// Myservicestabs.js
import { useState } from 'react'
import Myservicestabsitem from './Myservicestabsitem'
import { StyledServicesTabs } from './styledComponents'

const Myservicestabs = ({ itemdata, setTabdata, TableComponent }) => {
  const [current, setCurrent] = useState(0)

  return (
    <>
      <StyledServicesTabs
        id="mytabs-container"
        activeKey={current}
        onChange={(key) => setCurrent(key)}
        type="card"
        items={new Array(6).fill(null).map((_, i) => ({
          label: (
            <Myservicestabsitem
              itemprop={itemdata[i]}
              setTabdata={setTabdata}
              tabdata={{ itemdata, setTabdata }}
            />
          ),
          key: i,
        }))}
      />
      <TableComponent currentTab={itemdata[current].label} />
    </>
  )
}

export default Myservicestabs
