import { Col, Row } from 'antd'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

const OffersCreatedProgress = ({ percentage }) => {
  const { t } = useTranslation()

  const series = [percentage]
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '12px',
            color: void 0,
            offsetY: 60,
          },
          value: {
            offsetY: 22,
            fontSize: '16px',
            color: void 0,
            formatter: function (e) {
              return Math.round(e) + '%'
            },
          },
        },
      },
    },
    colors: ['#00566A'],
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 3,
    },
    labels: [''],
  }
  return (
    <Row>
      <Col>
        <ReactApexChart
          options={options}
          series={series}
          type="radialBar"
          height="150"
          width="150"
          style={{ marginTop: '-20px' }}
        />
      </Col>
      <Col>
        <div
          style={{
            lineHeight: '1.633333',
            marginTop: '22px',
            textAlign: 'center',
          }}
        >
          <div style={{ fontSize: '14px' }}>
            {t('received_requests.offer_completion_percentage.title')}
          </div>
          <p>{t('received_requests.offer_completion_percentage.subtitle')}</p>
        </div>
      </Col>
    </Row>
  )
}

export default OffersCreatedProgress
