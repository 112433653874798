import React from 'react'
import { Space, message, Popover, Button, Empty, Flex } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import {
  fetchLabels,
  fetchLabelsForBookedService,
  removeLabelFromBookedService,
  addLabelToBookedService,
} from '$api/evoAPIs'
import Spinner from '$components/Spinner'
import Label from '.'

const BookedServiceLabels = ({ bookedServiceId, disabled }) => {
  const [messageApi, contextHolder] = message.useMessage()
  const queryClient = useQueryClient()

  const { data: labels, isLoading: labelsAreLoading } = useQuery({
    queryKey: ['labels', { exclude_system_managed: true }],
    queryFn: fetchLabels,
  })

  const {
    data: bookedServiceLabels,
    isLoading: bookedServiceLabelsAreLoading,
  } = useQuery({
    queryKey: ['labels', bookedServiceId],
    queryFn: fetchLabelsForBookedService,
  })

  // limit for the number of labels to show directly
  const displayLimit = 1

  // bookedServiceLabels directly shown, and shown in Popover
  const displayedLabels = bookedServiceLabels?.slice(0, displayLimit)
  const extraLabels = bookedServiceLabels?.slice(displayLimit)

  const unassignedLabels =
    labels?.filter(
      (label) =>
        !bookedServiceLabels?.find(
          (bookedServiceLabel) => bookedServiceLabel.id === label.id,
        ),
    ) || []

  const removeLabelMutation = useMutation({
    mutationFn: removeLabelFromBookedService,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['labels', bookedServiceId] })
    },
  })

  const addLabelMutation = useMutation({
    mutationFn: addLabelToBookedService,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['labels', bookedServiceId] })
    },
  })

  const handleAdd = (label) => {
    addLabelMutation.mutate(
      {
        bookedServiceId,
        labelName: label.name,
      },
      {
        onError: () => {
          messageApi.error('Could not add label!')
        },
      },
    )
  }

  const handleRemove = (e, label) => {
    e.preventDefault()
    removeLabelMutation.mutate(
      {
        bookedServiceId,
        labelName: label.name,
      },
      {
        onError: () => {
          messageApi.error('Could not remove label!')
        },
      },
    )
  }

  if (bookedServiceLabelsAreLoading || labelsAreLoading) {
    return <Spinner />
  }

  return (
    <>
      {contextHolder}
      <Flex gap="small">
        <Space>
          {displayedLabels.map((label) => (
            <Label key={label.id} {...label} onRemove={handleRemove} />
          ))}
          {extraLabels.length > 0 && (
            <Popover
              content={
                <Space direction="vertical">
                  {extraLabels.map((label) => (
                    <Label key={label.id} {...label} onRemove={handleRemove} />
                  ))}
                </Space>
              }
              title="More Labels"
              trigger="hover"
            >
              <Button
                size="small"
                type="link"
              >{`+${extraLabels.length} more`}</Button>
            </Popover>
          )}
        </Space>
        <Popover
          title={!disabled && <b>Assign a label:</b>}
          content={
            !disabled && (
              <Space direction="vertical">
                {unassignedLabels.length > 0 ? (
                  unassignedLabels.map((label) => (
                    <Label
                      key={label.id}
                      {...label}
                      onClick={() => handleAdd(label)}
                    />
                  ))
                ) : (
                  <Empty />
                )}
              </Space>
            )
          }
        >
          <Button
            type="dashed"
            size="small"
            shape="circle"
            icon={<PlusOutlined />}
            disabled={disabled}
          />
        </Popover>
      </Flex>
    </>
  )
}

export default BookedServiceLabels
