import ThreeDModelConfigForm from './ThreeDModelConfigForm'

const ExpandedRow = (props) => (
  <ThreeDModelConfigForm
    record={props.record}
    setStatus={props.setStatus}
    setCurrentFormValues={props.setCurrentFormValues}
  />
)

export default ExpandedRow
