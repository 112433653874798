import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons'
import { PartNoDiv, PartNoButton } from './styledComponents'
import { TableNumberButton } from '$globalStyledComponents/Table'
import TextHighlighter from '$components/TextHighlighter'
import { theme } from 'antd'

const { useToken } = theme

const PartNo = ({ part, expandedRowKeys, onExpand, filters = [] }) => {
  const { token } = useToken()
  if (typeof expandedRowKeys === 'object') {
    const expanded = expandedRowKeys.includes(part.id)
    return (
      <PartNoButton
        onClick={() => {
          onExpand(!expanded, part)
        }}
      >
        {expanded ? (
          <UilMinusCircle
            size="15"
            color={token.colorPrimary}
            style={{ margin: '0px 5px -3px -5px' }}
          />
        ) : (
          <UilPlusCircle
            size="15"
            color={token.colorPrimary}
            style={{ margin: '0px 5px -3px -5px' }}
          />
        )}
        <TableNumberButton>
          <TextHighlighter text={part.id_part_client} filters={filters} />
        </TableNumberButton>
      </PartNoButton>
    )
  }

  return (
    <PartNoDiv>
      <TableNumberButton>
        <TextHighlighter text={part.id_part_client} filters={filters} />
      </TableNumberButton>
    </PartNoDiv>
  )
}

export default PartNo
