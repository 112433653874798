import styled from 'styled-components'
import { Card, Upload } from 'antd'
import { StlViewer } from 'react-stl-viewer'

export const ExpandableTableCard = styled(Card)`
  cursor: default;
  background: none;
  border: none;

  .ant-card-body {
    padding: 0px 15px 0px;
  }
  .ant-card {
    background: none;
    border: none;
  }
  .detailsprice {
    width: 82px;
  }
  .ant-steps-vertical {
    margin-top: -6px;
  }
  .ant-steps-item-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #495057;
  }
  .formcolumn {
    padding: 0 5px 0 0;
    max-width: 243px;
  }
  .ant-input-number-group > .ant-input-number:last-child,
  .ant-input-number-group-addon:last-child {
    border: none;
  }
  .dwidth {
    width: 45px;
    margin-right: 2px;
  }
  .ant-input-number-affix-wrapper {
    width: 45px;
  }
  .ant-input-group {
    display: flex;
    justify-content: space-between;
    margin-left: -13px;
    width: 112%;
  }
  .white-bg {
    background-color: #fff !important;
  }

  .ant-form-item-control {
    align-items: flex-end;
  }
  .partsdivider {
    height: unset;
    margin: 0 40px;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
`

export const StyledUpload = styled(Upload)`
  text-align: center;
  height: 100%;
  aspect-ratio: 1 / 1;
  display: flex !important;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: white;

  .ant-upload-list,
  .ant-upload-select {
    width: 95% !important;
    height: 95% !important;
    margin-left: 3px;
    margin-bottom: 3px;
  }
  .ant-upload-list-item-container {
    width: 95% !important;
    height: 95% !important;
    margin-left: 3px !important;
    margin-top: -9px !important;
  }
`

export const StyledStlViewer = styled(StlViewer)`
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.6s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  :hover .overlay {
    opacity: 1;
  }

  .overlay div {
    color: white;
    text-decoration: none;
    margin: 10px;
    font-size: 1.2em;
    padding: 10px 20px;
  }
  .overlay div:first-child {
    position: absolute;
    bottom: 0;
    :hover {
      color: red;
    }
  }

  .overlay div:last-child {
    position: static;
    margin-top: 20px;
  }
`

export const ImageContainerFallback = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  aspect-ratio: 1 / 1;

  padding: 10px;
  background-color: #fff;

  .overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    opacity: 1;
    transition: opacity 0.6s ease;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
  }

  .overlay {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.6s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  :hover .overlay {
    opacity: 1;
  }
  :hover .overlay2 {
    opacity: 0;
  }

  .overlay div {
    color: white;
    text-decoration: none;
    margin: 10px;
    font-size: 1.2em;
    padding: 10px 20px;
  }
  .overlay div:first-child {
    position: absolute;
    bottom: 0;
    :hover {
      color: red;
    }
  }

  .overlay div:last-child {
    position: static;
    margin-top: 20px;
  }
  .spinner {
    background-color: '#eee';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`
