import styled from 'styled-components'
import { Card } from 'antd'

export const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: #495057;
  margin-bottom: 25px;
`

export const ExpandableTableCard = styled(Card)`
  cursor: default;
  background: none;
  border: none;

  .ant-card-body {
    padding: 0px 15px 0px;
  }

  .detailsprice {
    width: 82px;
  }
  .ant-steps-vertical {
    margin-top: -6px;
  }
  .ant-steps-item-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #495057;
  }
  .formcolumn {
    padding: 0 5px 0 0;
    max-width: 243px;
  }
  .ant-input-number-group > .ant-input-number:last-child,
  .ant-input-number-group-addon:last-child {
    border: none;
  }
  .dwidth {
    width: 45px;
    margin-right: 2px;
  }
  .ant-input-number-affix-wrapper {
    width: 45px;
  }
  .ant-input-group {
    display: flex;
    justify-content: space-between;
    margin-left: -13px;
    width: 112%;
  }
  .white-bg {
    background-color: #fff !important;
  }

  .ant-form-item-control {
    align-items: flex-end;
  }

  .partsdivider {
    height: unset;
    margin: 0 40px;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }

  .partdetailform {
    border: none !important;
  }

  .ant-select-selector {
    border: none !important;
  }
`
export const MetaInfo = styled.div`
  float: right;

  > span {
    float: right;
    background: rgb(239, 239, 239);
    margin: -2px 0 0 10px;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 11px;
  }
`
