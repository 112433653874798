import { Button } from 'antd'

const PrivacyEN = () => {
  const openPrivacySettings = () => {
    if (window.UC_UI && window.UC_UI.showSecondLayer) {
      window.UC_UI.showSecondLayer()
    } else {
      console.error('UserCentrics UI is not available.')
    }
  }
  return (
    <div style={{ margin: '0 200px' }}>
      <h1>Privacy Statement</h1>
      <p>
        <strong>
          The controller as per the EU General Data Protection Regulation (GDPR)
          is:
        </strong>
      </p>
      <p>
        Daimler Buses GmbH (&quot;We&quot;)
        <br />
        HPC R503
        <br />
        Fasanenweg 10
        <br />
        70771 Leinfelden-Echterdingen
        <br />
        Germany
        <br />
        Email:{' '}
        <a href="mailto:dataprivacy-bus@daimlertruck.com">
          dataprivacy-bus@daimlertruck.com
        </a>
      </p>

      <h2>Data Privacy Officer:</h2>
      <p>
        Daimler Truck AG
        <br />
        Chief Data Privacy Officer
        <br />
        HPC DTF2B
        <br />
        70745 Leinfelden-Echterdingen
        <br />
        Germany
        <br />
        Email:{' '}
        <a href="mailto:dataprivacy@daimlertruck.com">
          dataprivacy@daimlertruck.com
        </a>
      </p>
      <h2>1. Data Privacy</h2>
      <p>
        We appreciate you visiting our website and thank you for your interest
        in our company. The advancing technologization of our everyday lives
        opens up unimagined possibilities for us today. This brings with it a
        high level of responsibility on the part of the companies to which we
        make our personal data available. Daimler Truck AG is fully aware of
        this responsibility and the protection of your privacy when using our
        websites is of particular concern to us. In our privacy statement, we
        inform you how we collect and process your personal data, for what
        purpose, on what legal basis this is done and how long we store the
        data. We also inform you about your rights and claims and how you can
        assert them.
      </p>

      <h2>2. Purpose</h2>
      <p>
        <strong>a.</strong> Provision of website
      </p>
      <p>
        We collect and process the personal data of our website visitors only
        insofar as this is necessary to provide a functional website and our
        content. In addition, we use the data to optimize the website and to
        protect our IT-systems.
      </p>

      <p>
        <strong>b.</strong> Contacting us
      </p>
      <p>
        If you provide us with further personal data, e.g. in the context of a
        chat, a contact form or by telephone communication, we use this data for
        the purposes of customer communication and administration to the extent
        necessary in each case.
      </p>

      <p>
        <strong>c.</strong> Contract initiation or fulfilment
      </p>
      <p>
        If you provide us with further personal data (e.g. for the execution of
        a contract and/or contract initiation), we use your data for the
        purposes of handling and billing any business transactions.
      </p>

      <p>
        <strong>d.</strong> Further possible uses
      </p>
      <p>
        If you give us your consent by way of a customer satisfaction survey, a
        raffle or registration on our website, we will use your personal data
        for this purpose.
      </p>
      <p>
        We and possibly selected third parties use your data to display
        personalized content or advertising based on your usage behavior,
        provided that you give your consent (= approval) via our Consent
        Management System. Further information and decision-making options are
        available here:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Open privacy settings
        </Button>
      </p>

      <h2>3. Collecting and processing your personal data</h2>
      <p>
        <strong>a.</strong> There is no obligation to provide your personal data
        when you visit our website. However, it is possible that certain
        contents of our website depend on the provision of personal data. If you
        do not wish to provide personal data in these cases, this may result in
        content not being displayed or only being displayed to a limited extent.
      </p>

      <p>
        <strong>b.</strong> Provision website
      </p>
      <p>
        Temporary storage of the IP address by the system is necessary to enable
        delivery of the website to the visitor&apos;s computer. For this
        purpose, the anonymized IP address of the visitor must remain stored for
        the duration of the session. It is stored in log files to ensure the
        functionality of the website. Each time you visit our website, our
        system automatically collects and stores information that your browser
        transmits to us in log files. The following data is collected:
      </p>
      <ul>
        <li>the browser and operating system used and their settings,</li>
        <li>the date and time of the visit,</li>
        <li>
          the access status (e.g. whether you were able to access a website or
          received an error message),
        </li>
        <li>the use of website functions,</li>
        <li>the search terms you may have entered,</li>
        <li>the frequency with which you visit individual websites,</li>
        <li>the name of the retrieved files,</li>
        <li>the amount of data transferred,</li>
        <li>
          the website from which you accessed our websites and the website you
          visit from our websites, whether by clicking on links on our websites
          or entering a domain directly in the input field of the same tab (or
          window) of your browser in which you accessed our websites,
        </li>
        <li>
          for security reasons, in particular, to prevent and detect attacks on
          our websites or attempts at fraud, we store your IP address and the
          name of your Internet service provider for seven days.
        </li>
      </ul>

      <p>
        <strong>c.</strong> Contacting us
      </p>
      <p>
        Personal data is collected when you contact us. The data collected in
        the case of a contact form or chat can be found in the respective form.
        This data is stored and used exclusively for the purpose of responding
        to your request or for communication and the associated technical
        administration.
      </p>
      <p>
        In addition, we also process additional data as part of our business
        relationship with you. This includes, in particular:
      </p>
      <ul>
        <li>Data from postal and telephone communication.</li>
      </ul>

      <p>
        <strong>d.</strong> Contract initiation or fulfilment
      </p>
      <p>
        We first collect, process and use the data that you provide to us as
        part of our business relationship (in particular for the execution of
        orders within our online shop, etc.). This includes in particular the
        following data:
      </p>
      <ul>
        <li>
          Master data of the contractual partner and/or its contact person, in
          particular name;
        </li>
        <li>
          Contact details of the contractual partner or its contact person, in
          particular current address, telephone numbers and e-mail addresses;
        </li>
        <li>
          Contract data such as delivery, order and confirmation date, order and
          customer account number;
        </li>
        <li>
          for export transactions, the data from the commercial register extract
          and the VAT identification number;
        </li>
        <li>possibly bank details, such as IBAN and BIC.</li>
      </ul>

      <p>
        <strong>e.</strong> Further possible uses
      </p>
      <p>
        Further personal data may be processed in particular in the following
        cases:
      </p>
      <ul>
        <li>customer-satisfaction surveys,</li>
        <li>raffles,</li>
        <li>registration on our website.</li>
      </ul>
      <p>
        The data collected in the case of a contact form or chat can be seen
        from the respective form.
      </p>
      <p>
        This data will only be processed in the event of consent or in
        accordance with applicable legal provisions (see section 6).
      </p>

      <h2>
        4. Transfer of personal data to third parties; social plug-ins; use of
        service providers
      </h2>
      <p>
        If we use so-called “social plug-ins” of social networks such as
        Facebook and Twitter on our websites, we integrate them as follows:
      </p>
      <p>
        When you visit our websites, the social plug-ins are deactivated by
        default, i.e. no personal data of any kind is transferred to the
        operators of these (social) networks. If you would like to use one of
        the networks, click on the respective social plug-in to activate it and
        thereby confirm your consent to communication with these networks. The
        buttons then become active and establish the connection. If you have a
        user account with the network and are logged in there at the time the
        social plug-in is activated, the network can assign your visit to our
        websites to your user account. If you would like to avoid this, please
        log out of the network before activating the social plug-in. A social
        network cannot assign a visit to other Daimler Truck websites until you
        have activated a social plug-in there as well. If you activate a social
        plug-in, the network transfers the content that becomes available
        directly to your browser, which integrates it into our websites. In this
        situation, data transfers can also take place that are initiated and
        controlled by the respective social network. Your connection to a social
        network, the data transfers that take place between the network and your
        system, and your interactions on this platform are governed exclusively
        by the data privacy protection provisions of the respective network.
      </p>
      <p>
        The social plug-in remains active until you deactivate it or delete your
        website technologies (see Section 5.d).
      </p>
      <p>
        If you click on the link to an offer or activate a social plug-in,
        personal data may reach providers in countries outside the European
        Economic Area that, from the point of view of the European Union
        (&quot;EU&quot;), do not guarantee an “adequate level of protection” for
        the processing of personal data according to EU standards. Please keep
        this in mind before you click on a link or activate a social plug-in and
        thereby may trigger a transfer of your data.
      </p>
      <p>
        We use qualified service providers (including IT service providers,
        marketing agencies) for operating, improving and protecting our
        websites. We only transfer personal data to them if this is necessary
        for the provision and use of the websites and their functionalities, for
        the pursuit of legitimate interests, for the fulfilment of legal
        obligations or if you have consented to this. You can find more detailed
        information on the recipients in our Consent Management System:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Open privacy settings
        </Button>
      </p>
      <h2>5. Website technologies</h2>

      <p>
        <strong>a.</strong> Website technologies may be used when you visit our
        websites. Website technologies are text files that are stored in the
        visitor’s browser. When a visitor accesses a website, a website
        technology can be stored on the visitor’s operating system. This website
        technology contains a characteristic character string that enables the
        browser to be uniquely identified when the website is called up again.
        Technically, these are so-called HTML cookies and similar software tools
        such as web/DOM storage or local shared objects (so-called “flash
        cookies”), which we collectively refer to as website technologies.
      </p>

      <p>
        <strong>b.</strong> Website technologies are small files that are stored
        on your desktop, notebook or mobile device when you visit a website.
        This can be used to: recognise whether there has already been a
        connection between the device and the websites, take into account your
        preferred language or other settings, provide you with certain
        functionalities (e.g. online shop, vehicle configurator) or recognise
        your interests based on usage. Website technologies may also contain
        personal data.
      </p>

      <p>
        <strong>c.</strong> Whether and which website technologies are used when
        you visit our websites depends on which areas and functions of our
        websites you use and whether you consent to the use of website
        technologies that are not technically necessary in our consent
        management system. Technically necessary website technologies are small
        text files that are stored by a website in your browser when you surf
        the Internet. They ensure that a website works and that users see it the
        same way the next time they visit it. These website technologies are
        necessary for the website to function and do not require the user’s
        consent. Further information and decision-making options can be found
        here:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Open privacy settings
        </Button>
      </p>

      <p>
        <strong>d.</strong> Please note that you can set your browser so that
        you are informed about the setting of website technologies and can
        decide individually whether to accept them or to exclude the acceptance
        of website technologies in certain cases or generally. Each browser is
        different in the way it manages website technologies settings. This is
        described in the help menu of each browser, which explains how to change
        your website technologies settings. You can find these settings for the
        respective browsers under the following links:
      </p>
      <ul>
        <li>
          <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
            Internet Explorer
          </a>
        </li>
        <li>
          <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
            Firefox
          </a>
        </li>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=de&hlrm=en">
            Chrome
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">
            Safari
          </a>
        </li>
      </ul>

      <p>
        <strong>e.</strong> If you decide against the use of website
        technologies or delete them, it is possible that not all functions of
        our websites will be available or individual functions will only be
        available to you to a limited extent.
      </p>

      <h2>6. Legal basis for processing</h2>

      <p>
        <strong>a.</strong> Provision website
      </p>
      <p>
        The legal basis for the temporary storage of data in the log files is
        Art. 6 Para. 1 sentence 1 letter b GDPR. In this respect, we fulfil the
        existing contract with the user – also in the case of free offers – for
        the use of the website.
      </p>
      <p>
        We also process the data to pursue our legitimate interests in
        accordance with Art. 6 Para. 1 sentence 1 letter f GDPR, in order to be
        able to provide you with the website technically. Our legitimate
        interest is to be able to provide you with an attractive, technically
        functioning, user-friendly and secure website.
      </p>

      <p>
        <strong>b.</strong> Contacting us
      </p>
      <p>
        The legal basis for the processing of the data is our legitimate
        interest in responding to your request pursuant to Art. 6 Para. 1
        sentence 1 letter f GDPR. If your contact is aimed at concluding a
        contract, the additional legal basis for the processing is Art. 6 Para.
        1 sentence 1 letter b GDPR.
      </p>

      <p>
        <strong>c.</strong> Contract initiation or fulfilment
      </p>
      <p>
        For the processing of personal data for the purposes of initiating or
        fulfilling a contract with you, Art. 6 Para. 1 sentence 1 letter b GDPR
        is the legal basis.
      </p>
      <p>
        Insofar as the processing of your personal data is necessary for the
        fulfilment of our legal obligations (e.g. for the retention of data), we
        are authorised to do so in accordance with Art. 6 Para. 1 sentence 1
        letter c GDPR.
      </p>

      <p>
        <strong>d.</strong> Newsletter registration (see section 9)
      </p>
      <p>
        For the processing of personal data for subscribing to our newsletter,
        by activating the confirmation link you give your consent to the use of
        your personal data in accordance with Art. 6 Para. 1 sentence 1 letter a
        GDPR.
      </p>

      <p>
        <strong>e.</strong> Further possible uses
      </p>
      <p>
        We process personal data for the purposes of pursuing our legitimate
        interests as well as the legitimate interests of third parties in
        accordance with Art. 6 Para. 1 sentence 1 letter f GDPR for the
        following processing operations in particular:
      </p>
      <ul>
        <li>maintaining the functionality of our IT systems,</li>
        <li>
          the (direct) marketing of our own and third-party products and
          services (insofar as this does not take place with your consent),
        </li>
        <li>the legally required documentation of business contacts.</li>
      </ul>
      <p>
        We also process your personal data in order to be able to provide you
        with our website technically. Our legitimate interest is to be able to
        provide you with an attractive, technically functional, user-friendly
        and secure website/product.
      </p>
      <p>
        Within the scope of the respectively required balancing of interests, we
        take into account in particular the type of personal data, the purpose
        of processing, the processing circumstances and your interest in the
        confidentiality of your personal data.
      </p>

      <h2>7. Deletion of your personal data</h2>
      <p>
        Your personal data will be deleted as soon as it is no longer necessary
        to achieve the purpose for which it was collected.
      </p>

      <p>
        <strong>a.</strong> Provision website
      </p>
      <p>
        In the case of collection of your data for the provision of our website,
        data is deleted when the respective session has ended. If the data is
        saved in log files, after seven days at the latest.
      </p>

      <p>
        <strong>b.</strong> Contacting us
      </p>
      <p>
        Your data is deleted after your request has been processed. This is the
        case if it can be seen from the circumstances that the matter in
        question has been finally clarified and if no statutory retention
        obligations continue to apply.
      </p>

      <p>
        <strong>c.</strong> Contract initiation or fulfilment
      </p>
      <p>
        We process and store your personal data only for as long as we need it
        to fulfil contractual or legal obligations, generally between 6 and 10
        years (in accordance with the German commercial code “HGB” and the
        German tax code “AO”).
      </p>

      <p>
        <strong>d.</strong> Declaration of consent
      </p>
      <p>
        You can revoke your consent to the processing of personal data at any
        time, free of charge and with future effect. Please note that the
        revocation will only take effect for the future. Processing that took
        place before the revocation is not affected. Withdrawal of your consent
        may result in us no longer being able to provide our services in whole
        or in part without processing this data and therefore having to
        terminate the existing contract.
      </p>

      <p>
        <strong>e.</strong> Further possible uses
      </p>
      <p>
        Processing takes place via the previously mentioned storage, data is
        only stored to the extent required by the laws, regulations or other
        legal provisions to which we are subject in the EU or by legal
        provisions in third countries, if there is an appropriate level of data
        protection. If deletion is not possible in individual cases, the
        corresponding personal data will be marked with the aim of restricting
        further processing.
      </p>

      <h2>8. Data subject rights</h2>
      <p>
        <strong>a.</strong> As a data subject, you have the right to access
        (Art. 15 GDPR), rectification (Art. 16 GDPR), erasure (Art. 17 GDPR),
        restriction of processing (Art. 18 GDPR) and data portability (Art. 20
        GDPR).
      </p>

      <p>
        <strong>b.</strong> If you have consented to the processing of your
        personal data by us, you have the right to withdraw your consent at any
        time without giving reasons. The lawfulness of the processing of your
        personal data up to the point of revocation remains unaffected by the
        revocation. Further processing of this data on the basis of a different
        legal basis and for the purposes based thereon also remains unaffected.
      </p>
      <div
        style={{
          border: '1px solid black',
          borderRadius: '6px',
          padding: '10px',
          margin: '0px -10px 10px -10px',
        }}
      >
        <p>
          <strong>c. Right to object</strong>
        </p>
        <p>
          You have the right, for reasons arising from your particular
          situation, to object at any time to the processing of personal data
          concerning you that is processed on the basis of Art. 6 Para. 1
          sentence 1 letter f GDPR (data processing on the basis of legitimate
          interests). If you file an objection, we will only continue to process
          your personal data if we can prove compelling legitimate reasons that
          outweigh your interests, rights and freedoms, or if the processing
          serves the assertion, exercise or defence of legal claims. Insofar as
          we process your personal data for the purposes of direct marketing in
          order to pursue legitimate interests on the basis of a balance of
          interests, you have the right to object to this at any time without
          giving reasons.
        </p>
      </div>

      <p>
        <strong>d.</strong> You can exercise your rights at the following
        contact address:{' '}
        <a href="mailto:dataprivacy-bus@daimlertruck.com">
          dataprivacy-bus@daimlertruck.com
        </a>
      </p>

      <p>
        <strong>e.</strong> If you are of the opinion that the processing of
        your personal data infringes data protection law, you have the right to
        lodge a complaint with a data protection supervisory authority (Art. 77
        GDPR).
      </p>
      <p>
        The competent supervisory authority is the Landesbeauftragte für den
        Datenschutz und die Informationsfreiheit in Baden-Württemberg.
      </p>

      <h2>9. Newsletter</h2>
      <p>
        If you subscribe to our e-mail newsletter, we will send you regular
        e-mail information about our offers. Only your e-mail address is
        required for sending the newsletter. The provision of further data is
        voluntary and will be used to be able to contact you personally. We use
        the so-called double opt-in process to send the newsletter. This means
        that we will only send you an e-mail newsletter if you have expressly
        confirmed that you consent to receiving newsletters. You will then
        receive a confirmation e-mail asking you to confirm by clicking on the
        link that you wish to receive our newsletter by e-mail in the future.
      </p>
      <p>
        The data collected by us when registering for the newsletter will be
        used exclusively for the purposes of advertising by means of the email
        newsletter.
      </p>
      <p>
        You can unsubscribe from the newsletter at any time and without stating
        reasons using the unsubscribe link provided within the newsletter.
      </p>
      <p>
        Once you have unsubscribed, your e-mail address will be deleted from our
        newsletter mailing list immediately, unless you have expressly consented
        to the further use of your data or we reserve the right to use your data
        beyond this, which is permitted by law and about which we inform you in
        this privacy statement.
      </p>

      <h2>10. Transfer to third countries</h2>
      <p>
        For the maintenance and support of IT systems, we also transfer data to
        service providers outside the European Economic Area (EEA) or grant them
        access. Since countries outside the EEA generally do not have an EU
        level of data protection, we contractually ensure that measures are
        taken by the service providers to ensure a level of data protection
        corresponding to the level of data protection in the EU/EEA. For more
        information on this please use the contact details provided. The
        companies of the Daimler Truck Group are also bound by the strict data
        protection regulations of the Daimler Truck AG.
      </p>
      <p>
        In the following countries ({' '}
        <a
          href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en?prefLang=de"
          target="_blank"
          rel="noreferrer"
        >
          link to list
        </a>
        ), from the point of view of the EU, there is an adequate level of
        protection for the processing of personal data corresponding to EU
        standards (so-called adequacy decision). With recipients in other
        countries, we agree on the application of EU standard contractual
        clauses, binding corporate regulations or the Swiss-U.S. Privacy Shield
        in order to create an “appropriate level of protection” in accordance
        with the legal requirements.
      </p>
    </div>
  )
}

export default PrivacyEN
