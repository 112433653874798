import { Layout, Timeline } from 'antd'
import styled from 'styled-components'

export const StyledHeader = styled(Layout.Header)`
  .navbar-header {
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 70px;
    max-width: 1320px;
    /*position: fixed;*/
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;

    @media (max-width: 991.98px) {
      left: 0;
    }
  }

  .navbar-user {
    display: flex;
    align-items: center;
    margin: 2px 14px 0px 2px;
  }

  .lang-switcher {
    margin-top: 5px;
  }

  .info-circle {
    margin-top: 4px;
  }
`
export const StyledTimeline = styled(Timeline)`
  margin-left: -100px;
  padding: 20px 20px 0 0;
  .ant-timeline-item-label {
    padding-left: 100px;
  }
`
