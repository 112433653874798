import { useQuery } from '@tanstack/react-query'
import { fetchAllMaterials, fetchAllPrinters } from '$api/evoAPIs'

const useAvailableMaterials = () => {
  const { data: materials, isSuccess: materialsSuccessfullyLoaded } = useQuery(
    ['materials'],
    fetchAllMaterials,
  )

  const { data: allPrinterMaterials, isSuccess: printersSuccessFullyLoaded } =
    useQuery(['printers'], fetchAllPrinters, {
      select: (response) =>
        response.items.reduce((allPrinterMaterials, printer) => {
          if (printer.materials) {
            printer.materials.forEach((materialId) => {
              allPrinterMaterials.add(materialId)
            })
          }
          return allPrinterMaterials
        }, new Set()),
    })

  if (materialsSuccessfullyLoaded && printersSuccessFullyLoaded) {
    return materials.map((material) => ({
      ...material,
      printerAvailable: allPrinterMaterials.has(material.id),
    }))
  }

  return []
}

export default useAvailableMaterials
