import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import AntdConfigProvider from '$context/AntdConfigProvider'
import StyledComponentsThemeProvider from '$context/StyledComponentsThemeProvider'
import defaultTheme from '$assets/defaultTheme.json'
import { fetchTheme } from '$api/client'
import { useUser } from '$context/user'

const THEME_REFETCH_ATTEMPTS = 3

const ThemeProvider = ({ children, fetchClientTheme = true }) => {
  const { t } = useTranslation()
  const user = useUser()

  const [hasFailed, setHasFailed] = useState(false)

  // fetch theme file from client
  const {
    isLoading,
    isError,
    data: clientTheme,
  } = useQuery({
    queryKey: ['theme', user.themeUrl],
    queryFn: fetchTheme,
    enabled: fetchClientTheme && user.isAuthenticated,
    retry: (failureCount) => {
      setHasFailed(true)
      return failureCount < THEME_REFETCH_ATTEMPTS // return whether we should keep trying
    },
  })

  return (
    <AntdConfigProvider
      defaultTheme={defaultTheme}
      clientTheme={isError ? undefined : clientTheme}
    >
      <StyledComponentsThemeProvider>
        {user.isAuthenticated && fetchClientTheme && isLoading && !hasFailed ? (
          <Spin tip={t('theme_loading')}>{children}</Spin>
        ) : (
          <>{children}</>
        )}
      </StyledComponentsThemeProvider>
    </AntdConfigProvider>
  )
}

export default ThemeProvider
