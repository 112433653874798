const createColumnsCM = () => {
  const columns = [
    {
      title: 'Client Material',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Anzahl',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'DWH Material',
      dataIndex: 'dwhmaterial',
      key: 'dwhmaterial',
    },
  ]

  return columns
}

export default createColumnsCM
