import { fetchLegalTexts } from '$api/evoAPIs'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import Footer from '$components/Footer'
import { StyledLayout } from '$globalStyledComponents/Layout'

const { Content } = StyledLayout

const GeneralTerms = () => {
  const { t, i18n } = useTranslation()

  const { data: legalText } = useQuery({
    queryKey: ['legalTexts', i18n.language],
    queryFn: fetchLegalTexts,
  })

  return (
    <StyledLayout className="layout">
      <Content>
        <div className="header-with-inlines">
          <h1 style={{ margin: '50px 0' }}>{t('legal_information')}</h1>
          <Link to="/imprint" className="jump">
            <InfoCircleOutlined
              style={{ fontSize: '13px', margin: '4px 8px 0 2px' }}
            />
            {t('Provider')}
          </Link>
          <Link to="/data-privacy" className="jump">
            <InfoCircleOutlined
              style={{ fontSize: '13px', margin: '4px 8px 0 2px' }}
            />
            {t('Data protection')}
          </Link>
        </div>
        <div className="myevocard">
          {legalText ? (
            <>
              <h2 style={{ textAlign: 'center', marginBottom: '50px' }}>
                {t('general_terms_and_conditions')}
              </h2>
              <div
                dangerouslySetInnerHTML={legalText}
                style={{
                  margin: '0 200px',
                }}
              />
            </>
          ) : null}
        </div>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default GeneralTerms
