let pendingRequestsCounter = 0
let listenerAttached = false

const beforeUnloadHandler = (event) => {
  // Recommended
  event.preventDefault()

  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true
}

export const pendingRequestsInterceptor = {
  trackRequests: (config) => {
    pendingRequestsCounter++

    if (!listenerAttached && pendingRequestsCounter > 0) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
      listenerAttached = true
    }

    return config
  },
  trackResponses: () => {
    pendingRequestsCounter--

    if (pendingRequestsCounter <= 0) {
      window.removeEventListener('beforeunload', beforeUnloadHandler)
      listenerAttached = false
      pendingRequestsCounter = 0
    }
  },
}

export const attachPendingRequestsInterceptors = (axiosClient) => {
  axiosClient.interceptors.request.use(pendingRequestsInterceptor.trackRequests)
  axiosClient.interceptors.response.use(
    (response) => {
      pendingRequestsInterceptor.trackResponses()
      return response
    },
    (error) => {
      pendingRequestsInterceptor.trackResponses()
      return Promise.reject(error)
    },
  )
}
