import styled from 'styled-components'

export const StyledGlobal = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 500;
  }

  h2 {
    font-weight: 500;
  }

  h3 {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    text-transform: uppercase;
    margin: 0 0 15px 0;
  }

  h4 {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin: 0 0 10px 0;
  }

  p {
    margin: 0 0 15px 0;
  }

  a {
    text-decoration: none;
  }

  .ant-layout {
    background: none !important;
  }

  .ant-card {
    background: none;
    border: none;
  }

  .ant-layout-header {
    background: ${({ theme }) => {
      // Use the gradient if provided, or fall back to a default background
      return (
        theme.Layout?.headerBgGradient || theme.Layout?.headerBg || '#ffffff'
      )
    }};

    height: unset !important;
    padding: 0 30px;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
  }

  .ant-layout-footer {
    padding: 24px 0 !important;
    background: white !important;
  }

  .ant-layout-footer {
    padding: 24px 0 !important;
    background: white !important;
  }

  .ant-upload-list-item {
    margin-top: 0px !important;
  }

  .myserviceskpi {
    margin-right: 15px;
    max-width: 425px;
  }

  // Global - Icons

  .myevocard {
    background-color: white;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
    padding: 25px;
  }

  .historyarrowicon {
    width: 16px;
    height: 16px;
    margin-bottom: -3px;
    margin-right: 7px;
  }

  .traffic-light {
    width: 12px;
    margin: 2px 8px -1px 0;
  }
`
