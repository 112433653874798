import styled from 'styled-components'
import { Table, Collapse, Steps } from 'antd'
import Form from '$components/Form'

export const PartNoBg = styled.div`
  background: ${(props) => props.theme.colorBgLightSeven};
  padding: 7px 14px;
  margin: -8px;
  width: fit-content;
  font-weight: 600;
  border-radius: 2px;
  color: ${(props) => props.theme.colorPrimary};
`

export const StyledCollapse = styled(Collapse)`
  border: 1px solid #d5e2e4;
  .ant-collapse-item {
    border-bottom: 1px solid #d5e2e4;
  }
  .ant-collapse-content {
    border-top: 1px solid #d5e2e4;
  }
  .ant-collapse-header {
    background-color: #f1f2f3 !important;
  }
  .ant-collapse-content {
    background-color: #fbfbfb;
  }
`
export const StyledSteps = styled(Steps)`
.ant-steps-item-container {

padding-top: 12px;
}

background-color: #F8F7F7;
padding-top: 0px!important;
border-radius: 8px 8px 0 0;


.ant-steps-item-active {
  background-color: #D0DFE3!important;
  border-radius: 0 0 0 0;
}
.ant-steps-item:first-child {
  border-radius: 8px 0 0 8px;
}
.ant-steps-item:last-child {
  border-radius: 0 8px 8px 0;
}
.ant-steps-item.ant-steps-item-active::before {
  height: 100%!important;
  border-left: 4px solid ${(props) => props.theme.colorPrimary} !important;
}
.ant-steps-item.ant-steps-item-active:first-child::before {
  height: 100%!important;
  border-left: 4px solid ${(props) => props.theme.colorPrimary} !important;
  border-radius: 8px 0 0 8px;
}
.ant-steps-item::before {
  transition: height 0.3s, bottom 0.3s!important;
  background-color: initial!important;
}
.ant-steps-item::after {
  margin-left: -38px;
  margin-top: 6px;
`
export const StyledStepButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    //margin-top: -6px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }

  tr {
    background-color: white;
    //cursor: pointer;
  }

  th::before {
    display: none;
  }

  .feas-col {
    width: 80px;
  }
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #f8f8f8;
  }
  .ant-table-thead .ant-table-row-expand-icon-cell:after {
    content: '${(props) => props.partIdText}';
  }
  .ant-table-thead .ant-table-row-expand-icon-cell {
  }
`
export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 15px;
  }
`
