import styled from 'styled-components'
import { Tabs } from 'antd'

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-tab {
    background: none !important;
  }
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab-active {
    background: ${(props) => props.theme.colorBgLightFour} !important;
    border-left: 6px solid !important;
  }
`
