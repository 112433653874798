import { fetchFileAsServiceProvider } from '$api/evoAPIs'
import { Button, message } from 'antd'
import useFetchDownloadQuery from './useFetchDownloadQuery'
import { useTranslation } from 'react-i18next'
import Tooltip from '$components/Tooltip'

const PartFileDownloadButton = ({ children, fileName, ...rest }) => {
  const [messageApi, contextHolder] = message.useMessage()
  const { t } = useTranslation()

  const downloadFile = useFetchDownloadQuery()

  const handleDownload = () => {
    if (!fileName) {
      messageApi.error(t('download_error'))
      return
    }

    downloadFile({
      queryKey: ['files', fileName],
      queryFn: fetchFileAsServiceProvider,
      downloadFileName: fileName,
      onError: () => messageApi.error(t('download_error')),
    })
  }

  return (
    <>
      {contextHolder}
      <Tooltip title={!fileName ? 'No file available!' : ''}>
        <Button {...rest} disabled={!fileName} onClick={handleDownload}>
          {children}
        </Button>
      </Tooltip>
    </>
  )
}

export default PartFileDownloadButton
