import { useIgnoreCMP } from '$utils'

const LegalEN = () => {
  useIgnoreCMP()

  return (
    <div style={{ margin: '0 200px' }}>
      <h2 style={{ textAlign: 'center' }}>Legal Notices</h2>

      <p>&nbsp;</p>

      <p>
        <strong>Copyrights</strong>
        <br />
        <br />
        Copyright Daimler Buses GmbH. All rights reserved. The text, images,
        graphics, sound files, animation files, video files and their
        arrangement are subject to Copyright and other intellectual property
        protection. These objects may not be copied for commercial use or
        distribution, nor may these objects be modified or reposted to other web
        sites, apps or other digital content (hereinafter &ldquo;Digital
        Offerings&rdquo;). Some Daimler Buses Digital Offerings also contain
        material that is subject to the copyright rights of their providers.
      </p>

      <p>
        <strong>Product variations</strong>
        <br />
        <br />
        Some of the product information, illustrations and images contained in
        this Digital Offering may have been prepared for generic use on Daimler
        Buses Digital Offerings maintained in different countries around the
        world. Consequently, some of the information and/or accessories which
        are not available in some countries or which, in order to satisfy local
        market demand or regulatory controls in such countries, may only be
        available in different specifications or configurations.
      </p>

      <p>
        If you are interested in any vehicle model, paint, option or accessory
        shown on the Internet site and are unsure of its availability or
        specification in your locality, you should contact Daimler Buses and/or
        a local authorized dealer for the relevant product, for information of
        current details in your locality.
      </p>

      <p>
        <strong>Prices</strong>
      </p>

      <p>
        All prices specified are recommended retail prices. Prices are current
        at the time of publication and subject to change without notice.
      </p>

      <p>
        <strong>Trademarks</strong>
        <br />
        <br />
        Unless otherwise indicated, all marks displayed in Daimler Buses Digital
        Offerings are the intellectual property of Daimler Truck AG, this
        applies especially to its model name plates and its corporate logos and
        emblems.
      </p>

      <p>
        <strong>No Licenses</strong>
        <br />
        <br />
        We have thought to achieve an innovative and informative Digital
        Offering. We hope that you will be as enthusiastic as we are about this
        creative effort. However, you also need to understand that Daimler Truck
        and Daimler Buses must protect their Intellectual Property, including
        their patents, trademarks and copyrights. Accordingly, you are hereby on
        notice that neither this Digital Offering, nor any material contained
        therein shall in any way grant or be taken to grant any person a license
        to Daimler Truck or Daimler Buses&rsquo; Intellectual Property.
      </p>

      <p>
        <strong>No warranties or representations</strong>
      </p>

      <p>
        The information in this Digital Offering is provided by Daimler Buses
        &quot;as is&quot; and to the extent permitted by law, is provided
        without warranty of any kind, expressed or implied, including but not
        limited to any implied warranties of merchantability, fitness for any
        particular purpose, or non-infringement. While the information provided
        is believed to be accurate, it may include errors or inaccuracies.
      </p>

      <p>
        Our Digital Offerings contain links to external sites, which are not
        under the control of Daimler Buses. Therefore, we are not responsible
        for the contents of any linked site. Daimler Buses is providing these
        links to you only as a convenience, and the inclusion of any link does
        not imply endorsement by Daimler Buses of the linked site.
      </p>

      <p>
        <strong>Information about online dispute resolution</strong>
        <br />
        <br />
        The European Commission has established an internet platform for online
        dispute resolution (so called &ldquo;ODR platform&rdquo;). The ODR
        platform is a point of entry for out-of-court resolutions relating to
        contractual obligations of online sales contracts. You can get to the
        ODR platform by following the link:
        <br />
        <br />
        <a
          href="https://ec.europa.eu/consumers/odr"
          target="_blank"
          rel="noreferrer"
        >
          https://ec.europa.eu/consumers/odr
        </a>
      </p>

      <p>
        <strong>
          Information according to &sect; 36 Verbraucherstreitbeilegungsgesetz
          (VSBG)
        </strong>
        <br />
        <br />
        We will not participate in a dispute settlement proceeding in front of a
        consumer arbitration board according to the German Consumer Dispute
        Resolution Act (VSBG) and are not obliged to do so.
      </p>

      <p>
        <strong>Order of Precedence</strong>
        <br />
        <br />
        Terms of Use for a Digital Offering take precedence over these Legal
        Notices.
      </p>

      <p>&nbsp;</p>

      <p>&nbsp;</p>
    </div>
  )
}

export default LegalEN
