import { Steps } from 'antd'

const Progresssteps = (props) => {
  return (
    <Steps
      progressDot
      current={props.current}
      direction="vertical"
      items={props.steps}
      style={{ marginBottom: '30px' }}
    />
  )
}

export default Progresssteps
