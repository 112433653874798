import { UilArrowCircleRight, UilArrowRight } from '@iconscout/react-unicons'
import { Input, Row, Col } from 'antd'
import FilterAddPartsModal from './FilterAddPartsModal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { theme } from 'antd'
import Form from '$components/Form'

const { useToken } = theme

const Editgroup = (props) => {
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isReadMore, setIsReadMore] = useState(true)
  const { token } = useToken()
  const isNewGroup = !props.groupId

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }
  const onFinish = () => {}
  let text = props.groupFilterDescription
    ? props.groupFilterDescription
    : 'none no filter used'
  let filterDescription = (
    <>
      <p>Letzter Filter</p>
      <UilArrowCircleRight className="historyarrowicon" />
      <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
        {text.split(' ')[0]}
      </span>
      <UilArrowRight className="historyarrowicon" />
      {isReadMore
        ? text.substr(text.indexOf(' ') + 1).slice(0, 45)
        : text.substr(text.indexOf(' ') + 1)}
      {text.substr(text.indexOf(' ') + 1).length > 45 && (
        <span
          style={{
            cursor: 'pointer',
            fontWeight: 'bold',
            color: token.colorPrimary,
          }}
          onClick={toggleReadMore}
        >
          {isReadMore ? ' ...more' : ' ...less'}
        </span>
      )}
    </>
  )

  const { TextArea } = Input
  const [form] = Form.useForm()

  return (
    <Row>
      <Col flex="400px" style={{ margin: '15px' }}>
        <h3>{t('parts_master_data.configurator')}</h3>

        <div style={{ marginTop: '10px', marginBottom: '-6px' }}>
          {props.groupFilterDescription !== '' ? filterDescription : ''}
        </div>
      </Col>
      <Col flex="auto">
        <Form
          form={form}
          initialValues={props.groupName}
          name="filtergroupform"
          onFinish={onFinish}
          autoComplete="off"
          size="small"
          style={{ margin: '15px' }}
          preserve={false}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item
              name="name"
              label={t('parts_master_data.group_name')}
              initialValue={props.groupName}
              rules={[
                {
                  required: true,
                  message: t('parts_master_data.choose_name'),
                },
              ]}
            >
              <Input
                placeholder={t('parts_master_data.new_group')}
                name="gruppenname"
                onChange={(e) => {
                  const currValue = e.target.value
                  props.setGroupName(currValue)
                  props.setIsChanged(true)
                }}
              />
            </Form.Item>
            {!isNewGroup && (
              <FilterAddPartsModal
                groupId={props.groupId}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                setIsChanged={props.setIsChanged}
                setGroupFilterDescription={props.setGroupFilterDescription}
              />
            )}
          </div>
          <TextArea
            rows={2}
            placeholder={t('parts_master_data.describe_group')}
            maxLength={600}
            name="description"
            defaultValue={props.groupDescription}
            onChange={(e) => {
              const currValue = e.target.value
              props.setGroupDescription(currValue)
              props.setIsChanged(true)
            }}
          />
        </Form>
      </Col>
    </Row>
  )
}
export default Editgroup
