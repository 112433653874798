import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { App, Button, Popconfirm, Modal } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { evoConsolidation } from '$api'
import { evoClient, putPart } from '$api/client'
import {
  fetchAllServices,
  postGenerate3DModel,
  postPartsConsolidation,
} from '$api/evoAPIs'
import { updateStatusAttributes } from '$utils'
import StatusPage from '../Defaultwizard/StatusPage'
import StepOne3DModelOverview from './StepOne3DModelOverview'
import StepThreeSummary from '../Defaultwizard/StepThreeSummary'
import {
  StyledStepButtons,
  StyledSteps,
} from '../Defaultwizard/styledComponents'
import StepTwo3DModelConfig from './StepTwo3DModelConfig'
import { theme } from 'antd'

const { useToken } = theme

const Generate3DModel = (props) => {
  const { t } = useTranslation()
  const [fcData] = useState({
    not_feasible_parts: [],
    feasible_parts: [],
  })
  const [bookableParts, setBookableParts] = useState()
  const [steps, setSteps] = useState([
    {
      title: t('service.selection'),
      content: '',
    },
    {
      title: t('service.generate_3d_model.step_config_title'),
      content: '',
    },
    {
      title: t('service.order'),
      content: '',
    },
  ])
  const [current, setCurrent] = useState(0)
  const { message } = App.useApp()
  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [buttonActive, setButtonActive] = useState(true)
  const fileNamesRef = useRef(false)
  const accumulatedFormDataRef = useRef(false)
  const [currentFormValues, setCurrentFormValues] = useState()
  const [configState, setConfigState] = useState(true)
  const [accumulatedFormData, setAccumulatedFormData] = useState([])
  const [currentGenerate3DModelId, setCurrentGenerate3DModelId] = useState()
  const [stlFile, setSTLFile] = useState()
  const [stlFileName, setSTLFileName] = useState()
  const [files, setFiles] = useState()
  const [fileNames, setFileNames] = useState([])
  const [has3DModel, setHas3DModel] = useState(false)

  const queryClient = useQueryClient()
  const { data: servicesData } = useQuery(['services'], fetchAllServices)
  const { token } = useToken()

  const addBookedGenerate3DModels = useMutation(
    (formData) => {
      formData.service_name = 'Generate 3D Model'
      formData.service_id = props.bookedServiceStatus[1].id
      formData.partlist_name = props.activeGroupName
      formData.part.files = []
      setSTLFile(formData.stl_file)
      setFiles(formData.files)
      delete formData.stl_file
      delete formData.files
      return evoConsolidation.post(`${postGenerate3DModel}`, formData, {
        headers: {
          'content-type': 'application/json',
        },
      })
    },
    {
      onSuccess: (data, originalPart) => {
        setCurrentGenerate3DModelId(data.data.id)
        // After creating the bookedservice attaching files depending on preferences
        if (data.data.information_quality.includes('other upload')) {
          for (let file of files) {
            uploadFiles.mutate([data.data.part.db_id_client, file])
          }
        }
        if (data.data.information_quality.includes('stl')) {
          uploadSTLFile.mutate(data.data.part.db_id_client)
        }
        // update Part status attribute
        updateClientPartStatus.mutate(originalPart)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not book Service`)
      },
    },
  )

  const uploadSTLFile = useMutation(
    (conslidation_part_id) => {
      const formData = new FormData()
      const file = new File([stlFile], 'dateiname.stl', {
        type: 'application/vnd.ms-pki.stl',
      })
      formData.append('file', file)
      return evoConsolidation.post(
        `${postPartsConsolidation}${conslidation_part_id}/file`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
    },
    {
      onSuccess: (data) => {
        setSTLFileName(data.data)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not upload STL File`)
      },
    },
  )

  const uploadFiles = useMutation(
    (id_and_file) => {
      const formData = new FormData()
      formData.append('file', id_and_file[1].originFileObj, id_and_file[1].name)
      return evoConsolidation.post(
        `${postPartsConsolidation}${id_and_file[0]}/file`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
    },
    {
      onSuccess: (data) => {
        setFileNames([...fileNames, data.data])
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(
          `${err.response.status} Could not upload files for "Generate 3D Service"`,
        )
      },
    },
  )

  const updateFileLocations = useMutation(
    (data) => {
      const body = JSON.stringify(
        data[1].concat(data[2] !== undefined ? [data[2]] : []),
      )
      const generate3DModelid = data[0]
      return evoConsolidation.put(
        `${postGenerate3DModel}${generate3DModelid}/file-locations`,
        body,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      )
    },
    {
      onSuccess: () => {
        setLoading(false)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(
          `${err.response.status} Could not upload stl files for "Generate 3D Service"`,
        )
      },
    },
  )

  const updateClientPartStatus = useMutation(
    (partsToUpdate) => {
      //check if "partsToUpdate" is from feasibility check or booked service
      if (partsToUpdate.part) {
        const editedPart = updateStatusAttributes(
          partsToUpdate.part,
          'status_attributes',
          'Generate 3D Model',
          '1',
        )
        editedPart.png = 'nopng'
        return evoClient.put(
          `${putPart}${editedPart.db_id_client}`,
          editedPart,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['allgroups'])
        queryClient.invalidateQueries(['notifications'])
        setLoading(false)
      },
      onError: (err) => {
        message.error(`${err.response.status} Could not update status`)
      },
    },
  )

  // Functions

  const updateSteps = useCallback(() => {
    if (bookableParts || servicesData) {
      const newSteps = [
        {
          title: t('service.selection'),
          content: (
            <StepOne3DModelOverview
              fcData={fcData}
              selectedParts={props.selectedParts}
              setBookableParts={setBookableParts}
              bookableParts={bookableParts}
              servicesData={servicesData}
              bookedServiceStatus={props.bookedServiceStatus}
              setHas3DModel={setHas3DModel}
            />
          ),
        },
        {
          title: t('service.generate_3d_model.step_config_title'),
          content: (
            <StepTwo3DModelConfig
              fcData={fcData}
              selectedParts={props.selectedParts}
              bookableParts={bookableParts}
              setCurrentFormValues={setCurrentFormValues}
              setConfigState={setConfigState}
              configState={configState}
            />
          ),
        },
        {
          title: t('service.order'),
          content: (
            <StepThreeSummary
              fcData={fcData}
              selectedParts={props.selectedParts}
              bookableParts={bookableParts}
              setButtonActive={setButtonActive}
              bookedServiceStatus={props.bookedServiceStatus}
              currentFormValues={currentFormValues}
            />
          ),
        },
      ]
      setSteps(newSteps)
    }
  }, [
    currentFormValues,
    bookableParts,
    servicesData,
    fcData,
    props.selectedParts,
    setBookableParts,
    setCurrentFormValues,
    setConfigState,
    configState,
    setButtonActive,
    props.bookedServiceStatus,
    t,
  ])

  const handleOk = () => {
    setCurrent(0)
    props.setSelectedParts([])
    setButtonActive(true)

    //post to endpoint
    for (let formData of accumulatedFormData) {
      addBookedGenerate3DModels.mutate({
        ...formData,
        originalPart: bookableParts,
      })
    }
    setLoading(true)
    setVisible(false)
  }

  const next = () => {
    if (current === 0) {
      setConfigState(false)
    }
    setCurrent(current + 1)
  }
  const prev = () => {
    if (current === 1) {
      setConfigState(true)
    }
    setCurrent(current - 1)
  }
  const handleFirstStepNext = () => {
    Modal.confirm({
      title: t('note'),
      content: <p>{t('service.generate_3d_model.confirmation_message')}</p>,
      onOk() {
        setCurrent(current + 1)
      },
      onCancel() {},
      centered: true,
      // default color from AntdConfigProvider is not working here, must be revised when theming is implemented
      okButtonProps: {
        style: {
          backgroundColor: token.colorPrimary,
        },
      },
      cancelButtonProps: {
        style: {
          borderColor: token.colorPrimary,
          color: token.colorPrimary,
        },
      },
    })
  }
  const items = steps
    .filter(function (x) {
      return x !== null
    })
    .map((item) => ({
      key: item.title,
      title: item.title,
    }))

  useEffect(() => {
    updateSteps()
  }, [updateSteps])

  // for prevent infinite loop #eslint
  useEffect(() => {
    accumulatedFormDataRef.current = currentFormValues
  }, [currentFormValues])

  useEffect(() => {
    fileNamesRef.current = fileNames
  }, [fileNames])

  useEffect(() => {
    // Put Forms from all Parts in an Array. Check if the current one exists, then update, or if not, append it. Checks are based on id
    if (currentFormValues && accumulatedFormDataRef.current !== false) {
      setAccumulatedFormData(
        accumulatedFormData.length
          ? accumulatedFormData.some(
              (item) => item.part.id === currentFormValues.part.id,
            )
            ? accumulatedFormData.map((item) =>
                item.part.id === currentFormValues.part.id
                  ? currentFormValues
                  : item,
              )
            : [...accumulatedFormData, currentFormValues]
          : [currentFormValues],
      )
      accumulatedFormDataRef.current = false
    }
  }, [currentFormValues, setAccumulatedFormData, accumulatedFormData])

  useEffect(() => {
    if (fileNames.length > 0 || stlFileName) {
      if (fileNamesRef.current !== false) {
        const nameArr = [currentGenerate3DModelId, fileNames, stlFileName]
        updateFileLocations.mutate(nameArr)
        // this is for preventing infinite loop
        fileNamesRef.current = false
      }
    }
  }, [fileNames, stlFileName, currentGenerate3DModelId, updateFileLocations])

  return (
    <>
      {visible && (
        <>
          <h1 style={{ margin: '50px 0' }}>
            {t(props.bookedServiceStatus[1].servicename)}
          </h1>
          <div className="contentcard">
            <StyledSteps current={current} items={items} type="navigation" />
            <div style={{ margin: '50px 0px' }}>{steps[current].content}</div>
            <StyledStepButtons>
              {current < steps.length - 1 && (
                <>
                  {current === 0 && has3DModel ? (
                    <Button
                      type="primary"
                      onClick={handleFirstStepNext}
                      disabled={!configState}
                    >
                      {t('button.next')}
                    </Button>
                  ) : (
                    <Popconfirm
                      disabled={configState}
                      placement="topRight"
                      description={
                        <>
                          Some parts need configuration before proceeding.
                          <br />
                          Check the &quot;status&quot; of your part.
                        </>
                      }
                      title={<h4>Some changes have to be made</h4>}
                    >
                      <Button
                        type="primary"
                        onClick={() => next()}
                        disabled={!configState}
                      >
                        {t('button.next')}
                      </Button>
                    </Popconfirm>
                  )}
                </>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => handleOk()}
                  disabled={!buttonActive}
                >
                  {t('button.order')}
                </Button>
              )}
              {current > 0 && (
                <Button
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => prev()}
                >
                  {t('button.back')}
                </Button>
              )}
            </StyledStepButtons>
          </div>
        </>
      )}
      {!visible && (
        <>
          <StatusPage
            setIsBookModalOpen={props.setIsBookModalOpen}
            loading={loading}
            setCurrent={props.setCurrent}
          />
        </>
      )}
    </>
  )
}
export default Generate3DModel
