import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { AUTOMATIC_LOGOUT, LOGOUT_FLAGS, REDIRECT_PARAM } from '$constants'
import { useUser, useLogout } from '$context/user'

const msToSeconds = (ms) => Math.floor(ms / 1_000)
const minsToMs = (mins) => mins * 60 * 1_000

const UserIdleHandler = ({
  children,
  idleTimeoutMinutes = 60,
  promptBeforeIdleMinutes = 5,
}) => {
  const timeout = minsToMs(idleTimeoutMinutes)
  const promptBeforeIdle = minsToMs(promptBeforeIdleMinutes)

  const { isAuthenticated } = useUser()
  const logout = useLogout()

  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const [remaining, setRemaining] = useState(msToSeconds(timeout))
  const [open, setOpen] = useState(false)

  const onIdle = () => {
    if (isAuthenticated) {
      handleLogout(AUTOMATIC_LOGOUT, location.pathname)
    }
  }

  const onActive = () => {
    setOpen(false)
  }

  const onPrompt = () => {
    if (isAuthenticated) {
      setOpen(true)
    }
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(msToSeconds(getRemainingTime()))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleLogout = (logoutFlag, redirectPath) => {
    logout()
    setOpen(false)

    let rootPath = '/login'

    const urlParams = new URLSearchParams()
    if (LOGOUT_FLAGS.includes(logoutFlag)) {
      urlParams.set(logoutFlag, '')
    }
    if (redirectPath && redirectPath !== '/') {
      urlParams.set(REDIRECT_PARAM, redirectPath)
    }
    const paramString = urlParams.toString()
    if (paramString) {
      rootPath += '?' + paramString
    }
    navigate(rootPath)
  }

  const handleStillHere = () => {
    activate()
  }

  return (
    <>
      {children}
      <Modal
        title={t('logout_impending_title')}
        open={open}
        closable={false}
        footer={[
          <Button key="back" onClick={() => handleLogout()}>
            {t('Logout')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleStillHere}>
            {t('still_here')}
          </Button>,
        ]}
      >
        <p>
          {remaining >= 60
            ? t('logout_impending_text', {
                timeLeft: Math.ceil(remaining / 60),
                range: 'minutes',
              })
            : t('logout_impending_text', {
                timeLeft: remaining,
                range: 'seconds',
              })}
        </p>
      </Modal>
    </>
  )
}

export default UserIdleHandler
