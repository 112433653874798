import {
  UilAnalytics,
  UilBrushAlt,
  UilCircle,
  UilCube,
  UilFileCheck,
  UilStore,
} from '@iconscout/react-unicons'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { fetchUnreadNotifications } from '$api/evoAPIs'
import Footer from '$components/Footer'
import { StyledLayout } from '$globalStyledComponents/Layout'
import { FcUpdateParts } from '$utils/FcUpdateParts'
import Myservicestabs from './Myservicestabs'

const { Content } = StyledLayout

const ServicesPage = ({ TableComponent, title }) => {
  const [tabdata, setTabdata] = useState([
    {
      label: 'All Services',
      i18nextLabel: 'all_services',
      notification_id: undefined,
      performed: 0,
      icon: <UilCircle size="19" />,
    },
    {
      label: 'Feasibility Check',
      i18nextLabel: 'service.feasibility_check.name',
      notification_id: undefined,
      performed: 0,
      icon: <UilFileCheck size="19" />,
    },
    {
      label: 'Generate 3D Model',
      i18nextLabel: 'service.generate_3d_model.name',
      notification_id: undefined,
      performed: 0,
      icon: <UilCube size="19" />,
    },
    {
      label: 'Apply Branding',
      i18nextLabel: 'service.apply_branding.name',
      notification_id: undefined,
      performed: 0,
      icon: <UilStore size="19" />,
    },
    {
      label: 'Apply Texturing',
      i18nextLabel: 'service.apply_texturing.name',
      notification_id: undefined,
      performed: 0,
      icon: <UilBrushAlt size="19" />,
    },
    {
      label: 'Digital Parts Report',
      i18nextLabel: 'service.digital_part_report.name',
      notification_id: undefined,
      performed: 0,
      icon: <UilAnalytics size="19" />,
    },
  ])

  const searchArray = (array, keyword) => {
    let count = 0
    const results = array.filter((obj) => {
      if (obj.detail_text.includes(keyword)) {
        count++
        return true
      }
      return false
    })
    return { count, results }
  }

  const { data: _notificationsdata } = useQuery(
    ['notifications'],
    fetchUnreadNotifications,
    {
      onSuccess: (data) => {
        let newTabs = []
        let allServicesCount = 0
        for (let tab of tabdata) {
          if (tab.label !== 'All Services') {
            if (data.filter((obj) => obj.detail_text.includes(tab.label))) {
              const { count, results } = searchArray(data, tab.label)
              if (tab.performed !== undefined) {
                tab.performed = count
                tab.notification_id = results
                allServicesCount += count
              }
              newTabs = [...newTabs, tab]
            }
          }
        }
        setTabdata([
          {
            ...tabdata.find((tab) => tab.label === 'All Services'),
            performed: allServicesCount,
          },
          ...newTabs,
        ])
      },
    },
  )

  return (
    <StyledLayout className="layout">
      <Content>
        <h1 style={{ margin: '50px 0' }}>{title}</h1>
        <Myservicestabs
          setTabdata={setTabdata}
          itemdata={tabdata}
          TableComponent={TableComponent}
        />
        <FcUpdateParts />
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default ServicesPage
