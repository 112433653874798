import styled from 'styled-components'
import { Select } from 'antd'
import { useTranslation, Trans } from 'react-i18next'
import { useState } from 'react'
import Form from '$components/Form'
const { Option } = Select

const Wrapper = styled.div`
  // using classes to select children of Wrapper to override antd styles
  .ant-form-item-control-input-content {
    display: 'flex';
  }

  .property-select {
    width: ${({ $units }) => $units && Object.keys($units).length > 0 && '68%'};
    margin-right: 2%;
  }

  .unit-select {
    width: 30%;
  }
`

const PropertyMapper = ({
  name,
  label,
  required = false,
  propertiesToMap,
  disableOptions = [],
  units,
  data,
  useOptions = () => ({}),
}) => {
  const { t } = useTranslation()
  const unitFormItemName = `${name}-unit`
  const [currentValue, setCurrentValue] = useState()

  const { data: options, isLoading: optionsAreLoading } = useOptions()
  if (optionsAreLoading) {
    return 'Options are loading...'
  }

  let rules = []
  if (required) {
    rules.push({
      required: true,
      message: t('parts_master_data.upload.required_field_prompt'),
    })
  }

  if (options) {
    rules.push({
      validator: (_, columnName) => {
        if (columnName) {
          for (const { [columnName]: value } of data) {
            if (!value) {
              // ignore empty cells
              continue
            }

            if (!options.some((option) => option.name === value)) {
              const msg = (
                <Trans
                  i18nKey="parts_master_data.upload.unknown_material_warning"
                  components={{
                    1: <br />,
                    value,
                    options: options.map((option) => option.name).join(', '),
                  }}
                />
              )
              return Promise.reject(msg)
            }
          }
        }
        return Promise.resolve()
      },
    })
  }

  return (
    <Wrapper $units={units}>
      <Form.Item label={t(label || name)}>
        <Form.Item name={name} noStyle={true} rules={rules}>
          <Select
            className="property-select"
            placeholder={t('parts_master_data.upload.select_placeholder')}
            allowClear={true}
            placement="bottomLeft"
            virtual={false}
            onChange={setCurrentValue}
          >
            {[...propertiesToMap]
              // sort array to show unmapped properties first
              .sort(
                (a, b) =>
                  disableOptions.includes(a) - disableOptions.includes(b),
              )
              .map((property) => {
                const alreadyMapped =
                  disableOptions.includes(property) && property !== currentValue
                return (
                  <Option
                    key={property}
                    value={property}
                    disabled={alreadyMapped}
                    title={
                      alreadyMapped
                        ? t('parts_master_data.upload.column_already_mapped')
                        : ''
                    }
                  >
                    {property}
                  </Option>
                )
              })}
          </Select>
        </Form.Item>
        {units && Object.keys(units).length > 0 && (
          <Form.Item name={unitFormItemName} noStyle={true} initialValue={1}>
            <Select className="unit-select" virtual={false}>
              {Object.keys(units).map((unit) => (
                <Option key={unit} value={units[unit]}>
                  {unit}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>
    </Wrapper>
  )
}

export default PropertyMapper
