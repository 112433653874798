import styled from 'styled-components'
import { Tag } from 'antd'

export const StyledTag = styled(Tag)`
  margin: 15px 15px;
  position: absolute;
  z-index: 1;
  opacity: 0.8;
  font-size: 10px;
`
