import Footer from '$components/Footer'
import { StyledLayout } from '$globalStyledComponents/Layout'
import { useTranslation } from 'react-i18next'
import OrderPartsListTable from '$components/OrderPartsListTable'
import Topwelcomecard from './Topwelcomecard'
import { StyledCardContent } from './styledComponents'

const { Content } = StyledLayout

const Myorders = () => {
  const { t } = useTranslation()

  return (
    <>
      <StyledLayout className="layout">
        <Content>
          <h1 style={{ margin: '46px 0' }}>{t('my_orders.title')}</h1>
          <Topwelcomecard />
          <StyledCardContent>
            <OrderPartsListTable />
          </StyledCardContent>
        </Content>
        <Footer />
      </StyledLayout>
    </>
  )
}

export default Myorders
