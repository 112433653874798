import { Segmented, Table } from 'antd'
import styled from 'styled-components'

export const StyledSegmented = styled(Segmented)`
  .ant-segmented-item {
    width: 33.3%;
  }
`
export const StyledTable = styled(Table)`
  .ant-table-selection-column {
    display: none;
  }
  .ant-table-tbody {
    cursor: pointer;
  }
  .ant-table-row-selected {
    background-color: #e6f7ff;
  }
  .userlistnamecol {
    width: 170px;
  }
`
