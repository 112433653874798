import { Button } from 'antd'
import styled from 'styled-components'

export const StyledButton = styled(Button)`
  height: 43px;
  background: ${(props) => props.theme.colorPrimary};
  color: #fff;
  font-size: large;
  border: none;
`
