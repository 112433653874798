import { LabelText } from '$components/Services/styledComponents'
import DownloadFiles from '$components/Services/OrderedServicesDetails/DownloadFiles'
import { Col, Divider, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useUser } from '$context/user'

const ApplyBrandingDetails = ({ record }) => {
  const { isServiceProvider } = useUser()
  const { t } = useTranslation()

  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col span={12} style={{ paddingRight: 30 }}>
          {record?.user_file_locations?.length > 0 && (
            <DownloadFiles record={record} style={{ marginBottom: '18px' }} />
          )}
          <LabelText>{t('Preview')}</LabelText>
        </Col>
        <Col span={12}>
          {isServiceProvider ? (
            <>
              <LabelText>{t('info')}</LabelText>
              <p>{t('bookedservices.execute_service')}</p>
              <Divider />
            </>
          ) : null}
          <LabelText>{t('Details')}</LabelText>
          <Row style={{ width: '100%' }}>
            <Col span={8}>{t('service.apply_branding.writing')}:</Col>
            <Col span={16}>
              <b>{record.branding}</b>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>{t('service.apply_branding.font_face')}: </Col>
            <Col span={16}>
              <b>{record.font}</b>
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={8}>{t('service.apply_branding.font_size')}: </Col>
            <Col span={16}>
              <b>{record.font_size} mm</b>
            </Col>
          </Row>
          {record.attribute_names.length > 0 && (
            <>
              <Divider />
              <LabelText>
                {t('service.apply_branding.additional_specifications')}
              </LabelText>
              <ul>
                {record.attribute_names.map((str, index) => (
                  <li key={index}>{str}</li>
                ))}
              </ul>
            </>
          )}
        </Col>
      </Row>
      <Divider />
      <LabelText>{t('service.apply_branding.position_and_type')}</LabelText>
      <Row>
        <Col span={12} style={{ paddingRight: 30 }}>
          {t('service.apply_branding.min_max_size')}
          {record.min_size !== null ? (
            <>
              <div style={{ marginBottom: '15px' }}>
                <Row style={{ width: '100%' }}>
                  <Col span={8}>von - bis: </Col>
                  <Col span={16}>
                    <b>
                      {record.min_size} - {record.max_size} mm
                    </b>
                  </Col>
                </Row>
              </div>
              {t('service.apply_branding.if_not_fits')}
              <p>
                <i>{record.what_should_be_done_if_not_fit}</i>
              </p>
            </>
          ) : (
            <p style={{ textIndent: '15px' }}>
              <b>{t('No')}</b>
            </p>
          )}
          {t('service.apply_branding.invisibly_branded')}
          {record.is_invisibly_branded ? (
            <p style={{ textIndent: '15px' }}>
              <b>{t('Yes')}</b>
            </p>
          ) : (
            <p style={{ textIndent: '15px' }}>
              <b>{t('No')}</b>
            </p>
          )}
          {t('service.apply_branding.product_authentication')}
          {record.authentification ? (
            <p style={{ textIndent: '15px' }}>
              <b>{t('Yes')}</b>
            </p>
          ) : (
            <p style={{ textIndent: '15px' }}>
              <b>{t('No')}</b>
            </p>
          )}
        </Col>
        <Col span={12}>
          {t('Is there a visible side')}
          {record.is_visible_side ? (
            <div>
              <p style={{ textIndent: '15px' }}>
                <b>{t('Yes')}</b>
              </p>
              <div>{t('Where?')}</div>
              <p>
                <i>{record.visible_side_description}</i>
              </p>
            </div>
          ) : (
            <p style={{ textIndent: '15px' }}>
              <b>{t('No')}</b>
            </p>
          )}
          {t('service.apply_branding.always_on_nonvisible_side')}
          {record.branding_on_non_visible_side ? (
            <p style={{ textIndent: '15px' }}>
              <b>{t('Yes')}</b>
            </p>
          ) : (
            <p style={{ textIndent: '15px' }}>
              <b>{t('No')}</b>
            </p>
          )}
        </Col>
      </Row>
    </>
  )
}

export default ApplyBrandingDetails
