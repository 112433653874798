import { useQuery } from '@tanstack/react-query'

import { fetchAllMaterials } from '$api/evoAPIs'

const useMaterialMapping = () => {
  const query = useQuery(['materials'], fetchAllMaterials, {
    select: (data) =>
      Object.fromEntries(data.map((material) => [material.id, material])),
  })

  return query
}

export default useMaterialMapping
