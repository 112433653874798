import { getPartStlHead } from '$api/client'
import { List } from 'antd'

const NotBookableReason = (title, description) => ({
  title: title,
  description: description,
})

export const machbarkeitsPruefung = async (
  parts_to_be_checked,
  essential_attributes,
  service_name,
  t,
) => {
  // merge attributes to check against
  for (const array of parts_to_be_checked) {
    array.mergedattributes = [...array.attributes, ...array.float_attributes]
  }
  let bookableParts = []
  let notBookableParts = []

  const checkIf3DModelExists = () => {
    return new Promise((resolve, reject) => {
      Promise.allSettled(
        parts_to_be_checked.map((part) => getPartStlHead(part.id)),
      )
        .then((results) => {
          results.forEach((result, index) => {
            if (result.status === 'rejected') {
              notBookableParts.push({
                ...parts_to_be_checked[index],
                reason: {
                  title: t('service.not_processable_because.no_3d_model.title'),
                  description: t(
                    'service.not_processable_because.no_3d_model.description',
                  ),
                },
              })
            } else {
              bookableParts.push(parts_to_be_checked[index])
            }
          })

          resolve({ bookableParts, notBookableParts })
        })
        .catch((error) => {
          console.error('Error handling part checks:', error)
          reject(error)
        })
    })
  }

  const check3DModelForOrderPart = async () => {
    let promises = parts_to_be_checked.map((part) => getPartStlHead(part.id))
    const results = await Promise.allSettled(promises)
    return results.map((result, index) => ({
      id: parts_to_be_checked[index].id,
      has3DModel: result.status === 'fulfilled',
    }))
  }

  switch (service_name) {
    case 'Apply Branding':
      await checkIf3DModelExists()
      break
    case 'Apply Texturing':
      await checkIf3DModelExists()
      break
    case 'Order Part': {
      // Check for 3D model existence first
      const modelChecks = await check3DModelForOrderPart()
      parts_to_be_checked.forEach((part) => {
        const modelCheck = modelChecks.find((mc) => mc.id === part.id)
        part.has3DModel = modelCheck.has3DModel
      })

      // Now check feasibility check
      for (const part of parts_to_be_checked) {
        let fcResult = part?.attributes?.find(
          (x) => x.key === 'Feasibility Check',
        )?.value
        let bookable = fcResult === 'true' && part.has3DModel

        if (bookable) {
          bookableParts.push(part)
        } else {
          let titles = []
          let descriptions = []

          if (fcResult === 'false') {
            titles.push(
              t(
                'service.not_processable_because.feasibility_check_negative.title',
              ),
            )
            descriptions.push(
              t(
                'service.not_processable_because.feasibility_check_negative.description',
              ),
            )
          } else if (fcResult !== 'true') {
            titles.push(
              t('service.not_processable_because.no_feasibility_check.title'),
            )
            descriptions.push(
              t(
                'service.not_processable_because.no_feasibility_check.description',
              ),
            )
          }
          if (!part.has3DModel) {
            titles.push(t('service.not_processable_because.no_3d_model.title'))
            descriptions.push(
              t('service.not_processable_because.no_3d_model.description'),
            )
          }

          // Join titles and descriptions with a comma if multiple reasons exist
          let title = titles.join(', ')
          let description = (
            <List
              dataSource={descriptions}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          )

          notBookableParts.push({
            ...part,
            reason: NotBookableReason(title, description),
          })
        }
      }
      break
    }
    case 'Generate 3D Model':
      notBookableParts = notBookableParts.map(
        (part) => (part.has3dmodel = false),
      )
      bookableParts = bookableParts.map((part) => (part.has3dmodel = true))
      await checkIf3DModelExists()
      break
    case 'Feasibility Check': // Fall Through
    default: {
      // Schleife durch jedes Array in parts_to_be_checked
      for (const part of parts_to_be_checked) {
        let matchedArr = []
        // Schleife durch jedes Objekt im aktuellen Array
        for (const obj1 of essential_attributes) {
          // Überprüfe, ob das Objekt einen passenden Schlüssel hat
          for (const obj2 of part.mergedattributes) {
            if (obj1 === obj2.key) {
              matchedArr.push(obj1)
            }
          }
        }
        // Wenn alle Objekte im Array übereinstimmen, füge das Array zu bookableParts hinzu
        if (matchedArr.length === essential_attributes.length) {
          delete part.mergedattributes
          bookableParts.push(part)
        }
      }
      //finde und lösche parts die nicht "buchbar" sind
      for (const part of parts_to_be_checked) {
        const match = bookableParts.some((obj2) => obj2.id === part.id)
        if (!match) {
          const set_difference = (A, B) => A.filter((a) => !B.includes(a))

          notBookableParts.push({
            ...part,
            reason: NotBookableReason(
              t(
                'service.not_processable_because.missing_essential_attributes.title',
              ),
              t(
                'service.not_processable_because.missing_essential_attributes.description',
                {
                  missing_attributes: set_difference(
                    essential_attributes,
                    part.mergedattributes.map((attr) => attr.key),
                  )
                    .map((attr) => `"${attr}"`)
                    .join(', '),
                },
              ),
            ),
          })
        }
      }
    }
  }
  return {
    bookableParts,
    notBookableParts,
  }
}
