import { useState, useEffect } from 'react'
import { TableHeader, StyledTable } from './styledComponents'
import createColumns from './createColumns'
import ExpandedRow from './ExpandedRow'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

notification.config({
  placement: 'top',
  duration: 5,
})

const Brandingconfigtable = (props) => {
  const { t } = useTranslation()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [status, setStatus] = useState([false, false])

  useEffect(() => {
    if (props.bookableParts) {
      for (let i in props.bookableParts.bookableParts) {
        props.bookableParts.bookableParts[i].value = status[0]
      }

      setData(props.bookableParts.bookableParts)
      setLoading(false)

      if (props.bookableParts.bookableParts.length > 0) {
        setExpandedRowKeys([props.bookableParts.bookableParts[0].id])
      }
    }
  }, [props.bookableParts, status])

  const onTableRowExpand = (expanded, record) => {
    const keys = []
    if (expanded) {
      keys.push(record.id)
    }
    setExpandedRowKeys(keys)
  }

  useEffect(() => {
    if (status[1]) {
      const newDataSource = [...data]
      for (let i in newDataSource) {
        if (status[1] === newDataSource[i].id)
          newDataSource[i].value = status[0]
      }
      setData(newDataSource)

      // Check if all .value are true, then enable "next" button
      if (newDataSource.every((obj) => obj.value === true)) {
        props.setConfigState(true)
      }
    }
  }, [status, data, props])

  return (
    <div>
      <TableHeader></TableHeader>
      <StyledTable
        loading={loading}
        columns={createColumns(t, expandedRowKeys, onTableRowExpand)}
        expandable={{
          expandRowByClick: true,
          expandIconAsCell: false,
          expandIconColumnIndex: -1,
          showExpandColumn: true,
          expandedRowRender: (record) => (
            <ExpandedRow
              setBookableParts={props.setBookableParts}
              setConfigState={props.setConfigState}
              configState={props.configState}
              record={record}
              setStatus={setStatus}
              status={status}
              setCurrentFormValues={props.setCurrentFormValues}
            />
          ),
          rowExpandable: () => true,
        }}
        dataSource={data}
        expandedRowKeys={expandedRowKeys}
        onExpand={onTableRowExpand}
        rowKey={(part) => part.id}
      />
    </div>
  )
}

export default Brandingconfigtable
