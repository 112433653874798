import { PlusCircleOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StyledModal } from '../styledComponents'
import InputForm from './InputForm'
import INTERNAL_PROPERTIES from '../UploadExcelButton/internalProperties'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { postPart, putPartsToGroup } from '$api/client'
import { theme } from 'antd'
import { isDemoError } from '$utils/demo'

const { useToken } = theme

const CreatePartButton = ({ groups, activeGroup }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)
  const { token } = useToken()

  const addPartMutation = useMutation({
    mutationFn: postPart,
    onSuccess: (part, variables) => {
      if (variables.group) {
        addToGroupMutation.mutate({
          partsIds: [part.data.id],
          groupId: variables.group,
        })
      } else {
        setLoading(false)
        queryClient.refetchQueries(['allpartspaginated'])
        queryClient.invalidateQueries(['parts'])
      }
    },
    onError: (err) => {
      setLoading(false)
      console.error(err)
      if (!isDemoError(err)) {
        message.error(`there was an error!`)
      }
    },
  })

  const addToGroupMutation = useMutation({
    mutationFn: putPartsToGroup,
    onSuccess: () => {
      setLoading(false)
      queryClient.refetchQueries(['allpartspaginated'])
      queryClient.invalidateQueries(['parts'])
    },
    onError: (err) => {
      setLoading(false)
      console.error(err)
      if (!isDemoError(err)) {
        message.error(`there was an error!`)
      }
    },
  })
  const getPart = (mapping) => {
    // part definition
    const partToAdd = {
      id_part_client: mapping.id_part_client,
      name: mapping.name,
      attributes: [],
      float_attributes: [],
      status_attributes: [],
    }

    // add float attributes
    for (const internalProperty of INTERNAL_PROPERTIES.filter(
      (val) => val.float_attribute,
    )) {
      const value = mapping[internalProperty.name]
      if (typeof value === 'number') {
        partToAdd.float_attributes.push({
          key: internalProperty.name,
          value: value * (mapping?.[`${internalProperty.name}-unit`] || 1),
        })
      }
    }

    // add other attributes
    for (const internalProperty of INTERNAL_PROPERTIES.filter(
      (val) => val.attribute,
    )) {
      const value = mapping[internalProperty.name]
      if (value) {
        partToAdd.attributes.push({
          key: internalProperty.name,
          value: value,
        })
      }
    }

    return partToAdd
  }

  const handleOk = (mappingFormValues) => {
    setIsModalOpen(false)

    const partData = getPart(mappingFormValues)
    if (typeof mappingFormValues.group === 'object') {
      mappingFormValues.group = mappingFormValues.group.value
    }

    addPartMutation.mutate(
      { ...partData, group: mappingFormValues.group },
      {
        onSuccess: () => {
          message.success(
            t('parts_master_data.create.success_message', {
              count: 1,
            }),
          )
        },
      },
    )
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Button
        onClick={() => setIsModalOpen(true)}
        icon={<PlusCircleOutlined />}
        size="large"
        loading={loading}
        style={{
          borderRadius: '0 10px 10px 0',
          borderColor: token.colorPrimary,
          color: token.colorPrimary.substring(1),
        }}
      >
        {t('parts_master_data.create.new_button')}
      </Button>
      <StyledModal
        destroyOnClose="true"
        centered="true"
        title={t('parts_master_data.create.create_part')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            form="addPartForm"
            key="submit"
            htmlType="submit"
            type="primary"
          >
            {t('button.create')}
          </Button>,
        ]}
      >
        <InputForm
          name="addPartForm"
          internalProperties={INTERNAL_PROPERTIES}
          handleOk={handleOk}
          groups={groups}
          activeGroup={activeGroup}
        />
      </StyledModal>
    </>
  )
}
export default CreatePartButton
