import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { fetchAllFCServices } from '$api/evoAPIs'
import { App } from 'antd'
import { updateStatusAttributes } from '$utils'
import { useRefetchTimer } from '$context/RefetchTimerProvider'
import { evoClient, putPart } from '$api/client'

export const FcUpdateParts = () => {
  const queryClient = useQueryClient()
  const { stopRefetch } = useRefetchTimer()
  const { message, notification } = App.useApp()

  let arrOfNotUpdatedIds
  try {
    arrOfNotUpdatedIds = JSON.parse(localStorage.getItem('notupdatedfc'))
  } catch {
    arrOfNotUpdatedIds = []
  }

  const updateClientPartStatus = useMutation(
    async (fcObject) => {
      const currentPartsData = queryClient.getQueryData(['allparts'])
      // find the part to which the fcObject refers
      let currentPart = currentPartsData.items.find(
        (part) => fcObject.part.db_id_client === part.id,
      )

      // if "Feasibility Check" was feasible change status in part
      if (fcObject.feasible_printers.length > 0) {
        currentPart = updateStatusAttributes(
          currentPart,
          'attributes',
          'Feasibility Check',
          'true',
        )
        currentPart = updateStatusAttributes(
          currentPart,
          'attributes',
          'Technical Suitability',
          'true',
        )
        currentPart = updateStatusAttributes(
          currentPart,
          'status_attributes',
          'Feasibility Check',
          '1',
        )
      } else {
        currentPart = updateStatusAttributes(
          currentPart,
          'attributes',
          'Feasibility Check',
          'false',
        )
        currentPart = updateStatusAttributes(
          currentPart,
          'attributes',
          'Technical Suitability',
          'false',
        )
        currentPart = updateStatusAttributes(
          currentPart,
          'status_attributes',
          'Feasibility Check',
          '1',
        )
      }

      //updateStatusAttributes(part, "attributes", "Feasibility Check", true)
      return evoClient.put(putPart + currentPart.id, currentPart, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['parts'])
        queryClient.invalidateQueries(['bookedServices'])
        queryClient.invalidateQueries(['orderPartsLists'])
        queryClient.invalidateQueries(['allparts'])

        notification.success({
          message: 'Hinweis',
          description:
            "Alle 'Feasibility Checks' sind fertig. Die betreffenden Parts wurden aktualisiert",
          onClick: () => {},
          placement: 'top',
        })
        localStorage.setItem('notupdatedfc', '')
        stopRefetch()
      },
      onError: (err) => {
        message.error(err.response.status + ', Fehler')
      },
    },
  )

  useQuery(['feasibilitycheck'], fetchAllFCServices, {
    //enabled: typeof bsData !== "undefined",

    onSuccess: (data) => {
      if (!arrOfNotUpdatedIds || arrOfNotUpdatedIds.length === 0) return

      // Filter only those parts that have IDs matching the not updated IDs
      const fcObjectsData = data.filter((fcObject) =>
        arrOfNotUpdatedIds.includes(fcObject.id),
      )

      // Mutate each part that needs to be updated
      fcObjectsData.forEach((fcObject) => {
        updateClientPartStatus.mutate(fcObject)
      })
    },
  })

  useEffect(() => {
    if (!arrOfNotUpdatedIds || arrOfNotUpdatedIds.length === 0) {
      stopRefetch()
    }
  })
}
