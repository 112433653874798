import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Input, Button, Select } from 'antd'
import { UilSearch } from '@iconscout/react-unicons'

const DEFAULT_OPERATOR = '='
const LABELS = {
  '>=': '≥',
  '<=': '≤',
  contains: '=',
}
const BUTTON_WIDTH = 90
const GAP = 8
const DROPDOWN_WIDTH = BUTTON_WIDTH * 2 + GAP

const FilterDropdown = ({
  confirm,
  clearFilters,
  setSelectedKeys,
  operators = [DEFAULT_OPERATOR],
  parseValue = (id) => id,
}) => {
  const defaultSearchTerm = ''
  const defaultsearchOperator = operators[0] ?? DEFAULT_OPERATOR

  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm)
  const [searchOperator, setSearchOperator] = useState(defaultsearchOperator)

  const handleSearch = () => {
    setSelectedKeys([{ searchTerm, searchOperator }])
    confirm()
  }

  const handleReset = () => {
    clearFilters()
    confirm({ closeDropdown: true })
    setSearchTerm(defaultSearchTerm)
    setSearchOperator(defaultsearchOperator)
  }

  const isSingleOperatorOnly = operators.length === 1
  const options = operators.map((operator) => ({
    value: operator,
    label: LABELS[operator] ?? operator,
  }))

  return (
    <div style={{ padding: 8, width: DROPDOWN_WIDTH }}>
      <div
        style={{
          display: 'flex',
          marginBottom: 8,
          gap: GAP,
        }}
      >
        {isSingleOperatorOnly ? (
          ''
        ) : (
          <Select
            defaultValue="="
            options={options}
            onChange={(value) => {
              setSearchOperator(value)
            }}
          />
        )}
        <Input
          placeholder={t('Search term')}
          onChange={(e) => {
            setSearchTerm(parseValue(e.target.value))
          }}
          onPressEnter={handleSearch}
          value={searchTerm}
        />
      </div>
      <div style={{ display: 'flex', gap: GAP }}>
        <Button
          type="primary"
          icon={
            <UilSearch
              style={{ height: '14px', margin: '0px 0px -3px -6px' }}
            />
          }
          size="small"
          style={{ width: BUTTON_WIDTH }}
          onClick={handleSearch}
        >
          {t('button.search')}
        </Button>
        <Button
          size="small"
          style={{ width: BUTTON_WIDTH }}
          onClick={handleReset}
        >
          {t('button.reset')}
        </Button>
      </div>
    </div>
  )
}

export default FilterDropdown
