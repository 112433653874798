import { Table } from 'antd'
import styled from 'styled-components'

export const StyledTable = styled(Table)`
  table {
    border-spacing: 0 5px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }

  tr {
    background-color: white;
    cursor: pointer;
  }

  .ant-table-expanded-row {
    cursor: default;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
  }
`
export const TableNumberButton = styled.span`
  font-weight: 600;
  color: ${(props) => props.theme.colorPrimary};
`
