// eslint-disable-next-line no-restricted-imports
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const FormWrapper = ({ children, ...props }) => {
  /**
   * This is a wrapper for Ant Design's Form component to fix
   * an issue, where changing the language doesn't update validation error text.
   * See VBS-2072 and VBS-1702 and https://gitlab.com/susiandjames/projects/evobus/dwh/evobus-ui/-/merge_requests/540#note_1979909920 for context
   * and also the solution is a mix of https://react.dev/learn/you-might-not-need-an-effect#how-to-remove-unnecessary-effects and
   * https://github.com/ant-design/ant-design/issues/42347.
   */

  const { i18n } = useTranslation()

  // Fix: Re-validate form fields on change of language
  useEffect(() => {
    if (!props.form) {
      return
    }

    const errorFields = props.form.getFieldsError().reduce((arr, field) => {
      if (field.errors.length) {
        arr.push(field.name)
      }
      return arr
    }, [])
    props.form.validateFields(errorFields)
  }, [i18n.language, props.form])

  return <Form {...props}>{children}</Form>
}

for (const prop in Form) {
  FormWrapper[prop] = Form[prop]
}

export default FormWrapper
