import { useState } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { StyledLayout } from '$globalStyledComponents/Layout'
import { StyledCard, StyledUserCard } from '$globalStyledComponents/Card'
import Footer from '$components/Footer'
import Companyform from './Companyform'
import Companylist from './Companylist'
import Companycard from './Companycard'
import Subcompanies from './Subcompanies'

const { Content } = StyledLayout

const Company = () => {
  const { t } = useTranslation()
  // set the company of the user ["company name", isMainCompany?, shouldUpdate?]
  const [userCompany, setUserCompany] = useState(['', false])
  // set the selected company
  const [company, setCompany] = useState('')
  const [companyID, setCompanyID] = useState('')
  const [subCompanyCount, setSubCompanyCount] = useState()
  const [isNewCompany, setIsNewCompany] = useState(false)
  // subcompanies component delete Button
  const [deleteCompany, setDeleteCompany] = useState('')

  return (
    <StyledLayout className="layout">
      <Content>
        <h1 style={{ margin: '50px 0' }}>{t('company_master_data.title')}</h1>
        <Row>
          <Col flex="450px">
            <StyledUserCard>
              <Companycard
                company={company}
                subCompanyCount={subCompanyCount}
                companyID={companyID}
                userCompany={userCompany}
              />
            </StyledUserCard>
            <StyledCard>
              <Companylist
                className="contentcard"
                company={company}
                setCompanyID={setCompanyID}
                setIsNewCompany={setIsNewCompany}
                isNewCompany={isNewCompany}
                userCompany={userCompany}
              />
            </StyledCard>
          </Col>
          <Col flex="auto">
            <Companyform
              setCompany={setCompany}
              company={company}
              setCompanyID={setCompanyID}
              companyID={companyID}
              setSubCompanyCount={setSubCompanyCount}
              setIsNewCompany={setIsNewCompany}
              isNewCompany={isNewCompany}
              deleteCompany={deleteCompany}
              setUserCompany={setUserCompany}
              userCompany={userCompany}
            />
            <Subcompanies
              company={company}
              setCompanyID={setCompanyID}
              setDeleteCompany={setDeleteCompany}
              userCompany={userCompany}
            />
          </Col>
        </Row>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default Company
