import { UilUpload } from '@iconscout/react-unicons'
import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { StyledUpload } from './styledComponents'

const UploadFiles = ({ setFileList, fileList }) => {
  const [buttonHeight, setButtonHeight] = useState('200px')

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  useEffect(() => {
    if (fileList.length > 0) {
      setButtonHeight('58px')
    } else {
      setButtonHeight('148px')
    }
  }, [fileList])

  return (
    <>
      <StyledUpload
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            onSuccess('ok')
          }, 0)
        }}
        listType="picture"
        defaultFileList={fileList}
        fileList={fileList}
        onChange={handleChange}
      >
        <Button
          block
          style={{ height: buttonHeight, marginTop: 10 }}
          icon={
            <UilUpload
              style={{ height: '15px', margin: '0px 9px -3px -1px' }}
            />
          }
        >
          Upload
        </Button>
      </StyledUpload>
    </>
  )
}
export default UploadFiles
