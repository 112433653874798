import { LabelText } from '$components/Services/styledComponents'
import { useUser } from '$context/user'
import DownloadFiles from '$components/Services/OrderedServicesDetails/DownloadFiles'
import { Col, Divider, Row } from 'antd'
import { useTranslation } from 'react-i18next'

const ApplyTexturingDetails = ({ record }) => {
  const { isServiceProvider } = useUser()
  const { t } = useTranslation()
  return (
    <>
      <Row style={{ width: '100%' }}>
        <Col span={12} style={{ paddingRight: 30 }}>
          {record?.user_file_locations?.length > 0 && (
            <DownloadFiles record={record} style={{ marginBottom: '18px' }} />
          )}
          <LabelText>{t('Details')}</LabelText>
          {t('Is there a visible side')}
          {record.visible_side ? (
            <div>
              <p style={{ textIndent: '15px' }}>
                <b>{t('Yes')}</b>
              </p>
              <div>{t('Where?')}</div>
              <p>
                <i>{record.visible_where}</i>
              </p>
              {t('service.apply_texturing.only_on_visible_side')}
              {record.not_visible_side ? (
                <p style={{ textIndent: '15px' }}>
                  <b>{t('Yes')}</b>
                </p>
              ) : (
                <p style={{ textIndent: '15px' }}>
                  <b>{t('No')}</b>
                </p>
              )}
            </div>
          ) : (
            <p style={{ textIndent: '15px' }}>
              <b>{t('No')}</b>
            </p>
          )}
        </Col>
        <Col span={12}>
          {isServiceProvider ? (
            <>
              <LabelText>{t('info')}</LabelText>
              <p>{t('bookedservices.execute_service')}</p>
              <Divider />
            </>
          ) : null}
          <LabelText>
            {t('service.apply_texturing.additional_information')}
          </LabelText>
          <Row style={{ width: '100%' }}>
            <Col span={8}>
              {t('service.apply_texturing.depth_of_surface_texture')}:
            </Col>
            <Col span={16}>
              <p>
                <b>{record.depth_of_grain} µm</b>
              </p>
            </Col>
          </Row>
          {record.specifications && (
            <>
              <div>
                {t('service.apply_texturing.additional_specifications')}:
              </div>
              <p>
                <i>{record.specifications}</i>
              </p>
            </>
          )}
        </Col>
      </Row>
    </>
  )
}

export default ApplyTexturingDetails
