import { fetchAllBookedServicesQueries } from '$api/evoAPIs'
import Ordersandcustomerstotal from '$components/Ordersandcustomerstotal'
import Orderstotal from '$components/Orderstotal'
import { useQuery } from '@tanstack/react-query'
import { Col, Row } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandedRow from './ExpandedRow'
import createColumns from './createColumns'
import { StyledTable } from './styledComponents'

const Myservicestable = ({ currentTab }) => {
  const { t } = useTranslation()
  const [pageNr, setPageNr] = useState(1)
  const [total, setTotal] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [sorting, setSorting] = useState({
    key: 'time_stamp',
    order: -1,
  })

  const convertToMachineServiceName = (serviceName) => {
    if (serviceName === 'All Services') return null
    if (serviceName === 'Feasibility Check') return 'feasibility_check'
    if (serviceName === 'Generate 3D Model') return 'generate_3d_model'
    if (serviceName === 'Apply Branding') return 'apply_branding'
    if (serviceName === 'Apply Texturing') return 'apply_texturing'
    if (serviceName === 'Digital Parts Report') return 'digital_parts_report'
    else return null
  }
  const currentService = convertToMachineServiceName(currentTab)

  const { data: orders, isLoading: loading } = useQuery({
    queryKey: [
      'allBookedServices',
      {
        pageNr,
        pageSize,
        sorting,
        currentService,
      },
    ],
    queryFn: fetchAllBookedServicesQueries,
    placeholderData: { items: [] },
    onSuccess: ({ total }) => {
      setTotal(total)
    },
    retry: 0,
  })

  const onChange = (_pagination, _tableFilters, sorter, { action }) => {
    if (action === 'sort') {
      setSorting({
        key:
          sorter.field ||
          sorter.column?.field ||
          sorter.column?.title ||
          undefined,
        order: sorter.order === 'ascend' ? 1 : -1,
      })
      setPageNr(1)
    }
  }

  return (
    <Row className="myservicescontent">
      <Col flex="440px">
        <div className="myserviceskpi">
          <Ordersandcustomerstotal serviceName={currentTab} />
          <Orderstotal tab={currentTab} />
        </div>
      </Col>
      <Col flex="880px">
        <div className="servicestablecard">
          <StyledTable
            loading={loading}
            columns={createColumns(t)}
            expandable={{
              showExpandColumn: false,
              expandedRowRender: (record) => <ExpandedRow record={record} />,
              rowExpandable: () => true,
              expandRowByClick: true,
            }}
            rowKey={(record) => record.id}
            dataSource={orders?.items || []}
            pagination={{
              total: total,
              onChange: setPageNr,
              onShowSizeChange: (_, size) => {
                setPageSize(size)
              },
              showSizeChanger: true,
              pageSize: pageSize,
              align: 'start',
            }}
            onChange={onChange}
          />
        </div>
      </Col>
    </Row>
  )
}

export default Myservicestable
