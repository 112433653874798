import { setProviderAcceptedOffer } from '$api/evoAPIs'
import Form from '$components/Form'
import OfferAdditionalContent from '$components/OrderPartsListTable/ExpandedRow/OfferAdditionalContent'
import SlideContentWrapper from '$components/SlideContentWrapper'
import Tooltip from '$components/Tooltip'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Divider, Input, Modal, message } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateOfferDetailView from './CreateOfferDetailView'
import ExpandedHeader from './ExpandedHeader'
import RequestedPartsTable from './RequestedPartsTable'

const { TextArea } = Input

const CreateOffersView = ({
  orderPartsList,
  setOrderPartsFormValues,
  orderPartsFormValues,
  deleteRule,
  deleteProviderOffer,
}) => {
  const { t } = useTranslation()
  const [messageApi, messageContextHolder] = message.useMessage()
  const queryClient = useQueryClient()

  const [orderPartsListForm] = Form.useForm()
  const [rejectOfferForm] = Form.useForm()

  const subOrders = [
    ...(orderPartsList.list_of_suborders?.length
      ? orderPartsList.list_of_suborders
      : [orderPartsList.main_order]),
  ]
  const [modal, contextHolder] = Modal.useModal()
  const [showDetailView, setShowDetailView] = useState(null)
  // for initializing the component. Make sure that each newly expanded row starts in the first view
  const [isFirstOpen, setIsFirstOpen] = useState(true)
  const [currentStepObject, setCurrentStepObject] = useState([0])
  const currentStep = currentStepObject[0]
  const [selectedPartKey, setSelectedPartKey] = useState(
    orderPartsList.list_of_orderparts[0].id,
  )
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const selectedOrderPart = orderPartsList.list_of_orderparts.find(
    (orderPart) => orderPart.id === selectedPartKey,
  )

  // derive current state of OrderPartsList from ProviderOffers on OrderParts
  const partsHaveProviderOffers = orderPartsList.list_of_orderparts.map(
    (orderPart) =>
      orderPart.provider_offers?.length && orderPart.provider_offers.length > 0,
  )
  const numberOfPartsWithProviderOffers = partsHaveProviderOffers.filter(
    (hasOffers) => hasOffers,
  ).length
  const allPartsHaveOffers = partsHaveProviderOffers.every(
    (hasOffers) => hasOffers,
  )

  const setProviderAcceptedOfferMutation = useMutation({
    mutationFn: setProviderAcceptedOffer,
    onSuccess: () => {
      queryClient.invalidateQueries('allorderedpartslists')
    },
  })

  const handleMakeOffer = async () => {
    try {
      await orderPartsListForm.validateFields()
      orderPartsListForm.submit()
    } catch {
      return // without sending request to accept offer
    }

    setProviderAcceptedOfferMutation.mutate(
      {
        order_part_list_id: orderPartsList.id,
        provider_accepted_offer: true,
        provider_rejection_reason: '',
      },
      {
        onSuccess: () => messageApi.success('Successfully made offer!'),
        onError: () => messageApi.error('Could not make offer!'),
      },
    )
  }

  const handleRejectOffer = ({ provider_rejection_reason }) => {
    setProviderAcceptedOfferMutation.mutate(
      {
        order_part_list_id: orderPartsList.id,
        provider_accepted_offer: false,
        provider_rejection_reason,
      },
      {
        onSuccess: () => {
          messageApi.success('Offer has been rejected!')
          setIsDeleteModalOpen(false)
        },
        onError: () => messageApi.error('Could not reject offer!'),
      },
    )
  }

  const showModal = () => {
    modal.confirm({
      title: t('received_requests.caution'),
      open: isDeleteModalOpen,
      onOk: () => rejectOfferForm.submit(),
      onCancel: handleCancel,
      centered: true,
      width: 700,
      content: (
        <Form form={rejectOfferForm} onFinish={handleRejectOffer}>
          <p>{t('received_requests.reject_dialog_1')}</p>
          <p>{t('received_requests.reject_dialog_2')}</p>
          <div style={{ margin: '15px 25px 15px 0' }}>
            <Form.Item name="provider_rejection_reason">
              <TextArea rows={8} />
            </Form.Item>
          </div>
        </Form>
      ),
    })
  }

  const handleCancel = () => {
    setIsDeleteModalOpen(false)
  }

  const next = (key) => {
    setShowDetailView(true)
    setIsFirstOpen(false)
    setCurrentStepObject([1, key])
    setSelectedPartKey(key)
  }

  const back = () => {
    setCurrentStepObject([0])
    setShowDetailView(false)
  }

  return (
    <>
      {messageContextHolder}
      {contextHolder}
      <ExpandedHeader
        offersCreatedPercentage={
          (numberOfPartsWithProviderOffers /
            (orderPartsList.list_of_orderparts.length || 1)) *
          100
        }
      />
      <div style={{ maxWidth: '1240px', overflow: 'hidden' }}>
        <SlideContentWrapper
          showDetailView={showDetailView}
          isFirstOpen={isFirstOpen}
          currentStep={currentStep}
          firstSlide={
            <>
              <h4 style={{ padding: '15px 0' }}>
                {t('received_requests.ordered_parts')}
              </h4>
              <RequestedPartsTable
                orderParts={orderPartsList.list_of_orderparts}
                showModal={showModal}
                next={next}
                currentStep={currentStep}
                orderPartsFormValues={orderPartsFormValues}
              />
              <OfferAdditionalContent
                form={orderPartsListForm}
                orderPartsList={orderPartsList}
                editable={true}
              />
              <Divider />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '15px 0',
                }}
              >
                <Button onClick={showModal}>
                  {t('received_requests.reject_request')}
                </Button>
                <Tooltip
                  title={
                    allPartsHaveOffers
                      ? ''
                      : 'Please create and save offer for all parts first!'
                  }
                >
                  <Button
                    type="primary"
                    disabled={!allPartsHaveOffers}
                    onClick={handleMakeOffer}
                  >
                    {t('received_requests.make_offer')}
                  </Button>
                </Tooltip>
              </div>
            </>
          }
          secondSlide={
            <CreateOfferDetailView
              key={selectedPartKey}
              back={back}
              orderPartsListId={orderPartsList.id}
              orderPart={selectedOrderPart}
              subOrders={subOrders}
              orderPartId={selectedPartKey}
              currentStep={currentStep}
              billingAddress={orderPartsList.billing_address}
              deliveryAddress={orderPartsList.delivery_address}
              setOrderPartsFormValues={setOrderPartsFormValues}
              orderPartsFormValues={orderPartsFormValues}
              deleteRule={deleteRule}
              deleteProviderOffer={deleteProviderOffer}
            />
          }
        />
      </div>
    </>
  )
}

export default CreateOffersView
