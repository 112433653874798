import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { Modal, Image } from 'antd'
import { fallback, checkFileSize } from '$utils'
import { StyledUpload } from '$pages/serviceuser/Marketplace/Bookservice/Applybranding/StepTwoBrandingConfig/Brandingconfigtable/ExpandedRow/styledComponents'
import { useQueryClient } from '@tanstack/react-query'

const ImageUpload = (props) => {
  const { t } = useTranslation()
  useQueryClient()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])

  const handleCancel = () => setPreviewOpen(false)
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewOpen(true)
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    )
  }
  const handleChange = ({ fileList: newFileList, file }) => {
    if (checkFileSize(file.size, '5 MB', t)) {
      props.setImageFile(file)
      setFileList(newFileList)
      setPreviewImage(newFileList[0]?.thumbUrl)
    }
  }

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
        props.setImageFile(reader.result)
      }
      reader.onerror = (error) => reject(error)
    })

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('button.upload_image')}
      </div>
    </div>
  )
  return (
    <>
      <StyledUpload
        onChange={handleChange}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        beforeUpload={() => {
          // always return false to disable default upload as we are handling that differently
          return false
        }}
      >
        {fileList.length >= 1 ? null : uploadButton}
      </StyledUpload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        width={800}
        centered
        transitionName=""
        destroyOnClose={true}
      >
        <Image
          src={previewImage}
          fallback={fallback}
          width="100%"
          preview={false}
        />
      </Modal>
    </>
  )
}
export default ImageUpload
