import React, { useState } from 'react'
import createColumnsCM from './createColumnsCM'
import { StyledTable } from './styledComponents'

const tData = (data) =>
  Array.isArray(data) && data?.map((el, i) => ({ key: i, ...el }))

const ClientMaterials = (props) => {
  const cmData = [
    {
      key: 'plastik',
      name: 'Plastik',
      count: '11',
      dwhmaterial: 'PA12',
    },
    {
      key: 'metall',
      name: 'Metall',
      count: '5',
      dwhmaterial: 'Stahl PLA12',
    },
    {
      key: 'stein',
      name: 'Stein',
      count: '1',
      dwhmaterial: '',
    },
  ]

  const onRow = (record) => {
    return {
      onClick: () => {
        props.setSelectedRowKeys([record.key])
        setSelectedRowKeys([record.key])
        // props.setUserID(record.id)
      },
    }
  }
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState(['plastik'])
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <>
      <StyledTable
        columns={createColumnsCM()}
        dataSource={tData(cmData)}
        rowSelection={rowSelection}
        onRow={onRow}
        rowKey={(record) => record.key}
      />
      {/* <p>Selected: {selectedRow ? selectedRow.name : "None"}</p> */}
    </>
  )
}

export default ClientMaterials
