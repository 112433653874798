import {
  UilCheckCircle,
  UilTimesCircle,
  UilExclamationCircle,
} from '@iconscout/react-unicons'
import { theme } from 'antd'

const { useToken } = theme

const Unicon = ({ iconName, colorToken, ...rest }) => {
  const { token } = useToken()

  const props = {
    size: 17,
    style: {
      margin: '0px 8px -4px 0',
    },
    color: token?.[colorToken],
    ...rest,
  }

  switch (iconName) {
    case 'check':
      return <UilCheckCircle {...props} />
    case 'exclamation':
      return <UilExclamationCircle {...props} />
    case 'times':
      return <UilTimesCircle {...props} />
    default:
      return null
  }
}

export const CheckCircle = (props) => (
  <Unicon iconName={'check'} colorToken={'colorSuccess'} {...props} />
)

export const TimesCircle = (props) => (
  <Unicon iconName={'times'} colorToken={'colorError'} {...props} />
)

export const ExclamationCircle = (props) => (
  <Unicon iconName={'exclamation'} colorToken={'colorWarning'} {...props} />
)
