import React from 'react'
import { Badge, Image } from 'antd'
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import { ImageContainer } from './styledComponents'
import { fallback } from '$utils'

const DownloadImage = () => {
  const { t } = useTranslation()

  return (
    <ImageContainer>
      <Badge.Ribbon text={t('marketplace.finished')}>
        <Image
          src="error"
          width="100%"
          preview={false}
          style={{ cursor: 'pointer' }}
          fallback={fallback}
        />
        <div
          className="overlay"
          // onClick={props.handlePreview}
        >
          <div
          //onClick={replaceStl}
          >
            <DownloadOutlined /> {t('Download')}
          </div>
          <div>
            <EyeOutlined /> {t('Preview')}
          </div>
        </div>
      </Badge.Ribbon>
    </ImageContainer>
  )
}

export default DownloadImage
