import { UilTrashAlt } from '@iconscout/react-unicons'
import { Button, Flex, InputNumber, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import CustomInputNumber from '$components/CustomInputNumber'
import {
  CENT,
  PERCENT,
  centToEuro,
  euroToCent,
} from '$components/OrderPartsListTable/utils'
import Form from '$components/Form'

const { Option } = Select

const CustomRule = ({
  typeOfRules,
  orderPartId,
  providerOfferId,
  ruleId,
  deleteRule,
}) => {
  const rulesKey = { price: 'price_rules', delivery: 'production_time_rules' }[
    typeOfRules
  ]
  const amount = { price: 'discount_amount', delivery: 'surcharge_amount' }[
    typeOfRules
  ]
  const condition = { price: 'min_parts', delivery: 'max_days' }[typeOfRules]
  const unit = { price: 'discount_unit', delivery: 'surcharge_unit' }[
    typeOfRules
  ]
  const { t } = useTranslation()

  const form = Form.useFormInstance()
  const formValues = Form.useWatch([], form)
  const providerOfferValues =
    formValues?.[orderPartId]?.provider_offers?.[providerOfferId]
  const selectedUnit = providerOfferValues?.[rulesKey][ruleId][unit]

  const euroDiscountValidator = (_, value) => {
    if (typeOfRules === 'price') {
      const rule = providerOfferValues.price_rules[ruleId]

      if (
        rule.discount_unit === CENT &&
        value >= providerOfferValues.base_price
      ) {
        return Promise.reject()
      }
    }

    return Promise.resolve()
  }

  const percentDiscountValidator = (_, value) => {
    if (typeOfRules === 'price') {
      const rule = providerOfferValues.price_rules[ruleId]

      if (rule.discount_unit === PERCENT && value >= 100) {
        return Promise.reject()
      }
    }

    return Promise.resolve()
  }

  const convertRuleAmount = (value) => {
    /*
      converts the rule amount based on the selected unit, ensuring
      that the same value is displayed for both units
    */
    const formItemName = [
      orderPartId,
      'provider_offers',
      providerOfferId,
      rulesKey,
      ruleId,
      amount,
    ]
    const fieldValue = form.getFieldValue(formItemName)

    if (fieldValue === undefined) return

    if (selectedUnit === undefined) return

    if (value === CENT) {
      form.setFieldValue(formItemName, euroToCent(fieldValue))
    } else if (value === PERCENT) {
      form.setFieldValue(formItemName, centToEuro(fieldValue))
    }
  }

  return (
    <Form.Item style={{ width: '100%', padding: '7px 10px' }}>
      <Flex justify="space-between" align="center">
        <Flex gap="small" align="center">
          {typeOfRules === 'price'
            ? t('received_requests.min_parts_text')
            : t('received_requests.max_days_text')}
          <Form.Item
            noStyle
            label={
              typeOfRules === 'price'
                ? t('received_requests.min_parts_label')
                : t('received_requests.max_days_label')
            }
            name={[
              orderPartId,
              'provider_offers',
              providerOfferId,
              rulesKey,
              ruleId,
              condition,
            ]}
            rules={[
              {
                required: true,
                type: 'number',
                min: 1,
              },
            ]}
          >
            <InputNumber
              size="small"
              style={{ width: '50px' }}
              min={1}
              max={99999}
            />
          </Form.Item>
          {`${
            typeOfRules === 'price' ? t('received_requests.parts') : t('days')
          }, `}
        </Flex>
        <Flex gap="small" align="center">
          <Form.Item
            noStyle
            className="no-required-asterisk"
            label={
              typeOfRules === 'price'
                ? t('received_requests.discount_amount')
                : t('received_requests.surcharge_amount')
            }
            name={[
              orderPartId,
              'provider_offers',
              providerOfferId,
              rulesKey,
              ruleId,
              amount,
            ]}
            rules={[
              {
                required: true,
                type: 'number',
                min: 0.01,
              },
              {
                validator: euroDiscountValidator,
                message: t('received_requests.discount_gte_base_price'),
              },
              {
                validator: percentDiscountValidator,
                message: t('received_requests.discount_gte_100_percent'),
              },
            ]}
          >
            <CustomInputNumber
              mode={selectedUnit === CENT && 'currency'}
              size="small"
              style={{ width: 100 }}
              decimalSeparator=","
              precision={typeOfRules === 'price' ? 2 : 0}
              min={0.01}
              addonAfter={
                <Form.Item
                  noStyle
                  name={[
                    orderPartId,
                    'provider_offers',
                    providerOfferId,
                    rulesKey,
                    ruleId,
                    unit,
                  ]}
                  initialValue="cent"
                  label={
                    typeOfRules === 'price'
                      ? t('received_requests.discount_unit')
                      : t('received_requests.surcharge_unit')
                  }
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    style={{ width: 49 }}
                    size="small"
                    onChange={(value) => {
                      convertRuleAmount(value)
                      if (typeOfRules === 'price') {
                        // validate correspondig value field
                        form.validateFields([
                          [
                            orderPartId,
                            'provider_offers',
                            providerOfferId,
                            rulesKey,
                            ruleId,
                            amount,
                          ],
                        ])
                      }
                    }}
                  >
                    <Option value="percent">%</Option>
                    <Option value="cent">€</Option>
                  </Select>
                </Form.Item>
              }
            />
          </Form.Item>
          {typeOfRules === 'price'
            ? t('received_requests.discount_per_part')
            : t('received_requests.surcharge_per_part')}
        </Flex>
        <Button
          type="secondary"
          size="small"
          onClick={() => deleteRule(ruleId)}
        >
          <UilTrashAlt size={17} />
        </Button>
      </Flex>
    </Form.Item>
  )
}

export default CustomRule
