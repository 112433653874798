import { useState } from 'react'
import { Row, Col, Card, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PropertyMapper from './PropertyMapper'
import Form from '$components/Form'

const FormHeader = styled.h2`
  font-size: 1.2em;
  font-weight: 500;

  margin: 20px 0;
`

const MappingForm = ({
  name = 'mapDataForm',
  internalProperties,
  propertiesToMap,
  handleOk,
  data,
  groups = [],
  activeGroup,
}) => {
  const { t } = useTranslation()
  const [mappedProperties, setMappedProperties] = useState([])
  const initialGroupValues = (activeGroup) => {
    return activeGroup === '1' ? undefined : activeGroup
  }

  return (
    <>
      <Card>{t('parts_master_data.upload.mapping_prompt')}</Card>

      <Form
        initialValues={{ group: { value: initialGroupValues(activeGroup) } }}
        onFinish={handleOk}
        name={name}
        labelAlign="left"
        labelCol={{ span: 10 }}
        requiredMark={false}
        onValuesChange={(_, allValues) => {
          setMappedProperties(
            Object.entries(allValues)
              .filter(([key, _]) => !key.endsWith('-unit'))
              .map(([_, val]) => val)
              .filter((val) => val !== undefined && val !== null),
          )
        }}
      >
        <Row>
          <Col span={10}>
            <FormHeader>
              {t('parts_master_data.upload.required_fields')}
            </FormHeader>
          </Col>
          <Col span={14}>
            <FormHeader>
              {t('parts_master_data.upload.mapping_column_header')}
            </FormHeader>
          </Col>
        </Row>

        {internalProperties
          .filter((property) => property.required)
          .map((property) => (
            <PropertyMapper
              key={property.name}
              propertiesToMap={propertiesToMap}
              disableOptions={mappedProperties}
              data={data}
              {...property}
            />
          ))}

        <Row>
          <Col span={10}>
            <FormHeader>
              {t('parts_master_data.upload.optional_fields')}
            </FormHeader>
          </Col>
          <Col span={14}>
            <FormHeader>
              {t('parts_master_data.upload.mapping_column_header')}
            </FormHeader>
          </Col>
        </Row>

        {internalProperties
          .filter((property) => !property.required)
          .map((property) => (
            <PropertyMapper
              key={property.name}
              propertiesToMap={propertiesToMap}
              disableOptions={mappedProperties}
              data={data}
              {...property}
            />
          ))}

        {groups.length > 0 ? (
          <>
            <Row>
              <Col span={10}>
                <FormHeader>
                  {t('parts_master_data.create.to_group')}
                </FormHeader>
              </Col>
              <Col span={14}></Col>
            </Row>

            <Form.Item
              label={t('parts_master_data.create.assign_group')}
              name="group"
            >
              <Select
                className="property-select"
                placeholder={t(
                  'parts_master_data.create.select_group_placeholder',
                )}
                allowClear={true}
                options={groups.map((item) => {
                  return {
                    value: item.id,
                    label: item.list_name,
                  }
                })}
              />
            </Form.Item>
          </>
        ) : null}
      </Form>
    </>
  )
}

export default MappingForm
