import styled from 'styled-components'
import { Space, Table, Button } from 'antd'

export const TableHeader = styled.div``

export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    background-color: ${(props) => props.theme.colorBgLightOne};
    padding: 0;
  }

  tr {
    background-color: white;
    //cursor: pointer;
  }

  th::before {
    display: none;
  }

  .feas-col {
    width: 80px;
  }
  .ant-table-wrapper {
    margin: 0;
  }
  .ant-table-row-selected {
    background: ${(props) => props.theme.colorPrimaryBg};
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px;
  }
`

export const StyledSpace = styled(Space)`
  .ant-space-item {
    width: 13.4%;
  }
`

export const StyledDiv = styled(Button)`
  background: ${(props) => props.theme.colorBgLightOne};
  border-color: ${(props) => props.theme.colorBgLightSix};
  cursor: auto;
`

export const SmServContainer = styled.div``

export const SmallServiceButton = styled.div`
  margin: -7px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  pointer: auto;

  .clickableservice:hover {
    background: #e7e7e7;
    border-color: ${(props) => props.theme.colorBgLightSix};
    margin: -7px;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .clickableservice {
    background: ${(props) => props.theme.colorBgLightSeven};
    border-color: ${(props) => props.theme.colorBgLightSix};
    margin: -7px;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
`
