import { Row, Col, Select, Button, Popover, List, Typography } from 'antd'
import styled from 'styled-components'
import { StyledSubTable } from './styledComponents'
import { useTranslation } from 'react-i18next'
import { currencyRenderer } from '$utils/i18n'
import {
  calculateBatchPrice,
  PERCENT,
  CENT,
  summarizeDeliveryTimes,
  centToEuro,
} from '../utils'
import Form from '$components/Form'

const { Text } = Typography

const StyledCol = styled(Col)`
  padding: 25px;
`

const OrderPartDetailView = ({
  orderPartListId,
  clientAcceptedOffer,
  orderPart: _originalOrderPart,
  resetFormData,
  editable,
}) => {
  // transform
  const orderPart = _originalOrderPart
    ? {
        ..._originalOrderPart,
        provider_offers: _originalOrderPart.provider_offers
          ? Object.fromEntries(
              _originalOrderPart.provider_offers.map((offer) => [
                offer.offer_id,
                {
                  ...offer,
                  // Set initial value for delivery_days if production_time_rules is empty
                  delivery_days:
                    offer.production_time_rules.length === 0
                      ? offer.base_production_time
                      : offer.delivery_days,
                },
              ]),
            )
          : {},
      }
    : _originalOrderPart

  const { t } = useTranslation()
  const [form] = Form.useForm()

  const providerOffers = Form.useWatch('provider_offers', {
    form,
    preserve: true,
  })

  const handleReset = () => {
    form.resetFields()
    // reset state because form.onValuesChange is not triggered by form.resetFields
    resetFormData()
  }

  const renderBoolAsText = (val) => (val ? t('yes') : t('no'))
  const renderUnitAsText = (val) =>
    ({
      [PERCENT]: '%',
      [CENT]: '€',
    })[val]

  return (
    <div style={{ backgroundColor: '#fdfdfd' }}>
      <Form
        name={[orderPartListId, orderPart.id]}
        form={form}
        initialValues={orderPart}
        // onFinish behavior currently should be handled by parent Form.Provider where necessary
      >
        <Row>
          <StyledCol span={24}>
            <h3>{t('service.order_part.batches')}</h3>
            <StyledSubTable
              dataSource={Object.values(orderPart.provider_offers)}
              columns={[
                {
                  title: t('Amount'),
                  render: (_text, record) => (
                    <Popover
                      title={t('Discount')}
                      content={
                        <List
                          dataSource={record.price_rules}
                          rowKey={(rule) => rule.min_parts}
                          renderItem={(rule) => (
                            <List.Item>
                              {rule.min_parts}{' '}
                              <Text type="secondary">
                                (-
                                {rule.discount_unit === CENT
                                  ? centToEuro(rule.discount_amount)
                                  : rule.discount_amount}
                                {renderUnitAsText(rule.discount_unit)} per part)
                              </Text>
                            </List.Item>
                          )}
                        />
                      }
                    >
                      {record.amount}
                    </Popover>
                  ),
                },
                {
                  title: t('received_requests.delivery_time'),
                  render: (_text, record) => {
                    return editable ? (
                      <Form.Item
                        name={[
                          'provider_offers',
                          record.offer_id,
                          'delivery_days',
                        ]}
                        style={{ marginBlock: '0px' }}
                        rules={[
                          {
                            required: true,
                            message: t('my_orders.choose_option'),
                          },
                        ]}
                      >
                        <Select
                          size="small"
                          popupMatchSelectWidth={false}
                          options={[
                            {
                              value: record.base_production_time,
                              label: (
                                <>
                                  {record.base_production_time} {t('days')}
                                </>
                              ),
                            },
                            ...record.production_time_rules.map((rule) => ({
                              value: rule.max_days,
                              label: (
                                <>
                                  {rule.max_days} {t('days')}{' '}
                                  <Text type="secondary">
                                    (+
                                    {rule.surcharge_unit === CENT
                                      ? centToEuro(rule.surcharge_amount)
                                      : rule.surcharge_amount}
                                    {renderUnitAsText(rule.surcharge_unit)} per
                                    part)
                                  </Text>
                                </>
                              ),
                            })),
                          ]}
                        />
                      </Form.Item>
                    ) : (
                      t(
                        'received_requests.days',
                        summarizeDeliveryTimes(
                          clientAcceptedOffer
                            ? [record.delivery_days]
                            : [
                                record.base_production_time,
                                ...record.production_time_rules.map(
                                  (rule) => rule.max_days,
                                ),
                              ],
                        ),
                      )
                    )
                  },
                },
                {
                  title: t('service.order_part.partial_short_text'),
                  dataIndex: 'allow_partial_delivery',
                  render: (value) => renderBoolAsText(value),
                },
                {
                  title: t('service.order_part.subcontractors_short_text'),
                  dataIndex: 'allow_subcontractors',
                  render: (value) => renderBoolAsText(value),
                },
                {
                  title: t('service.order_part.other_suppliers_short_text'),
                  dataIndex: 'allow_other_suppliers',
                  render: (value) => renderBoolAsText(value),
                },
                {
                  title: t('Price'),
                  render: (record) => {
                    if (editable) {
                      const editableOffer = providerOffers?.[record.offer_id]
                      const price = calculateBatchPrice({
                        ...record,
                        // initially use values from data until form values become available
                        amount: editableOffer?.amount || record.amount,
                        delivery_days:
                          editableOffer?.delivery_days || record.delivery_days,
                      })
                      return currencyRenderer(t)(price)
                    } else {
                      const price = calculateBatchPrice(
                        record,
                        clientAcceptedOffer,
                      )
                      return currencyRenderer(t)(price)
                    }
                  },
                },
              ]}
              rowKey={(record) => record.offer_id}
              size="small"
              style={{ width: '95%' }}
              pagination={false}
            />
          </StyledCol>
        </Row>
        {editable && (
          <Row justify={'end'} gutter={8} style={{ padding: 10 }}>
            <Col>
              <Button onClick={handleReset}>{t('button.reset')}</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                {t('button.save')}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  )
}

export default OrderPartDetailView
