import { fetchLegalTexts } from '$api/evoAPIs'
import { Row, Col, Divider } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { StyledTable } from '../styledComponents'
import CheckBoxForm from '../../Defaultwizard/StepThreeSummary/LegalTextConfirm'
import createColumnsSummary from './createColumnsSummary'
import OrderSummaryHeader from '../../Defaultwizard/StepThreeSummary/OrderSummaryHeader'
import useMaterialMapping from '$utils/useMaterialMapping'

function StepThreeSummaryOrderPart({
  accumulatedFormData,
  orderPartsListData,
  setButtonActive,
}) {
  const [legalTextConfirmed, setLegalTextConfirmed] = useState(false)
  const { t, i18n } = useTranslation()

  const { data: legalText } = useQuery({
    queryKey: ['legalTexts', i18n.language],
    queryFn: fetchLegalTexts,
  })

  const { data: materialsById, isLoading: materialsAreLoading } =
    useMaterialMapping()

  return (
    <>
      <h1 style={{ margin: '20px 0px 0px' }}>
        {t('service.order_part.order_summary')}
      </h1>
      <OrderSummaryHeader />
      <Divider />
      <h4 style={{ paddingTop: '15px' }}>
        {t('service.order_part.order_for')}
      </h4>
      <Row className="row">
        <Col span={18}>
          <div
            style={{
              borderRight: '1px solid #dfe1e4',
              marginRight: '25px',
              padding: '22px 25px 0 0',
            }}
          >
            <StyledTable
              partIdText={t('Part No')}
              width={160}
              size="middle"
              columns={createColumnsSummary(t, materialsById)}
              dataSource={accumulatedFormData}
              rowKey={(row) => row.part.id}
              loading={materialsAreLoading}
            />
          </div>
        </Col>
        <Col span={6}>
          <h2 style={{ opacity: '0.6' }}>
            {t('service.order_part.order_address.delivery_address')}
          </h2>
          <p>
            {orderPartsListData.delivery_address.name}
            <br />
            {orderPartsListData.delivery_address.address_line_1},{' '}
            {orderPartsListData.delivery_address.address_line_2}
          </p>
          <p>
            {orderPartsListData.delivery_address.postcode}{' '}
            {orderPartsListData.delivery_address.city},{' '}
            {orderPartsListData.delivery_address.state}
            <br />
            {orderPartsListData.delivery_address.country}
          </p>
          <Divider />
          <h2 style={{ opacity: '0.6' }}>
            {t('service.order_part.order_address.billing_address')}
          </h2>
          <p>
            {orderPartsListData.billing_address.name}
            <br />
            {orderPartsListData.billing_address.address_line_1},{' '}
            {orderPartsListData.billing_address.address_line_2}
          </p>
          <p>
            {orderPartsListData.billing_address.postcode}{' '}
            {orderPartsListData.billing_address.city},{' '}
            {orderPartsListData.billing_address.state}
            <br />
            {orderPartsListData.billing_address.country}
          </p>
          {orderPartsListData.client_reference && (
            <>
              <Divider />
              <h2 style={{ opacity: '0.6' }}>
                {t('service.order_part.client_reference')}
              </h2>
              <p>{orderPartsListData.client_reference}</p>
            </>
          )}
        </Col>
      </Row>

      <Divider />
      <CheckBoxForm
        setLegalTextConfirmed={setLegalTextConfirmed}
        legalTextConfirmed={legalTextConfirmed}
        setButtonActive={setButtonActive}
        legalText={legalText}
      />
    </>
  )
}

export default StepThreeSummaryOrderPart
