import { Button } from 'antd'

const PrivacyDE = () => {
  const openPrivacySettings = () => {
    if (window.UC_UI && window.UC_UI.showSecondLayer) {
      window.UC_UI.showSecondLayer()
    } else {
      console.error('UserCentrics UI is not available.')
    }
  }
  return (
    <div style={{ margin: '0 200px' }}>
      <h1>Datenschutzhinweise</h1>
      <p>
        <strong>
          Verantwortlicher im Sinne der EU-Datenschutz-Grundverordnung (DSGVO)
          ist:
        </strong>
      </p>
      <p>
        Daimler Buses GmbH („Wir“)
        <br />
        HPC R503
        <br />
        Fasanenweg 10
        <br />
        70771 Leinfelden-Echterdingen
        <br />
        Deutschland
        <br />
        E-Mail:{' '}
        <a href="mailto:dataprivacy-bus@daimlertruck.com">
          dataprivacy-bus@daimlertruck.com
        </a>
      </p>

      <h2>Datenschutzbeauftragter:</h2>
      <p>
        Daimler Truck AG
        <br />
        Konzerndatenschutzbeauftragte
        <br />
        HPC DTF2B
        <br />
        70745 Leinfelden-Echterdingen
        <br />
        Deutschland
        <br />
        E-Mail:{' '}
        <a href="mailto:dataprivacy@daimlertruck.com">
          dataprivacy@daimlertruck.com
        </a>
      </p>

      <h2>1. Datenschutz</h2>
      <p>
        Wir freuen uns, dass Sie unsere Webseiten besuchen und bedanken uns für
        Ihr Interesse an unserem Unternehmen. Die fortschreitende Technisierung
        unseres Alltags eröffnet uns heutzutage ungeahnte Möglichkeiten. Damit
        verbunden ist eine hohe Verantwortung der Unternehmen, denen wir unsere
        persönlichen Daten zur Verfügung stellen. Die Daimler Truck AG ist sich
        dieser Verantwortung vollumfänglich bewusst und der Schutz Ihrer
        Privatsphäre bei der Nutzung unserer Webseiten ist uns dabei ein
        besonderes Anliegen. In unseren Datenschutzhinweisen informieren wir Sie
        darüber, wie Ihre personenbezogenen Daten erhoben und verarbeitet
        werden, zu welchen Zwecken dies geschieht, auf welcher Rechtsgrundlage
        die Datenverarbeitung erfolgt und wie lange wir die Daten speichern.
        Darüber hinaus informieren wir Sie über Ihre Rechte und Ansprüche und
        wie Sie diese geltend machen können.
      </p>
      <h2>2. Nutzungszwecke</h2>

      <p>
        <strong>a.</strong> Bereitstellung Webseite
      </p>
      <p>
        Wir erheben und verarbeiten personenbezogene Daten unserer
        Webseitenbesucher grundsätzlich nur, soweit dies zur Bereitstellung
        einer funktionsfähigen Webseite sowie unserer Inhalte erforderlich ist.
        Zudem dienen uns die Daten zur Optimierung der Webseite und zur
        Sicherstellung unserer informationstechnischen Systeme.
      </p>

      <p>
        <strong>b.</strong> Kontaktaufnahme
      </p>
      <p>
        Soweit Sie uns weitere personenbezogene Daten übermitteln, z.B. im
        Rahmen eines Chats, eines Kontaktformulars oder der
        Telefonkommunikation, nutzen wir diese Daten zu Zwecken der
        Kundenkommunikation und -verwaltung im jeweils dafür erforderlichen
        Umfang.
      </p>

      <p>
        <strong>c.</strong> Vertragsanbahnung oder Vertragserfüllung
      </p>
      <p>
        Soweit Sie uns weitere personenbezogene Daten übermitteln, (z. B. zur
        Durchführung eines Vertrages und/oder Vertragsanbahnung, etc.) nutzen
        wir Ihre Daten zu Zwecken der Abwicklung und Abrechnung etwaiger
        Geschäftsvorgänge.
      </p>

      <p>
        <strong>d.</strong> Weitere Nutzungsmöglichkeiten
      </p>
      <p>
        Soweit Sie uns Ihre Einwilligung im Wege einer Kunden- und
        Zufriedenheitsumfrage, eines Preisausschreibens oder einer Registrierung
        auf unserer Webseite erteilen, nutzen wir hierfür Ihre personenbezogenen
        Daten. Zur Anzeige personalisierter Inhalte oder Werbung auf der Basis
        Ihres Nutzungsverhaltens) nutzen wir und möglicherweise ausgewählte
        Dritte Ihre Daten, soweit Sie dazu im Rahmen unseres Consent Management
        Systems Ihre Einwilligung (= Zustimmung) erteilen. Weitere Informationen
        und Entscheidungsmöglichkeiten erhalten Sie hier:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Cookie-Einstellungen öffnen
        </Button>
      </p>

      <h2>3. Erhebung und Verarbeitung Ihrer personenbezogenen Daten</h2>

      <p>
        <strong>a.</strong> Es besteht keine Verpflichtung während des Besuchs
        unserer Webseite, Ihre personenbezogenen Daten zur Verfügung zu stellen.
      </p>
      <p>
        Allerdings besteht die Möglichkeit, dass bestimmte Inhalte unserer
        Webseite von der Überlassung personenbezogener Daten abhängen. Falls Sie
        in diesen Fällen personenbezogene Daten nicht zur Verfügung stellen
        wollen, kann dies dazu führen, dass Inhalte nicht oder nur eingeschränkt
        dargestellt werden.
      </p>

      <p>
        <strong>b.</strong> Bereitstellung Webseite
      </p>
      <p>
        Die vorübergehende Speicherung der IP-Adresse durch das System ist
        notwendig, um eine Auslieferung der Webseite an den Rechner des
        Besuchers zu ermöglichen. Hierfür muss die anonymisierte IP-Adresse des
        Besuchers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung
        in Logfiles erfolgt, um die Funktionsfähigkeit der Webseite
        sicherzustellen. Bei jedem Aufruf unserer Webseite erhebt und speichert
        unser System automatisch in Logfiles Informationen, die Ihr Browser an
        uns übermittelt. Nachfolgende Daten werden hierbei erhoben:
      </p>
      <ul>
        <li>verwendeten Browser und Betriebssystem und deren Einstellungen,</li>
        <li>das Datum und die Uhrzeit des Besuches,</li>
        <li>
          den Zugriffsstatus (z.B. ob Sie eine Webseite aufrufen konnten oder
          eine Fehlermeldung erhielten),
        </li>
        <li>die Nutzung von Funktionen der Webseite,</li>
        <li>die von Ihnen möglicherweise eingegebenen Suchbegriffe,</li>
        <li>die Häufigkeit, mit der Sie einzelne Webseiten aufrufen,</li>
        <li>die Bezeichnung abgerufener Dateien,</li>
        <li>die übertragene Datenmenge,</li>
        <li>
          die Webseite, von der aus Sie auf unsere Webseiten gelangt sind, und
          die Webseite, die Sie von unseren Webseiten aus besuchen, sei es,
          indem Sie Links auf unseren Webseiten anklicken oder eine Domain
          direkt in das Eingabefeld derselben Registerkarte (bzw. desselben
          Fensters) Ihres Browsers eingeben, worin Sie unsere Webseiten geöffnet
          haben,
        </li>
        <li>
          aus Sicherheitsgründen, insbesondere zur Vorbeugung vor und Erkennung
          von Angriffen auf unsere Webseiten oder von Betrugsversuchen,
          speichern wir für die Dauer von sieben Tagen Ihre IP-Adresse und den
          Namen Ihres Internet Service Providers.
        </li>
      </ul>

      <p>
        <strong>c.</strong> Kontaktaufnahme
      </p>
      <p>
        Im Rahmen der Kontaktaufnahme mit uns werden personenbezogene Daten
        erhoben. Welche Daten im Falle eines Kontaktformulars oder Chats erhoben
        werden, entnehmen Sie bitte dem jeweiligen Formular. Diese Daten werden
        ausschließlich zum Zweck der Beantwortung Ihres Anliegens bzw. für die
        Kommunikation und der damit verbundenen technischen Administration
        gespeichert und verwendet.
      </p>
      <p>
        Darüber hinaus verarbeiten wir im Rahmen unserer Geschäftsbeziehung mit
        Ihnen auch weitere zusätzliche Daten. Hierzu gehören insbesondere:
      </p>
      <ul>
        <li>Daten aus postalischer und telefonischer Kommunikation.</li>
      </ul>

      <p>
        <strong>d.</strong> Vertragsanbahnung oder Vertragserfüllung
      </p>
      <p>
        Wir erheben, verarbeiten und nutzen zunächst die Daten, die Sie uns im
        Rahmen unserer Geschäftsbeziehung zur Verfügung stellen (insbesondere
        zur Durchführung von Bestellungen innerhalb unseres Online-Shops etc.).
        Dies umfasst insbesondere die folgenden Daten:
      </p>
      <ul>
        <li>
          Stammdaten des Vertragspartners und/oder dessen Ansprechpartner,
          insbesondere Name;
        </li>
        <li>
          Kontaktdaten des Vertragspartners oder dessen Ansprechpartner,
          insbesondere aktuelle Anschrift, Telefonnummern und E-Mail-Adressen;
        </li>
        <li>
          Vertragsdaten wie Liefer-, Bestell- und Bestätigungsdatum, Auftrags-
          und Kundenkontonummer;
        </li>
        <li>
          bei Exportgeschäften die Daten aus dem Handelsregisterauszug und die
          Umsatzsteueridentifikationsnummer;
        </li>
        <li>ggf. Bankverbindungsdaten, wie IBAN und BIC.</li>
      </ul>

      <p>
        <strong>e.</strong> Weitere Nutzungsmöglichkeiten
      </p>
      <p>
        Weitere personenbezogene Daten können insbesondere in folgenden Fällen
        verarbeitet werden:
      </p>
      <ul>
        <li>einer Kunden- und Zufriedenheitsumfrage,</li>
        <li>eines Preisausschreibens,</li>
        <li>Registrierung auf unserer Webseite.</li>
      </ul>
      <p>
        Welche Daten im Falle eines Kontaktformulars oder Chats erhoben werden,
        ist aus dem jeweiligen Formular ersichtlich.
      </p>
      <p>
        Eine Verarbeitung dieser Daten erfolgt nur im Falle einer Einwilligung
        oder nach Maßgabe geltender Rechtsvorschriften (siehe Ziffer 6).
      </p>

      <h2>
        4. Übertragung personenbezogener Daten an Dritte; Social Plug-ins;
        Einsatz von Dienstleistern
      </h2>

      <p>
        Wenn wir auf unseren Webseiten sogenannte „Social Plug-ins“ sozialer
        Netzwerke wie Facebook und Twitter einsetzen, binden wir diese wie folgt
        ein:
      </p>
      <p>
        Wenn Sie unsere Webseiten besuchen, sind die Social Plug-ins
        standardmäßig deaktiviert, d.h. es findet keine Übertragung
        irgendwelcher Daten an die Betreiber dieser (Sozialen-) Netzwerke statt.
        Falls Sie eines der Netzwerke nutzen möchten, klicken Sie auf das
        jeweilige Social Plug-in, um diese zu aktivieren und damit Ihre
        Zustimmung zur Kommunikation mit diesen Netzwerken zu bestätigen, werden
        die Buttons aktiv und stellen die Verbindung her. Falls Sie bei dem
        Netzwerk ein Nutzerkonto haben und im Moment des Aktivierens des Social
        Plug-ins dort eingeloggt sind, kann das Netzwerk Ihren Besuch unserer
        Webseiten Ihrem Nutzerkonto zuordnen. Wenn Sie das vermeiden möchten,
        loggen Sie sich bitte vor der Aktivierung des Social Plug-ins aus dem
        Netzwerk aus. Den Besuch anderer Daimler Truck-Webseiten kann ein
        soziales Netzwerk nicht zuordnen, bevor Sie nicht auch ein dort
        vorhandenes Social Plug-in aktiviert haben. Wenn Sie ein Social Plug-in
        aktivieren, überträgt das Netzwerk die dadurch verfügbar werdenden
        Inhalte direkt an Ihren Browser, der sie in unsere Webseiten einbindet.
        In dieser Situation können auch Datenübertragungen stattfinden, die vom
        jeweiligen sozialen Netzwerk initiiert und gesteuert werden. Für Ihre
        Verbindung zu einem sozialen Netzwerk, die zwischen dem Netzwerk und
        Ihrem System stattfindenden Datenübertragungen und für Ihre
        Interaktionen auf dieser Plattform gelten ausschließlich die
        Datenschutzbestimmungen des jeweiligen Netzwerks.
      </p>
      <p>
        Das Social Plug-in bleibt aktiv, bis Sie es deaktivieren oder Ihre
        Webseiten-Technologien löschen (siehe Ziffer 5.d).
      </p>
      <p>
        Wenn Sie den Link zu einem Angebot anklicken oder ein Social Plug-in
        aktivieren, kann es sein, dass personenbezogene Daten zu Anbietern in
        Ländern außerhalb des Europäischen Wirtschaftsraums gelangen, die aus
        der Sicht der Europäischen Union („EU“) kein den EU-Standards
        entsprechendes „angemessenes Schutzniveau“ für die Verarbeitung
        personenbezogener Daten gewährleisten. Bitte denken Sie an diesen
        Umstand, bevor Sie einen Link anklicken oder ein Social Plug-in
        aktivieren und damit eine Übertragung Ihrer Daten auslösen.
      </p>
      <p>
        Für Betrieb, Optimierung und Absicherung unserer Webseiten setzen wir
        außerdem qualifizierte Dienstleister (u. a. IT-Dienstleister,
        Marketing-Agenturen) ein. Personenbezogene Daten geben wir an diese nur
        weiter, soweit dies erforderlich ist für die Bereitstellung und Nutzung
        der Webseiten und deren Funktionalitäten, zur Verfolgung berechtigter
        Interessen, zur Erfüllung rechtlicher Verpflichtungen oder soweit Sie
        darin eingewilligt haben. Nähere Angaben zu den Empfängern finden Sie in
        unserem Consent Management System:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Cookie-Einstellungen öffnen
        </Button>
      </p>
      <h2>5. Webseiten-Technologien</h2>

      <p>
        <strong>a.</strong> Beim Besuch unserer Webseiten können
        Webseiten-Technologien zum Einsatz kommen. Bei Webseiten-Technologien
        handelt es sich um Textdateien, die im Browser des Besuchers gespeichert
        werden. Ruft ein Besucher eine Webseite auf, so kann eine
        Webseiten-Technologie auf dem Betriebssystem des Besuchers gespeichert
        werden. Diese Webseiten-Technologie enthält eine charakteristische
        Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim
        erneuten Aufrufen der Webseite ermöglicht. Technisch gesehen handelt es
        sich um sogenannte HTML-Cookies und ähnliche Softwaretools wie Web/DOM
        Storage oder Local Shared Objects (sogenannte „Flash-Cookies“), die wir
        zusammen als Webseiten-Technologien bezeichnen.
      </p>

      <p>
        <strong>b.</strong> Webseiten-Technologien sind kleine Dateien, die
        während des Besuchs einer Webseite auf Ihrem Desktop-, Notebook- oder
        Mobilgerät abgelegt werden. Daraus kann man z. B. erkennen, ob es
        zwischen dem Gerät und den Webseiten schon eine Verbindung gegeben hat,
        Ihre bevorzugte Sprache oder andere Einstellungen berücksichtigen, Ihnen
        bestimmte Funktionalitäten (z. B. Online-Shop, Fahrzeugkonfigurator)
        anbieten oder nutzungsbasiert Ihre Interessen erkennen.
        Webseiten-Technologien können auch personenbezogene Daten enthalten.
      </p>

      <p>
        <strong>c.</strong> Ob und welche Webseiten-Technologien bei Ihrem
        Besuch unserer Webseiten zum Einsatz kommen, hängt davon ab, welche
        Bereiche und Funktionen unserer Webseiten Sie nutzen und ob Sie dem
        Einsatz von Webseiten-Technologien, die nicht technisch erforderlich
        sind, in unserem Consent Management System zustimmen. Technisch
        notwendige Webseiten-Technologien sind kleine Textdateien, die von einer
        Webseite beim Internetsurfen im Browser gespeichert werden. Sie sorgen
        dafür, dass eine Webseite funktioniert und dass ein Nutzer sie beim
        nächsten Besuch genauso angezeigt bekommt wie beim ersten Mal. Diese
        Webseiten-Technologien sind für das Funktionieren der Webseite
        erforderlich und bedürfen keiner Einwilligung des Nutzers. Weitere
        Informationen und Entscheidungsmöglichkeiten erhalten Sie hier:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Cookie-Einstellungen öffnen
        </Button>
      </p>

      <p>
        <strong>d.</strong> Bitte beachten Sie, dass Sie Ihren Browser so
        einstellen können, dass Sie über das Setzen von Webseiten-Technologien
        informiert werden und einzeln über deren Annahme entscheiden oder die
        Annahme von Webseiten-Technologien für bestimmte Fälle oder generell
        ausschließen können. Jeder Browser unterscheidet sich in der Art, wie er
        die Webseiten-Technologien-Einstellungen verwaltet. Diese ist in dem
        Hilfemenü jedes Browsers beschrieben, welches Ihnen erläutert, wie Sie
        Ihre Webseiten-Technologien-Einstellungen ändern können. Diese finden
        Sie für die jeweiligen Browser unter den folgenden Links:
      </p>
      <ul>
        <li>
          <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
            Internet Explorer
          </a>
        </li>
        <li>
          <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
            Firefox
          </a>
        </li>
        <li>
          <a href="https://support.google.com/chrome/answer/95647?hl=de&hlrm=en">
            Chrome
          </a>
        </li>
        <li>
          <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">
            Safari
          </a>
        </li>
      </ul>

      <p>
        <strong>e.</strong> Wenn Sie sich gegen den Einsatz von
        Webseiten-Technologien entscheiden oder diese löschen, kann es sein,
        dass Ihnen nicht alle Funktionen unserer Webseiten oder einzelne
        Funktionen nur eingeschränkt zur Verfügung stehen.
      </p>

      <h2>6. Rechtsgrundlagen der Verarbeitung</h2>

      <p>
        <strong>a.</strong> Bereitstellung der Webseite
      </p>
      <p>
        Rechtsgrundlage für die - vorübergehende - Speicherung der Daten in den
        Logfiles ist Art. 6 Abs. 1 Satz 1 Buchst. b DSGVO. Insoweit erfüllen wir
        den mit dem Nutzer – auch bei kostenlosen Angeboten – bestehenden
        Vertrag über die Nutzung der Webseite.
      </p>
      <p>
        Ebenso verarbeiten wir die Daten zur Wahrung unserer berechtigten
        Interessen gemäß Art. 6 Abs. 1 Satz 1 Buchst. f DSGVO, um Ihnen die
        Webseite technisch zur Verfügung stellen zu können. Unser berechtigtes
        Interesse besteht dabei darin, Ihnen eine ansprechende, technisch
        funktionierende, benutzerfreundliche und sichere Webseite zur Verfügung
        stellen zu können.
      </p>

      <p>
        <strong>b.</strong> Kontaktaufnahme
      </p>
      <p>
        Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes
        Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1
        Buchst. f DSGVO. Zielt Ihre Kontaktierung auf den Abschluss eines
        Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung
        Art. 6 Abs. 1 Buchst. b DSGVO.
      </p>

      <p>
        <strong>c.</strong> Vertragsanbahnung oder Vertragserfüllung
      </p>
      <p>
        Für eine Verarbeitung personenbezogener Daten für die Zwecke der
        Anbahnung oder der Erfüllung eines Vertrages mit Ihnen ist Art. 6 Abs. 1
        Buchst. b DSGVO die Rechtsgrundlage.
      </p>
      <p>
        Soweit die Verarbeitung Ihrer personenbezogenen Daten zur Erfüllung
        unserer rechtlichen Verpflichtungen (z.B. zur Aufbewahrung von Daten)
        erforderlich ist, sind wir dazu gemäß Art. 6 Abs. 1 Buchst. c DSGVO
        befugt.
      </p>

      <p>
        <strong>d.</strong> Newsletter Anmeldung (vgl. Ziff. 9)
      </p>
      <p>
        Für eine Verarbeitung personenbezogener Daten für die Anmeldung zu
        unserem Newsletter erteilen Sie mit der Aktivierung des
        Bestätigungslinks, Ihre Einwilligung für die Nutzung Ihrer
        personenbezogenen Daten gemäß Art. 6 Abs. 1 Buchst. a DSGVO.
      </p>

      <p>
        <strong>e.</strong> Weitere Nutzungsmöglichkeiten
      </p>
      <p>
        Außerdem verarbeiten wir personenbezogene Daten zu Zwecken der
        Wahrnehmung unserer berechtigten Interessen sowie berechtigter
        Interessen Dritter gemäß Art. 6 Abs. 1 Buchst. f DSGVO. Insbesondere für
        folgende Verarbeitungen:
      </p>
      <ul>
        <li>die Erhaltung der Funktionsfähigkeit unserer IT-Systeme,</li>
        <li>
          die (Direkt-)Vermarktung eigener und fremder Produkte und
          Dienstleistungen (soweit diese nicht mit Ihrer Einwilligung erfolgt),
        </li>
        <li>
          die rechtlich gebotene Dokumentation von Geschäftskontakten sind
          solche berechtigten Interessen.
        </li>
      </ul>
      <p>
        Darüber hinaus verarbeiten wir Ihre personenbezogenen Daten, um Ihnen
        unsere Webseite technisch zur Verfügung stellen zu können. Unser
        berechtigtes Interesse besteht dabei darin, Ihnen eine ansprechende,
        technisch funktionsfähige, nutzerfreundliche und sichere
        Webseite/Produkt zur Verfügung stellen zu können. Wir berücksichtigen im
        Rahmen der jeweils erforderlichen Interessenabwägung insbesondere die
        Art der personenbezogenen Daten, den Verarbeitungszweck, die
        Verarbeitungsumstände und Ihr Interesse an der Vertraulichkeit Ihrer
        personenbezogenen Daten.
      </p>

      <h2>7. Löschung Ihrer personenbezogenen Daten</h2>
      <p>
        Ihre personenbezogenen Daten werden gelöscht, sobald sie für die
        Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.
      </p>

      <p>
        <strong>a.</strong> Bereitstellung der Webseite
      </p>
      <p>
        Im Falle der Erfassung Ihrer Daten zur Bereitstellung unserer Webseite
        ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Im Falle der
        Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen
        der Fall.
      </p>

      <p>
        <strong>b.</strong> Kontaktaufnahme
      </p>
      <p>
        Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage
        gelöscht. Dies ist der Fall, wenn sich aus den Umständen entnehmen
        lässt, dass der betroffene Sachverhalt abschließend geklärt ist und
        sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
      </p>

      <p>
        <strong>c.</strong> Vertragsanbahnung oder Vertragserfüllung
      </p>
      <p>
        Wir verarbeiten und speichern Ihre personenbezogenen Daten nur solange
        wir diese zu Erfüllung vertraglicher oder gesetzlicher Pflichten
        benötigen, in der Regel zwischen 6 – 10 Jahren (gemäß HGB und AO).
      </p>

      <p>
        <strong>d.</strong> Einwilligung
      </p>
      <p>
        Eine erteilte Einwilligung für die Verarbeitung personenbezogener Daten
        können Sie jederzeit kostenfrei und mit Wirkung für die Zukunft
        widerrufen. Bitte beachten Sie, dass der Widerruf erst für die Zukunft
        wirkt. Verarbeitungen, die vor dem Widerruf erfolgt sind, sind davon
        nicht betroffen. Ihr Widerruf kann zur Folge haben, dass wir ohne die
        Verarbeitung dieser Daten unsere Leistungen ganz oder teilweise nicht
        mehr erbringen können und daher den bestehenden Vertrag beenden müssen.
      </p>

      <p>
        <strong>e.</strong> Weitere Nutzungsmöglichkeiten
      </p>
      <p>
        Über diesen Zeitpunkt hinaus findet eine Speicherung nur statt, soweit
        dies gemäß den Gesetzen, Verordnungen oder sonstigen Rechtsvorschriften,
        denen wir unterliegen, in der EU oder nach Rechtsvorschriften in
        Drittstaaten, wenn dort jeweils ein angemessenes Datenschutzniveau
        gegeben ist, erforderlich ist. Soweit eine Löschung im Einzelfall nicht
        möglich ist, werden die entsprechenden personenbezogenen Daten mit dem
        Ziel der Einschränkung der weiteren Verarbeitung gekennzeichnet.
      </p>

      <h2>8. Betroffenenrechte</h2>
      <p>
        <strong>a.</strong> Als von der Datenverarbeitung betroffene Person
        haben Sie das Recht auf Auskunft (Art. 15 DSGVO), Berichtigung (Art. 16
        DSGVO), Datenlöschung (Art. 17 DSGVO), Einschränkung der Verarbeitung
        (Art. 18 DSGVO) sowie Datenübertragbarkeit (Art. 20 DSGVO).
      </p>

      <p>
        <strong>b.</strong> Haben Sie in die Verarbeitung Ihrer
        personenbezogenen Daten durch uns eingewilligt, haben Sie das Recht,
        Ihre Einwilligung jederzeit ohne Angabe von Gründen zu widerrufen. Die
        Rechtmäßigkeit der Verarbeitung Ihrer personenbezogenen Daten bis zu
        einem Widerruf wird durch den Widerruf nicht berührt. Ebenso unberührt
        bleibt eine weitere Verarbeitung dieser Daten aufgrund einer anderen
        Rechtsgrundlage und zu den darauf basierenden Zwecken.
      </p>
      <div
        style={{
          border: '1px solid black',
          borderRadius: '6px',
          padding: '10px',
          margin: '0px -10px 10px -10px',
        }}
      >
        <p>
          <strong>c. Widerspruchsrecht</strong>
        </p>
        <p>
          Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
          personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchst. f
          DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung)
          erfolgt, Widerspruch einzulegen. Legen Sie Widerspruch ein, werden wir
          Ihre personenbezogenen Daten nur weiterverarbeiten, soweit wir dafür
          zwingende berechtigte Gründe nachweisen können, die Ihre Interessen,
          Rechte und Freiheiten überwiegen, oder soweit die Verarbeitung der
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
          Sofern wir Ihre personenbezogenen Daten verarbeiten, um Direktwerbung
          zur Wahrnehmung berechtigter Interessen auf der Grundlage einer
          Interessenabwägung zu betreiben, haben Sie zudem das Recht, hiergegen
          jederzeit ohne Nennung von Gründen Widerspruch einzulegen.
        </p>

        <p>
          <strong>d.</strong> Von Ihren Rechten können Sie an nachfolgende
          Kontaktadresse Gebrauch machen:{' '}
          <a href="mailto:dataprivacy-bus@daimlertruck.com">
            dataprivacy-bus@daimlertruck.com
          </a>
        </p>

        <p>
          <strong>e.</strong> Sind Sie der Ansicht, die Verarbeitung Ihrer
          personenbezogenen Daten verstoße gegen Datenschutzrecht, haben Sie das
          Recht zur Beschwerde bei einer Datenschutzaufsichtsbehörde (Art. 77
          DSGVO).
        </p>
        <p>
          Die zuständige Aufsichtsbehörde ist der Landesbeauftragte für den
          Datenschutz und die Informationsfreiheit Baden-Württemberg.
        </p>
      </div>

      <h2>9. Newsletter</h2>
      <p>
        Wenn Sie sich zu unserem E-Mail-Newsletter anmelden, übersenden wir
        Ihnen regelmäßig per E-Mail-Informationen zu unseren Angeboten.
        Pflichtangabe für die Übersendung des Newsletters ist allein Ihre
        E-Mail-Adresse. Die Angabe weiterer Daten ist freiwillig und wird
        verwendet, um Sie persönlich ansprechen zu können. Für den Versand des
        Newsletters verwenden wir das sog. Double Opt-in Verfahren. Dies
        bedeutet, dass wir Ihnen erst dann einen E- Mail- Newsletter übermitteln
        werden, wenn Sie uns ausdrücklich bestätigt haben, dass Sie in den
        Empfang von Newsletter einwilligen. Anschließend wird Ihnen eine
        Bestätigungs-E-Mail, mit der Sie gebeten werden durch Anklicken eines
        entsprechenden Links zu bestätigen, dass Sie zukünftig unseren
        Newsletter per E-Mail erhalten wollen.
      </p>
      <p>
        Die von uns bei der Anmeldung zum Newsletter erhobenen Daten werden
        ausschließlich für Zwecke der werblichen Ansprache im Wege des E- Mail-
        Newsletters genutzt.
      </p>
      <p>
        Sie können den Newsletter jederzeit und ohne Angabe von Gründen über den
        dafür vorgesehenen Abmelde-Link innerhalb des Newsletters, abbestellen.
      </p>
      <p>
        Nach erfolgter Abmeldung wird Ihre E-Mail-Adresse unverzüglich in
        unserem Newsletter-Verteiler gelöscht, soweit Sie nicht ausdrücklich in
        eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine
        darüberhinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt
        ist und über die wir Sie in diesen Hinweisen informieren.
      </p>

      <h2>10. Drittstaatentransfer</h2>
      <p>
        Wir übermitteln für die Wartung und Support von IT-Systemen auch Daten
        an Dienstleister außerhalb des Europäischen Wirtschaftsraums (EWR) bzw.
        gewähren diesen Zugriff. Da Staaten außerhalb des EWR in der Regel kein
        EU- Datenschutzniveau haben, stellen wir vertraglich sicher, dass durch
        die Dienstleister Maßnahmen getroffen werden, um einen dem
        Datenschutzniveau in der EU/im EWR entsprechenden Schutz der Daten zu
        gewährleisten. Informationen hierzu stellen wir Ihnen gerne über die
        genannten Kontaktdaten zur Verfügung. Die Unternehmen des Daimler
        Truck-Konzerns sind darüber hinaus an die strikten Datenschutzvorgaben
        des Daimler Truck-Konzerns gebunden.
      </p>
      <p>
        In den folgenden Ländern ({' '}
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_de"
          target="_blank"
          rel="noreferrer"
        >
          Link zur Liste
        </a>
        ) besteht aus der Sicht der EU ein den EU-Standards entsprechendes
        angemessenes Schutzniveau für die Verarbeitung personenbezogener Daten
        (sog. Angemessenheitsbeschluss). Mit Empfängern in anderen Ländern
        vereinbaren wir die Anwendung von EU-Standardvertragsklauseln oder
        verbindlichen Unternehmensregelungen, um entsprechend den gesetzlichen
        Anforderungen ein „angemessenes Schutzniveau“ zu schaffen.
      </p>
    </div>
  )
}

export default PrivacyDE
