import React, { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Progress, Popover } from 'antd'
import { useTranslation } from 'react-i18next'
import { UilQuestion } from '@iconscout/react-unicons'
import { ExportOutlined } from '@ant-design/icons'
import { StyledContingentCard } from '$globalStyledComponents/Card'
import { StyledTable } from '$globalStyledComponents/Table'
import { SERVICES } from '$constants'
import { fetchAllBookedServices } from '$api/evoAPIs'
import { convertToPercentage, progressColor } from '$utils'
import PartNo from '$components/PartsTable/PartNo'
import TruncateText from '$components/TruncateText'

const getLatestN = (orders, n) =>
  orders
    .sort(
      (a, b) =>
        new Date(b.time_stamp).valueOf() - new Date(a.time_stamp).valueOf(),
    )
    .slice(0, n)

const ServicesCompleted = (props) => {
  const { t } = useTranslation()

  const getLastOrders = useCallback(
    (orders) => {
      if (orders.items) {
        let filteredOrders = orders.items
        if (props.tab !== 'All Services') {
          filteredOrders = orders.items.filter(
            (order) => order.service_name === props.tab,
          )
        }

        let completedOrders = filteredOrders.filter(
          (order) => order.status_info === 'finished',
        )
        completedOrders = getLatestN(completedOrders, 3)

        let inProgressOrders = filteredOrders.filter((order) =>
          ['in_progress', 'in_delivery'].includes(order.status_info),
        )
        inProgressOrders = getLatestN(inProgressOrders, 3)

        return {
          completed: completedOrders,
          inProgress: inProgressOrders,
        }
      }
    },
    [props.tab],
  )

  const { data: orders } = useQuery({
    queryKey: ['bookedServices'],
    queryFn: fetchAllBookedServices,
    placeholderData: [],
    select: getLastOrders,
  })

  const columns = [
    {
      title: 'Service',
      width: '8%',
      render: (order) => {
        const service = SERVICES.find((s) => s.id === order.service_id) ||
          SERVICES.flatMap((s) => s.subservices || []).find(
            (sub) => sub.id === order.service_id,
          ) || {
            servicename: 'Unknown Service',
            icon: <UilQuestion size="21" color="#F1B44C" />,
          }

        return (
          <Popover content={t(service?.servicename)}>
            {/* Must wrap icon in div or 'findDOMNode is deprecated in StrictMode' warning will be triggered */}
            <div>{service?.icon}</div>
          </Popover>
        )
      },
    },
    {
      title: t('Part Name'),
      dataIndex: 'part',
      render: (part) => {
        return (
          <Popover
            content={
              <PartNo
                part={part}
                expandedRowKeys={undefined}
                onExpand={undefined}
              />
            }
          >
            <TruncateText text={part.name} />
          </Popover>
        )
      },
      ellipsis: true,
    },
    {
      title: 'Progress',
      dataIndex: 'status_info',
      render: (orderStatus) => (
        <Progress
          percent={convertToPercentage(orderStatus)}
          size="small"
          strokeColor={progressColor(convertToPercentage(orderStatus))}
        />
      ),
    },
    {
      title: 'Date',
      dataIndex: 'time_stamp',
      render: (time_stamp) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          {' '}
          {/* TODO Link table entry to corresponding detail view of service */}
          <Popover content={t('my_services.latest_services.open_details')}>
            <ExportOutlined />{' '}
          </Popover>
          <Popover content={t('bookedservices.time_stamp')}>
            {t('localized_datetime', {
              val: new Date(time_stamp),
              formatParams: {
                val: {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                },
              },
            })}
          </Popover>
        </div>
      ),
    },
  ]

  return (
    <StyledContingentCard>
      <h3>{t('my_services.latest_services.latest_completed')}</h3>
      <StyledTable
        columns={columns}
        dataSource={orders?.completed || []}
        size="small"
        showHeader={false}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              props.onRowClick(record.id)
            },
          }
        }}
        rowKey={(record) => record.id}
      />
      <h3 style={{ marginTop: '20px' }}>
        {t('my_services.latest_services.latest_in_progress')}
      </h3>
      <StyledTable
        columns={columns}
        dataSource={orders?.inProgress || []}
        size="small"
        showHeader={false}
        pagination={false}
        onRow={(record) => {
          return {
            onClick: () => {
              props.onRowClick(record.id)
            },
          }
        }}
        rowKey={(record) => record.id}
      />
    </StyledContingentCard>
  )
}

export default ServicesCompleted
