import CustomizeRulesForm from '$components/Services/ReceivedRequests/CustomizeRulesForm'
import { Col, Row } from 'antd'

const ProviderOfferForm = ({
  orderPartId,
  providerOffer,
  resetInvalidProviderOffers,
  deleteRule,
}) => {
  return (
    <>
      <div style={{ padding: '27px' }} />
      <Row style={{ minHeight: 100 }}>
        <Col span="12" style={{ padding: '0 0 0 20px' }}>
          <CustomizeRulesForm
            typeOfRules="price"
            orderPartId={orderPartId}
            providerOffer={providerOffer}
            resetInvalidProviderOffers={resetInvalidProviderOffers}
            deleteRule={deleteRule}
          />
        </Col>
        <Col span="12" style={{ padding: '0 0 0 20px' }}>
          <CustomizeRulesForm
            typeOfRules="delivery"
            orderPartId={orderPartId}
            providerOffer={providerOffer}
            resetInvalidProviderOffers={resetInvalidProviderOffers}
            deleteRule={deleteRule}
          />
        </Col>
      </Row>
    </>
  )
}
export default ProviderOfferForm
