import { Col, Row } from 'antd'
import mymatpic from '$assets/img/mymatpic.png'

const Topwelcomecard = (props) => {
  return (
    <>
      <Row className="myevocard">
        <Col span={8}>
          <Row>
            <img src={mymatpic} width="135px" alt="My mat pic" />
          </Row>
        </Col>
        <Col
          span={8}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          <Col style={{ width: '50%' }}>
            Unmapped Materials<h2>12{props.partsCount}</h2>
          </Col>
          <Col style={{ width: '50%' }}>
            Client Materials<h2>23{props.currentgrouppartscounter}</h2>
          </Col>
          <Col style={{ width: '50%' }}>
            DWH Materials<h2>34{props.groupcounter}</h2>
          </Col>
        </Col>
        <Col span={8} style={{ textAlign: 'right', margin: 'auto' }}>
          <div style={{ width: '149px', display: 'inline-block' }}></div>
        </Col>
      </Row>
    </>
  )
}

export default Topwelcomecard
