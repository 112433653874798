import ApplyBrandingDetails from './ApplyBrandingDetails'
import ApplyTexturingDetails from './ApplyTexturingDetails'
import Generate3DModelDetails from './Generate3DModelDetails'
import OrderPartDetails from './OrderPartDetails'

const OrderedServicesDetails = ({ record }) => {
  const renderOrderDetails = (record) => {
    switch (record.service_name) {
      case 'Generate 3D Model':
        return <Generate3DModelDetails record={record} />
      case 'Apply Branding':
        return <ApplyBrandingDetails record={record} />
      case 'Apply Texturing':
        return <ApplyTexturingDetails record={record} />
      case 'Order Part':
        return <OrderPartDetails record={record} />
      default:
        return null
    }
  }

  return renderOrderDetails(record)
}

export default OrderedServicesDetails
