import styled from 'styled-components'
import { Tag } from 'antd'

export const StyledTag = styled(Tag)`
  margin-inline-end: 0px;
  &.light {
    color: #000;

    .anticon-close {
      color: #333;
    }
  }
  &.dark {
    color: #fff;

    .anticon-close {
      color: #ccc;
    }
  }
`
