import { Tag } from 'antd'
import Tooltip from '$components/Tooltip'
import { useTranslation } from 'react-i18next'
export const REQUEST_RECEIVED = Symbol('request received')
export const REQUEST_REJECTED = Symbol('request rejected')
export const OFFER_MADE = Symbol('offer made')
export const OFFER_ACCEPTED = Symbol('offer accepted')
export const OFFER_REJECTED = Symbol('offer rejected')

export const useOrderPartsListStatus = (orderPartsList) => {
  const isAccepted = (status) => status === true
  const isRejected = (status) => status === false
  const isSet = (status) => isAccepted(status) || isRejected(status)

  const determineStatus = (orderPartsList) => {
    if (
      !isSet(orderPartsList.provider_accepted_offer) &&
      !isSet(orderPartsList.client_accepted_offer)
    ) {
      return REQUEST_RECEIVED
    } else if (isRejected(orderPartsList.provider_accepted_offer)) {
      return REQUEST_REJECTED
    } else if (
      isAccepted(orderPartsList.provider_accepted_offer) &&
      !isSet(orderPartsList.client_accepted_offer)
    ) {
      return OFFER_MADE
    } else if (
      isAccepted(orderPartsList.provider_accepted_offer) &&
      isAccepted(orderPartsList.client_accepted_offer)
    ) {
      return OFFER_ACCEPTED
    } else if (isRejected(orderPartsList.client_accepted_offer)) {
      return OFFER_REJECTED
    }
  }

  const status = orderPartsList && determineStatus(orderPartsList)

  return {
    determineStatus,
    status,
  }
}

export const OrderPartsListStatusTag = ({ orderPartsList }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'service.order_part',
  })
  const { status } = useOrderPartsListStatus(orderPartsList)

  switch (status) {
    case REQUEST_RECEIVED:
      return (
        <Tooltip title={t('tooltip.request_received')}>
          <Tag color="warning">{t('status.request_received')}</Tag>
        </Tooltip>
      )
    case REQUEST_REJECTED:
      return (
        <Tooltip title={t('tooltip.request_rejected')}>
          <Tag color="default">{t('status.request_rejected')}</Tag>
        </Tooltip>
      )
    case OFFER_MADE:
      return (
        <Tooltip title={t('tooltip.offer_made')}>
          <Tag color="processing">{t('status.offer_made')}</Tag>
        </Tooltip>
      )
    case OFFER_ACCEPTED:
      return (
        <Tooltip title={t('tooltip.offer_accepted')}>
          <Tag color="success">{t('status.offer_accepted')}</Tag>
        </Tooltip>
      )
    /* case OFFER_REJECTED:
      return (
        <Tooltip title={t('tooltip.offer_rejected')}>
          <Tag color="error">{t('status.offer_rejected')}</Tag>
        </Tooltip>
      ) */
    default:
      return
  }
}
