import styled from 'styled-components'
import { Select, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import Form from '$components/Form'
import CustomInputNumber from '$components/CustomInputNumber'
const { Option } = Select

const Wrapper = styled.div`
  // using classes to select children of Wrapper to override antd styles
  .ant-form-item-control-input-content {
    display: 'flex';
  }

  .property-select {
    width: ${({ $units }) => $units && Object.keys($units).length > 0 && '68%'};
    margin-right: 2%;
  }

  .unit-select {
    width: 30%;
  }
`

const PropertyInput = ({
  name,
  label,
  required = false,
  units,
  float_attribute,
  useOptions = () => ({}),
}) => {
  const { t } = useTranslation()
  const unitFormItemName = `${name}-unit`

  const { data: options, isLoading: optionsAreLoading } = useOptions()
  if (optionsAreLoading) {
    return 'Options are loading...'
  }

  return (
    <Wrapper $units={units}>
      <Form.Item label={t(label || name)}>
        <Form.Item
          name={name}
          noStyle={true}
          rules={
            required
              ? [
                  {
                    required: true,
                    message: t(
                      'parts_master_data.create.required_field_prompt',
                    ),
                  },
                ]
              : []
          }
        >
          {float_attribute ? (
            <CustomInputNumber
              className="property-select"
              placeholder={t('parts_master_data.create.select_placeholder')}
              allowClear={true}
              virtual={false}
            />
          ) : options ? (
            <Select
              className="property-select"
              options={options.map((item) => {
                return {
                  value: item.name,
                  label: item.name,
                }
              })}
              style={{ minWidth: '66px' }}
            ></Select>
          ) : (
            <Input
              className="property-select"
              placeholder={t('parts_master_data.create.select_placeholder')}
              allowClear={true}
              virtual={false}
            />
          )}
        </Form.Item>
        {units && Object.keys(units).length > 0 && (
          <Form.Item name={unitFormItemName} noStyle={true} initialValue={1}>
            <Select className="unit-select" virtual={false}>
              {Object.keys(units).map((unit) => (
                <Option key={unit} value={units[unit]}>
                  {unit}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>
    </Wrapper>
  )
}

export default PropertyInput
