import { useState, useEffect } from 'react'
import { StyledLayout } from '$globalStyledComponents/Layout'
import Footer from '$components/Footer'
import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FcUpdateParts } from '$utils/FcUpdateParts'
import { fetchPartsByIds } from '$api/client'
import { useQuery } from '@tanstack/react-query'
import PartsTable from './PartsTable'

import ServiceButtons from './ServiceButtons'
import { SERVICES } from '$constants'

const { Content } = StyledLayout

const Marketplace = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const [selectedParts, setSelectedParts] = useState({})
  const [bookedServiceStatus, setBookedServiceStatus] = useState([false, ''])
  const [activeGroupName, setActiveGroupName] = useState('')
  const [orderToRepeat, setOrderToRepeat] = useState(null)

  useEffect(() => {
    // resetting current selected service on page enter
    if (location.pathname === '/marketplace') {
      setBookedServiceStatus([false, ''])
    }

    // book service 'Order Part' if there is an order to repeat
    if (location?.state?.orderToRepeat) {
      let order = JSON.parse(location.state.orderToRepeat)
      let orderpartService = SERVICES.find(
        (service) => service.id === '63dcfb10f81b69c6d3a7c42f',
      )

      setBookedServiceStatus([true, orderpartService])
      setOrderToRepeat(order)
    }
  }, [location])

  // parts in an order to repeat might be outdated, hence the need to refetch
  useQuery({
    queryKey: [
      'partsByIds',
      orderToRepeat?.list_of_orderparts.map(
        (orderpart) => orderpart.part.db_id_client,
      ),
    ],
    queryFn: fetchPartsByIds,
    enabled: !!orderToRepeat,
    onSuccess: (data) => {
      let orderToRepeatParts = {}
      data.items.forEach((part) => (orderToRepeatParts[part.id] = part))

      setSelectedParts({
        ...selectedParts,
        ...orderToRepeatParts,
      })
    },
  })

  return (
    <StyledLayout className="layout">
      {!bookedServiceStatus[0] ? (
        <>
          <Content>
            <h1 style={{ margin: '50px 0' }}>{t('Marketplace')}</h1>
            <ServiceButtons
              selectedParts={selectedParts}
              setBookedServiceStatus={setBookedServiceStatus}
            />
            <FcUpdateParts />
            <PartsTable
              selectedParts={selectedParts}
              setSelectedParts={setSelectedParts}
              setGroup={(group) => {
                if (group) {
                  setActiveGroupName(group.list_name)
                } else {
                  setActiveGroupName('')
                }
              }}
            />
          </Content>
        </>
      ) : (
        <Content>
          <Outlet
            context={[
              bookedServiceStatus,
              Object.values(selectedParts),
              setSelectedParts,
              props.setCurrent,
              activeGroupName,
              orderToRepeat,
              setOrderToRepeat,
            ]}
            bookedServiceStatus={bookedServiceStatus[1]}
            selectedParts={Object.values(selectedParts)}
            setSelectedParts={setSelectedParts}
            setCurrent={props.setCurrent}
            activeGroupName={activeGroupName}
            orderToRepeat={orderToRepeat}
            setOrderToRepeat={setOrderToRepeat}
          />
        </Content>
      )}
      <Footer />
    </StyledLayout>
  )
}

export default Marketplace
