import React, { useEffect, useState } from 'react'
import { Radio, List, Image, Modal } from 'antd'
import { fallback } from '$utils'
import img1 from '../../../../../../../../assets/img/bump1.jpg'
import img2 from '../../../../../../../../assets/img/bump2.jpg'
import img3 from '../../../../../../../../assets/img/bump3.jpg'
import img4 from '../../../../../../../../assets/img/bump4.jpg'
import img5 from '../../../../../../../../assets/img/bump5.png'
import img6 from '../../../../../../../../assets/img/bump6.jpg'
import img7 from '../../../../../../../../assets/img/bump7.jpg'
import img8 from '../../../../../../../../assets/img/bump8.jpg'
import img9 from '../../../../../../../../assets/img/bump9.jpg'

const data = [
  {
    title: 'Bumpmap 1',
    img: <Image width={290} height={290} src={img1} fallback={fallback} />,
  },
  {
    title: 'Bumpmap 2',
    img: <Image width={290} height={290} src={img2} fallback={fallback} />,
  },
  {
    title: 'Bumpmap 3',
    img: <Image width={290} height={290} src={img3} fallback={fallback} />,
  },
  {
    title: 'Bumpmap 4',
    img: <Image width={290} height={290} src={img4} fallback={fallback} />,
  },
  {
    title: 'Bumpmap 5',
    img: <Image width={290} height={290} src={img5} fallback={fallback} />,
  },
  {
    title: 'Bumpmap 6',
    img: <Image width={290} height={290} src={img6} fallback={fallback} />,
  },
  {
    title: 'Bumpmap 7',
    img: <Image width={290} height={290} src={img7} fallback={fallback} />,
  },
  {
    title: 'Bumpmap 8',
    img: <Image width={290} height={290} src={img8} fallback={fallback} />,
  },
  {
    title: 'Bumpmap 9',
    img: <Image width={290} height={290} src={img9} fallback={fallback} />,
  },
]

const BumpmapGallery = (props) => {
  const [checked, setChecked] = useState([])
  const [_indeterminate, setIndeterminate] = useState(false)
  // const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    props.setSelectedImage(checked[1])
    props.setIsModalOpen(false)
  }

  const handleCancel = () => {
    props.setIsModalOpen(false)
  }

  useEffect(() => {
    setIndeterminate(checked.length && checked.length !== data.length)
  }, [checked])

  // const onCheckAllChange = (e) => {
  //     setChecked(e.target.checked ? data.map((item) => item.title) : []);
  // };

  return (
    <>
      <Modal
        title="Bumpmap Gallery"
        open={props.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1030}
      >
        {/* <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                >
                    Check all
                </Checkbox> */}
        <Radio.Group
          style={{ width: '100%', display: 'block' }}
          value={checked[0]}
          onChange={(checkedValues) => {
            setChecked([
              checkedValues.target.value,
              data.find((f) => checkedValues.target.value === f.title).img,
            ])
          }}
        >
          <List
            grid={{ gutter: 16, column: 3 }}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <>
                      <Radio value={item.title} /> {item.title}
                    </>
                  }
                  description={
                    <div
                      onClick={() => {
                        setChecked([item.title, item.img])
                      }}
                    >
                      {item.img}
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </Radio.Group>
        <div style={{ marginTop: 20 }}>
          <b>Selected:</b> {checked[0]}
        </div>
      </Modal>
    </>
  )
}

export default BumpmapGallery
