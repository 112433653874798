import { useContext } from 'react'
import { AuthContext } from 'react-oauth2-code-pkce'
import { useTranslation } from 'react-i18next'

import { storeTokens } from '$api/tokenManagement'
import User from './user'
import Company from './company'

export function useUser() {
  const authContext = useContext(AuthContext)

  const { token, tokenData, idTokenData, idToken } = authContext
  if (!token || !idTokenData) {
    return false
  }

  storeTokens(idToken, token, null)

  const userData = {
    ...idTokenData,
    clientUrls: idTokenData.client_urls,
    companyChain: idTokenData.CompanyChain,
    themeUrl: tokenData.theme_url,
  }

  try {
    return new User(userData, tokenData.realm_access.roles)
  } catch (e) {
    return { error: e }
  }
}

export function useCompany() {
  const authContext = useContext(AuthContext)

  const { token, idTokenData } = authContext
  if (!token || !idTokenData) {
    return false
  }

  return new Company(idTokenData)
}

export function useToken() {
  const { token, idToken, error, loginInProgress, idTokenData, tokenData } =
    useContext(AuthContext)

  const tokenNotYetDecoded = token && !tokenData
  const idTokenNotYetDecoded = idToken && !idTokenData

  return {
    token,
    idToken,
    error,
    loginInProgress:
      loginInProgress || tokenNotYetDecoded || idTokenNotYetDecoded,
  }
}

export function useLogin() {
  // eslint-disable-next-line id-denylist -- wrapper for AuthContext's logIn(), which should be avoided in all other locations
  const { logIn } = useContext(AuthContext)
  const { i18n } = useTranslation()

  return () => {
    logIn(undefined, {
      ui_locales: getUiLocales(i18n.language),
    })
  }
}

export function useLogout() {
  // eslint-disable-next-line id-denylist -- wrapper for AuthContext's logOut(), which should be avoided in all other locations
  const { logOut } = useContext(AuthContext)
  const { i18n } = useTranslation()

  return () => {
    logOut(undefined, undefined, {
      ui_locales: getUiLocales(i18n.language),
    })
  }
}

function getUiLocales(currentLocale, defaultLocale = 'en') {
  return [...new Set([currentLocale, defaultLocale])].join(' ')
}
