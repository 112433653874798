import styled from 'styled-components'
import { Card, Upload, Checkbox } from 'antd'
import { StlViewer } from 'react-stl-viewer'

export const Divider = styled.div`
  background-color: rgba(239, 242, 247, 1);
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
`

export const LabelText = styled.p`
  color: #495057;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 20px;
  width: 225px;
`

export const Title = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  color: #495057;
  margin-bottom: 25px};
`

export const EnviromentName = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
  color: #495057;
`

export const ExpandableTableCard = styled(Card)`
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: -10px;
  cursor: default;
  background: none;
  border: none;

  .ant-card-body {
    padding: 0px 15px 0px;
  }

  .detailsprice {
    width: 82px;
  }
  .ant-steps-vertical {
    margin-top: -6px;
  }
  .ant-steps-item-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #495057;
  }
  .formcolumn {
    padding: 0 5px 0 0;
    //max-width: 243px;
  }
  .ant-input-number-group > .ant-input-number:last-child,
  .ant-input-number-group-addon:last-child {
    border: none;
  }
  .dwidth {
    width: 45px;
    margin-right: 2px;
  }
  .ant-input-group {
    display: flex;
    justify-content: space-between;
    margin-left: -13px;
    width: 112%;
  }
  .white-bg {
    background-color: #fff !important;
  }

  .ant-form-item-control {
    align-items: flex-end;
  }
  .partsdivider {
    height: unset;
    margin: 0 40px;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .ant-form-item-control-input {
    width: 100%;
  }
  .formcolumnlast .ant-form-item-control-input .ant-switch {
    margin-top: -40px;
  }
  .ant-slider-horizontal {
    width: 77% !important;
    float: right;
  }
  .longlabelcheckbox .ant-form-item-label {
    width: 85%;
    text-align: left;
  }
  .ant-upload-list-item-thumbnail {
    object-fit: cover;
  }
  .ant-upload-list-item-thumbnail img {
    object-fit: cover;
  }
`

export const StyledUpload = styled(Upload)`
  .ant-upload-select {
    display: inherit;
  }
  .ant-upload-list::before {
    display: inherit;
  }
`
export const GalleryOpen = styled.div`
  height: 200px;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: white;
`
export const StyledStlViewer = styled(StlViewer)`
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
`

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.6s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  :hover .overlay {
    opacity: 1;
  }

  .overlay div {
    color: white;
    text-decoration: none;
    margin: 10px;
    font-size: 1.2em;
    padding: 10px 20px;
  }
  .overlay div:first-child {
    position: absolute;
    bottom: 0;
    :hover {
      color: red;
    }
  }

  .overlay div:last-child {
    position: static;
    margin-top: 20px;
  }
`

export const StyledCheckboxGroup = styled(Checkbox.Group)`
  flex-wrap: wrap;
  margin: 3px 0;
  .ant-checkbox-wrapper {
    margin-inline-start: 0;
    width: 125px;
    margin: 2px 0;
  }
`
