import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Slider,
  Space,
  Switch,
  notification,
} from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FontPicker from 'font-picker-react'
import { evoClient, putPart } from '$api/client'
import { ImageContainer } from './styledComponents'
import { Modal } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import ImageUpload from '$components/FileUpload/ImageUpload'

import {
  ExpandableTableCard,
  StyledCheckboxGroup,
  Title,
} from './styledComponents'
import Form from '$components/Form'

const formatter = (value) => `${value}mm`
const formatter2 = (value2) => `${value2}mm`
const marks = {
  0: '0mm',
  25: '25mm',
  50: '50mm',
  75: '75mm',
  100: '100mm',
}
const { TextArea } = Input

const BrandingConfigForm = (props) => {
  const [minmaxswitch, setMinmaxswitch] = useState(false)
  const [visiblesideswitch, setVisiblesideswitch] = useState(false)
  const [invisiblesideswitch, setInvisiblesideswitch] = useState(false)
  const [nonVisibleSideBrandingSwitch, setNonVisibleSideBrandingSwitch] =
    useState(false)
  const [authentificationSwitch, setAuthentificationSwitch] = useState(false)
  const [stlFile, setSTLFile] = useState()
  const [logoFile, setImageFile] = useState()
  const [lettering, setLettering] = useState()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [fontSize, setFontSize] = useState(32)
  const [activeFontFamily, setActiveFontFamily] = useState('Open Sans')
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const otherOptions = [
    'Part No',
    'Part Name',
    'Dimensions',
    'Weight',
    'Material',
  ]

  useEffect(() => {
    evoClient
      .get(putPart + props.record.id + '/stl/', {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(async (resp) => {
        const asyncBlob = await resp.data.arrayBuffer()
        const blob = new Blob([asyncBlob], {
          type: 'application/vnd.ms-pki.stl',
        })
        setSTLFile(blob)
      })
  }, [props.record.id])

  const onFinish = (values) => {
    if (!logoFile && !lettering) {
      openErrorNotification(
        'top',
        t('service.apply_branding.must_select_logo_lettering'),
      )
      return
    }
    // set status for each part to "finished"
    props.setStatus([true, props.record.id])
    // post to endpoints
    const schema = {
      service_id: 'string',
      company: 'string',
      service_name: 'string',
      part: {
        id: props.record.id,
        name: props.record.name,
        id_part_client: props.record.id_part_client,
        db_id_client: props.record.id,
        company: props.record.company,
        attributes: props.record.attributes,
        float_attributes: props.record.float_attributes,
        status_attributes: props.record.status_attributes,
        dwh_materials: props.record.dwh_materials,
      },

      min_size: values.minmaxvalues ? values.minmaxvalues[0] : undefined,
      max_size: values.minmaxvalues ? values.minmaxvalues[1] : undefined,
      visible_side_description: values.visiblesidedescription,
      font: values.font?.family,
      branding: values.lettering,
      branding_on_non_visible_side: nonVisibleSideBrandingSwitch,
      is_invisibly_branded: invisiblesideswitch,
      authentification: authentificationSwitch,
      attribute_names: values.other ? values.other : [],
      is_min_max: minmaxswitch,
      is_visible_side: visiblesideswitch,
      what_should_be_done_if_not_fit: values.howtodeal,
      font_size: values.size,
      stl_file: stlFile,
      img_file: logoFile,
    }
    props.setCurrentFormValues(schema)
  }

  const handlePreviewOpen = () => {
    setPreviewOpen(true)
  }

  const handlePreviewClose = () => {
    setPreviewOpen(false)
  }

  const onReset = () => {
    openNotification('top', 'Änderungen verworfen')
    form.resetFields()
  }

  const openNotification = (placement, message) => {
    notification.success({
      message: `${message}`,
      description: ``,
      //`${JSON.stringify(values, null, '\t')}`
      placement,
    })
  }

  const openErrorNotification = (placement, message) => {
    notification.error({
      message: `${message}`,
      description: ``,
      placement,
    })
  }

  const onChangeSwitch1 = (v) => {
    setMinmaxswitch(v)
  }
  const onChangeSwitch2 = (v) => {
    setVisiblesideswitch(v)
  }
  const onChangeSwitch3 = (v) => {
    setInvisiblesideswitch(v)
  }
  const onChangeSwitch4 = (v) => {
    setNonVisibleSideBrandingSwitch(v)
  }
  const onChangeSwitch5 = (v) => {
    setAuthentificationSwitch(v)
  }

  return (
    <ExpandableTableCard>
      <Form
        layout="horizontal"
        size="medium"
        onFinish={onFinish}
        onReset={onReset}
        form={form}
        initialValues={{
          size: 50,
        }}
      >
        <Col span={24}>
          <Row className="row">
            <Col flex="1 0 25%" className="formcolumn">
              <Title style={{ margin: '23px 0px' }}>
                {t('service.apply_branding.please_upload')}
              </Title>
              <Form.Item name="image">
                {/* rules={[{ required: true, message: 'Please upload an Logo-Image!' }]} // NEEDS custom validator o.ä. */}
                <ImageUpload setImageFile={setImageFile} />
              </Form.Item>
            </Col>
            <Divider
              type="vertical"
              className="partsdivider"
              style={{ marginTop: '67px' }}
            />
            <Col flex="1 0 25%" className="formcolumn">
              <Title style={{ margin: '23px 0px' }}>
                {t('service.apply_branding.title')}
              </Title>
              <Form.Item
                label={t('service.apply_branding.writing')}
                name="lettering"
              >
                <Input
                  variant="borderless"
                  className="white-bg"
                  defaultValue=""
                  onChange={(event) => setLettering(event.target.value)}
                />
              </Form.Item>
              <Form.Item
                label={t('service.apply_branding.font_face')}
                name="font"
              >
                <FontPicker
                  apiKey="AIzaSyBz1jH_FCzrFNEP2uf6r518fkZTTQGKsT0"
                  activeFontFamily={activeFontFamily}
                  onChange={(nextFont) => setActiveFontFamily(nextFont.family)}
                  categories={['sans-serif', 'display', 'monospace']}
                />
              </Form.Item>
              <Form.Item
                label={t('service.apply_branding.font_size')}
                name="size"
              >
                <Slider
                  min={12}
                  max={100}
                  defaultValue={50}
                  tooltip={{ formatter }}
                  marks={marks}
                  onChange={(size) => {
                    setFontSize(size)
                  }}
                />
              </Form.Item>
              <Form.Item
                label={t('service.apply_branding.part_information')}
                name="other"
              >
                <StyledCheckboxGroup
                  options={otherOptions.map((option) => ({
                    label: t(option),
                    value: option,
                  }))}
                  defaultValue={['Part No']}
                />
              </Form.Item>
            </Col>
            <Divider
              type="vertical"
              className="partsdivider"
              style={{ marginTop: '67px' }}
            />
            <Col flex="1 0 25%" className="formcolumn">
              <Title style={{ margin: '23px 0px' }}>{t('Preview')}</Title>
              <ImageContainer>
                <div
                  className="apply-font"
                  style={{
                    height: '200px',
                    lineHeight: '200px',
                    textAlign: 'center',
                    justifyContent: 'center',
                    borderRadius: '8px',
                    border: '2px solid #e9e9e9',
                    whiteSpace: 'nowrap',
                    fontSize,
                    cursor: 'pointer',
                    display: 'flex',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  {lettering}
                  {lettering ? (
                    <div className="overlay" onClick={handlePreviewOpen}>
                      <EyeOutlined
                        style={{
                          fontSize: '20px',
                          color: '#fff',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </ImageContainer>
            </Col>

            <Modal
              open={previewOpen}
              footer={null}
              onCancel={handlePreviewClose}
              width={1000}
              centered
              destroyOnClose={true}
            >
              <div
                className="apply-font"
                style={{
                  padding: '24px 0px',
                  textAlign: 'center',
                  whiteSpace: 'wrap',
                  fontSize,
                }}
              >
                {lettering}
              </div>
            </Modal>
          </Row>
        </Col>
        <Divider type="horizontal" style={{ marginTop: '50px' }} />
        <Col span={24} style={{ marginTop: '30px' }}>
          <Title style={{ margin: '23px 0px' }}>
            {t('service.apply_branding.position_and_type')}
          </Title>
          <Row className="row">
            <Col flex="1 0 25%" className="formcolumn">
              <Form.Item
                label={t('service.apply_branding.is_min_max_size')}
                name="minmaxsize"
                valuePropName="checked"
                initialValue={false}
              >
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Switch
                    defaultChecked={minmaxswitch}
                    onChange={onChangeSwitch1}
                  />
                </div>
              </Form.Item>
              {minmaxswitch ? (
                <>
                  <Form.Item label={t('Which one?')} name="minmaxvalues">
                    <Slider
                      range
                      defaultValue={[20, 50]}
                      tooltip={{ formatter2 }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('service.apply_branding.if_not_fits_long')}
                    style={{ marginBottom: '5px' }}
                  ></Form.Item>
                  <Form.Item name="howtodeal">
                    <TextArea
                      rows={4}
                      variant="borderless"
                      className="white-bg"
                      defaultValue=""
                    />
                  </Form.Item>
                </>
              ) : null}
            </Col>
            <Divider type="vertical" className="partsdivider" />
            <Col flex="1 0 25%" className="formcolumn">
              <Form.Item
                label={t('Is there a visible side')}
                name="visibleside"
                valuePropName="checked"
              >
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Switch
                    defaultChecked={visiblesideswitch}
                    onChange={onChangeSwitch2}
                  />
                </div>
              </Form.Item>
              {visiblesideswitch ? (
                <>
                  <Form.Item label="Wo?" name="visiblesidedescription">
                    <TextArea
                      rows={4}
                      variant="borderless"
                      className="white-bg"
                      defaultValue=""
                    />
                  </Form.Item>
                  <Form.Item
                    className="longlabelcheckbox"
                    label={
                      <div
                        style={{
                          overflowWrap: 'break-word',
                          wordBreak: 'break-word',
                          display: 'inline-block',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {t('service.apply_branding.always_on_nonvisible_side')}
                      </div>
                    }
                    name="notvisibleside"
                    valuePropName="checked"
                  >
                    <div
                      style={{ display: 'flex', flexDirection: 'row-reverse' }}
                    >
                      <Switch
                        defaultChecked={nonVisibleSideBrandingSwitch}
                        onChange={onChangeSwitch4}
                      />
                    </div>
                  </Form.Item>
                </>
              ) : null}
            </Col>
            <Divider type="vertical" className="partsdivider" />
            <Col flex="1 0 25%" className="formcolumn">
              <Form.Item
                className="longlabelcheckbox"
                label={
                  <div
                    style={{
                      overflowWrap: 'break-word',
                      wordBreak: 'break-word',
                      display: 'inline-block',
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    {t('service.apply_branding.invisibly_branded')}
                  </div>
                }
                name="invisiblybranded"
                valuePropName="checked"
              >
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                  <Switch
                    defaultChecked={invisiblesideswitch}
                    onChange={onChangeSwitch3}
                  />
                </div>
              </Form.Item>
              {invisiblesideswitch ? (
                <>
                  <Form.Item
                    className="longlabelcheckbox"
                    label={
                      <div
                        style={{
                          overflowWrap: 'break-word',
                          wordBreak: 'break-word',
                          display: 'inline-block',
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        {t('service.apply_branding.product_authentication')}
                      </div>
                    }
                    name="authentification"
                    valuePropName="checked"
                  >
                    <div
                      style={{ display: 'flex', flexDirection: 'row-reverse' }}
                    >
                      <Switch
                        id="authentification"
                        defaultChecked={authentificationSwitch}
                        onChange={onChangeSwitch5}
                      />
                    </div>
                  </Form.Item>
                </>
              ) : null}
              <br />
              <p>{t('service.apply_branding.note_on_responsibility')}</p>
            </Col>
          </Row>
        </Col>
        <Form.Item style={{ float: 'right', marginTop: '20px' }}>
          <Space>
            {/* <Button type="secondary" onClick={onReset}>
              Discard
            </Button> */}
            <Button type="primary" htmlType="submit">
              {t('button.save')}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ExpandableTableCard>
  )
}

export default BrandingConfigForm
