import React, { useState, useEffect } from 'react'
import { useQuery, useQueries } from '@tanstack/react-query'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import {
  fetchUsersOfCompany,
  fetchCompanyOfUser,
  fetchUserRole,
} from '$api/evoAPIs'
import { StyledTable } from './styledComponents'
import { extractServiceUserRole, getRoleColorByName } from './utils'
import TruncateText from '$components/TruncateText'

const useGroupMembersWithRoles = (companyId) => {
  const membersQuery = useQuery(
    ['companyMembers', companyId],
    fetchUsersOfCompany,
    {
      enabled: !!companyId,
    },
  )

  const { data: companyMembers } = membersQuery

  const userRolesQueries = useQueries({
    queries:
      companyMembers?.map((companyMember) => ({
        queryKey: ['userRole', companyMember.id],
        queryFn: fetchUserRole,
      })) || [],
  })

  let companyMembersWithRoles = []
  if (
    membersQuery.data &&
    userRolesQueries.every((userRoleQuery) => userRoleQuery.data)
  ) {
    companyMembersWithRoles = membersQuery.data.map((member, idx) => ({
      ...member,
      role: extractServiceUserRole(userRolesQueries[idx].data),
    }))
  }

  return {
    membersQuery,
    userRolesQueries,
    companyMembersWithRoles,
    isLoading:
      membersQuery.isLoading ||
      userRolesQueries.some((userRoleQuery) => userRoleQuery.isLoading),
  }
}

const Userlist = (props) => {
  const authContext = {
    user: {
      id: 'TODO',
    },
    isSuperAdmin: () => false,
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  // Queries
  // get logged in users role

  const { data: userCompanyId } = useQuery(
    ['usercompany', authContext.user.id],
    fetchCompanyOfUser,
    {
      enabled: !!authContext.user.id && authContext.isSuperadmin(),
      select: (userCompanies) => userCompanies?.[0].id,
    },
  )

  const { companyMembersWithRoles } = useGroupMembersWithRoles(userCompanyId)

  // Handler Functions
  const onRow = (record) => {
    if (authContext.isSuperadmin()) {
      return {
        onClick: () => {
          setSelectedRowKeys([record.id])
          props.setSelectedUserId(record.id)
        },
      }
    }
  }

  const addUser = () => {
    props.setNewUser(true)
    setSelectedRowKeys([])
  }

  useEffect(() => {
    if (props.selectedUserId) {
      setSelectedRowKeys([props.selectedUserId])
    }
  }, [props.selectedUserId])

  const columns = [
    {
      title: 'Name',
      key: 'name',
      className: 'userlistnamecol',
      render: (record) => (
        <TruncateText text={record.firstName + ' ' + record.lastName} />
      ),
      ellipsis: true,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (role) => <TruncateText text={getRoleColorByName(role)} />,
      ellipsis: true,
    },
  ]

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>All Users</h3>
        <Button
          type="primary"
          onClick={addUser}
          disabled={props.newUser || !authContext.isSuperadmin()}
        >
          <PlusOutlined />
        </Button>
      </div>
      <br />
      <StyledTable
        columns={columns}
        dataSource={companyMembersWithRoles}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        }}
        onRow={onRow}
        rowKey={(record) => record.id}
      />
    </>
  )
}

export default Userlist
