import { Tag } from 'antd'
import Tooltip from '$components/Tooltip'
import { StyledGlobal } from '$globalStyledComponents/Global'
import PartNoExpand from '$components/PartsTable/PartNoExpand'
import TruncateText from '$components/TruncateText'

const createColumns = (t, expandedRowKeys, onExpand) => {
  const columns = [
    {
      title: 'Feasible',
      className: 'feas-col',
      width: '7%',
      render: (record) => {
        const fCValue = record?.attributes?.find(
          (x) => x.key === 'Feasibility Check',
        )?.value
        const fill =
          fCValue === 'true'
            ? '#34C38F'
            : fCValue === 'false'
              ? '#CD0204'
              : '#F1B44C'
        return (
          <StyledGlobal className="traffic-light">
            <svg viewBox="0 0 12 12">
              <circle cx="60%" cy="60%" r="4" fill={fill} />
            </svg>
          </StyledGlobal>
        )
      },
    },
    {
      title: t('Part No'),
      render: (record) => (
        <PartNoExpand
          part={record}
          expandedRowKeys={expandedRowKeys}
          onExpand={onExpand}
        />
      ),
      width: '160px',
    },
    {
      title: t('Part Name'),
      dataIndex: 'name',
      render: (text) => <TruncateText text={text} />,
      ellipsis: true,
    },
    {
      title: t('Width'),
      //dataIndex: ['attributes','0','value'],
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Width')
          .map((row) => row.value),
    },
    {
      title: t('Depth'),
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Depth')
          .map((row) => row.value),
    },
    {
      title: t('Height'),
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Height')
          .map((row) => row.value),
    },
    {
      title: `${t('Weight')} (g)`,
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Weight')
          .map((row) => row.value),
    },
    {
      title: t('Material'),
      render: (record) => (
        <TruncateText
          text={record.attributes
            .filter((x) => x.key === 'Material')
            .map((row) => row.value)}
        />
      ),
      ellipsis: true,
    },
    {
      title: t('Status'),
      render: (record) => {
        if (record.value !== undefined) {
          if (record.value === true) {
            return (
              <Tooltip title={t('marketplace.tooltip.finishd')}>
                <Tag color="green">
                  <TruncateText text={t('marketplace.finished')} />
                </Tag>
              </Tooltip>
            )
          } else {
            return (
              <Tooltip title={t('marketplace.tooltip.unfinished')}>
                <Tag>
                  <TruncateText text={t('marketplace.unfinished')} />
                </Tag>
              </Tooltip>
            )
          }
        }
      },
      ellipsis: true,
    },
    // {
    //   title: "Price AM",
    //   render: (record) => record.float_attributes.filter(x => x.key  == "Price AM").map(row =>row.value),
    // },
  ]

  return columns
}

export default createColumns
