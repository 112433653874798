import PartFileDownloadButton from '$components/FileDownload/PartFileDownloadButton'
import BookedServiceLabels from '$components/Label/BookedServiceLabels'
import OrderPartsListView from '$components/OrderPartsListTable/ExpandedRow'

const ViewOffers = ({ orderPartsList }) => {
  const additionalColumns = [
    {
      title: 'Labels',
      render: (record) => (
        <div style={{ width: '170px' }}>
          <BookedServiceLabels
            bookedServiceId={record.id}
            disabled={record.rejected_by_client}
          />
        </div>
      ),
    },
    {
      title: '',
      fixed: 'right',
      render: (record) => (
        <PartFileDownloadButton
          shape="round"
          size="small"
          fileName={record.user_file_location}
        >
          Download STL-file
        </PartFileDownloadButton>
      ),
    },
  ]

  return (
    <OrderPartsListView
      orderPartsList={orderPartsList}
      editable={false}
      showProviderOffers={true}
      showRejectedProviderOffers={true}
      showOfferTerms={true}
      additionalOrderPartsTableColumns={additionalColumns}
    />
  )
}

export default ViewOffers
