import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons'
import { PartNoButton } from './styledComponents'
import { TableNumberButton } from '$globalStyledComponents/Table'
import { theme } from 'antd'

const { useToken } = theme

const PartNoExpand = ({ part, expandedRowKeys, onExpand }) => {
  const { token } = useToken()
  if (typeof expandedRowKeys === 'object') {
    const expanded = expandedRowKeys.includes(part.id)
    return (
      <PartNoButton
        onClick={() => {
          onExpand(!expanded, part)
        }}
      >
        {expanded ? (
          <UilMinusCircle
            size="15"
            color={token.colorPrimary}
            style={{ margin: '0px 5px -3px -5px' }}
          />
        ) : (
          <UilPlusCircle
            size="15"
            color={token.colorPrimary}
            style={{ margin: '0px 5px -3px -5px' }}
          />
        )}
        <TableNumberButton>{part.id_part_client}</TableNumberButton>
      </PartNoButton>
    )
  }

  return <TableNumberButton>{part.id_part_client}</TableNumberButton>
}

export default PartNoExpand
