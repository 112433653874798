import OrderedServicesDetails from '$components/Services/OrderedServicesDetails'
import { useTranslation } from 'react-i18next'

const MyServiceConfigDetails = ({ record }) => {
  const { t } = useTranslation()

  return record.service_name !== 'Feasibility Check' ? (
    <div style={{ width: '71%', marginLeft: '24px' }}>
      <h2
        style={{
          padding: '10px 20px',
          marginBottom: '30px',
          background: 'whitesmoke',
          borderRadius: '6px',
          color: '#6A6B70',
        }}
      >
        {t('bookedservices.my_provided_information')}
      </h2>
      <div style={{ paddingLeft: '20px', width: '97%' }}>
        <OrderedServicesDetails record={record} />
      </div>
    </div>
  ) : null
}
export default MyServiceConfigDetails
