import Partdetails from './Partdetails'
import MyServiceConfigDetails from './MyServiceConfigDetails'

const ExpandedRow = ({ record }) => (
  <>
    <Partdetails record={record} />
    <MyServiceConfigDetails record={record} />
  </>
)

export default ExpandedRow
