import React, { useEffect, useRef } from 'react'
import { StyledOrderstotalCard } from '../globalStyledComponents/Card'
import ReactApexChart from 'react-apexcharts'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { fetchAllBookedServices } from '$api/evoAPIs'
import { theme } from 'antd'
import { hexToRGBA } from '$utils'
import { translate } from '$utils/i18n'

const { useToken } = theme

/*
 *  Stable Function outside of Component.
 *  Calculates total number of orders per month
 *  and per service for the past 12 months
 *  Memoized so calculation does not run on every render.
 */
const calculateOrderCounts = (orders) => {
  if (!orders || !orders.items) {
    return []
  }
  // create date object...
  let aYearAgo = new Date()
  // we want this month + past 11 months so subtract 11 months not 12
  aYearAgo.setMonth(aYearAgo.getMonth() - 11)
  // set to first day of month so we consider all orders within that month
  aYearAgo.setDate(1)

  // fill array with idx of past 12 months
  let past12Months = []
  for (let i = 0; i < 12; i++) {
    let nextMonth = new Date(aYearAgo.valueOf())
    nextMonth.setMonth(nextMonth.getMonth() + i)
    past12Months.push(nextMonth.getMonth())
  }

  let ordersPerMonth = orders.items.reduce(
    (accumulator, order) => {
      const orderDateTime = new Date(order.time_stamp)
      if (orderDateTime >= aYearAgo) {
        accumulator[orderDateTime.getMonth()].total++

        if (!(order.service_name in accumulator[orderDateTime.getMonth()])) {
          Object.keys(accumulator).forEach(function (month) {
            accumulator[month] = {
              ...accumulator[month],
              [order.service_name]: 0,
            }
          })
        }
        accumulator[orderDateTime.getMonth()][order.service_name]++
      }
      return accumulator
    },
    Object.fromEntries(past12Months.map((month) => [month, { total: 0 }])),
  )

  return past12Months.map((month) => ({
    ...ordersPerMonth[month],
    month: month,
  }))
}

const Orderstotal = (props) => {
  const chartRef = useRef()
  const { token } = useToken()
  const { t, i18n } = useTranslation()

  const { data: ordersPast12Months } = useQuery({
    queryKey: ['bookedServices'],
    queryFn: fetchAllBookedServices,
    placeholderData: [],
    select: calculateOrderCounts,
  })

  const showServiceSeries = props.tab && props.tab !== 'All Services'

  const lineChart = {
    width: 2,
    color: token.colorPrimary,
  }

  const barChart = {
    width: 5,
    color: hexToRGBA(token.colorPrimary, 0.3),
  }

  const localePlaceholders = {
    toolbar: {
      selection: 'ordered_services.apex_chart_language.selection',
      selectionZoom: 'ordered_services.apex_chart_language.selectionZoom',
      zoomIn: 'ordered_services.apex_chart_language.zoomIn',
      zoomOut: 'ordered_services.apex_chart_language.zoomOut',
      reset: 'ordered_services.apex_chart_language.reset',
    },
  }
  const locales = i18n.languages.map((lng) => {
    const t = i18n.getFixedT(lng)
    return {
      name: lng,
      options: {
        ...translate(localePlaceholders, t),
      },
    }
  })

  useEffect(() => {
    chartRef.current.chart.setLocale(i18n.language)
    chartRef.current.chart.update()
  }, [i18n.language])

  return (
    <React.Fragment>
      {' '}
      <StyledOrderstotalCard>
        <h3>{t('kpi_charts.services_per_month')}</h3>
        <ReactApexChart
          ref={chartRef}
          series={[
            {
              name: t('kpi_charts.total_orders'),
              type: 'line',
              data: ordersPast12Months.map((orderCount) => orderCount.total),
            },
            ...(showServiceSeries
              ? [
                  {
                    name: props.tab,
                    type: 'column',
                    data: ordersPast12Months.map(
                      (orderCount) => orderCount[props.tab],
                    ),
                  },
                ]
              : []),
          ]}
          type="line"
          height={220}
          className="apex-charts"
          options={{
            chart: {
              defaultLocale: i18n.language,
              height: 280,
              type: 'line',
              stacked: !1,
              toolbar: {
                tools: {
                  download: false,
                  selection: false,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: false,
                  reset: true,
                },
              },
              locales: locales,
            },
            stroke: {
              width: [lineChart.width, barChart.width],
            },
            plotOptions: {
              bar: {
                columnWidth: '20%',
                endingShape: 'rounded',
              },
            },
            colors: [lineChart.color, barChart.color],
            fill: {
              gradient: {
                inverseColors: !1,
                shade: 'light',
                type: 'vertical',
                opacityFrom: 0.85,
                opacityTo: 0.55,
                stops: [0, 100, 100, 100],
              },
            },
            labels: ordersPast12Months.map((month) => {
              const monthDateTime = new Date()
              monthDateTime.setDate(1)
              monthDateTime.setMonth(month.month)
              return t('localized_datetime', {
                val: monthDateTime,
                formatParams: {
                  val: {
                    month: 'short',
                  },
                },
              })
            }),
            legend: {
              showForSingleSeries: true,
              labels: {
                colors: [token.colorText, token.colorText],
              },
            },
            markers: { size: 0 },
            yaxis: {
              min: 0,
              max:
                Math.max(
                  ...ordersPast12Months
                    .map(({ month: _, ...rest }) => Object.values(rest))
                    .flat(),
                ) + 2,
            },
          }}
        />
      </StyledOrderstotalCard>
    </React.Fragment>
  )
}

export default Orderstotal
