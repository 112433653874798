import Brandingconfigtable from './Brandingconfigtable'

const StepTwoBrandingConfig = (props) => {
  return (
    <>
      <h2 style={{ margin: '50px 0' }}>Configure Branding Data</h2>
      <Brandingconfigtable
        setBookableParts={props.setBookableParts}
        bookableParts={props.bookableParts}
        setConfigState={props.setConfigState}
        configState={props.configState}
        setCurrentFormValues={props.setCurrentFormValues}
      />
      <div style={{ marginTop: '20px' }}></div>
    </>
  )
}

export default StepTwoBrandingConfig
