import { Popover } from 'antd'
import { NotBookableReasonButton } from '../styledComponents'

const NotBookableReason = ({ title, description }) => {
  return (
    <Popover content={description} overlayStyle={{ maxWidth: '600px' }}>
      <NotBookableReasonButton danger>{title}</NotBookableReasonButton>
    </Popover>
  )
}

export default NotBookableReason
