import { Input, InputNumber, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import Form from '$components/Form'

const AddressFormItems = ({ prefix: namePathPrefix }) => {
  const { t } = useTranslation()

  const generateNamePath = (name) =>
    namePathPrefix ? [namePathPrefix, name] : name

  return (
    <>
      <Form.Item
        name={generateNamePath('name')}
        label={t('service.order_part.order_address.company_name')}
        rules={[
          {
            required: true,
            message: t('service.order_part.order_address.name_required'),
          },
        ]}
      >
        <Input
          placeholder={t('service.order_part.order_address.input_company_name')}
        />
      </Form.Item>
      <Form.Item
        label={t('service.order_part.order_address.address_line')}
        style={{ marginBottom: 0, width: '101.1%' }}
        required
      >
        <Form.Item
          name={generateNamePath('address_line_1')}
          rules={[
            {
              required: true,
              message: t('service.order_part.order_address.address_required'),
            },
          ]}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
          }}
        >
          <Input placeholder="Input Address line 1 / Street Name / PO Box" />
        </Form.Item>
        <Form.Item
          name={generateNamePath('address_line_2')}
          style={{
            display: 'inline-block',
            width: 'calc(50% - 8px)',
            margin: '0 8px',
          }}
        >
          <Input placeholder="Input Address line 2 / Floor / Building / Unit" />
        </Form.Item>
      </Form.Item>
      <Form.Item
        label={t('service.order_part.order_address.postal_code')}
        style={{ marginBottom: 0, width: '102%' }}
        required
      >
        <Form.Item
          name={generateNamePath('postcode')}
          rules={[{ required: true, message: t('Required') }]}
          normalize={(value) => value.toString()}
          style={{
            display: 'inline-block',
            width: 'calc(16.6% - 8px)',
          }}
        >
          <InputNumber
            placeholder="Input Postal Code / ZIP / PLZ"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name={generateNamePath('city')}
          rules={[
            {
              required: true,
              message: t('service.order_part.order_address.city_required'),
            },
          ]}
          style={{
            display: 'inline-block',
            width: 'calc(33% - 8px)',
            margin: '0 8px',
          }}
        >
          <Input placeholder="Input City" />
        </Form.Item>
        <Form.Item
          name={generateNamePath('state')}
          style={{
            display: 'inline-block',
            width: 'calc(33% - 8px)',
            margin: '0 8px 0 0',
          }}
        >
          <Input placeholder="Input State / Province / Region" />
        </Form.Item>
        <Form.Item
          name={generateNamePath('country')}
          rules={[{ required: true, message: 'Country is required' }]}
          style={{
            display: 'inline-block',
            width: 'calc(16.6% - 8px)',
            margin: '0px',
          }}
        >
          <Select
            placeholder={t('address.country')}
            variant="outlined"
            options={[
              { value: 'DE', label: 'DE' },
              { value: 'FR', label: 'FR' },
              { value: 'US', label: 'US' },
              { value: 'CN', label: 'CN', disabled: true },
            ]}
          />
        </Form.Item>
      </Form.Item>
    </>
  )
}

export default AddressFormItems
