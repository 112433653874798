export default class Company {
  constructor({
    CompanyChain: companyChain,
    addressline1,
    addressline2,
    postalcode,
    city,
    state,
    country,
  }) {
    this.name = companyChain[0].slice(1)
    this.address_line_1 = addressline1
    this.address_line_2 = addressline2
    this.postcode = postalcode
    this.city = city
    this.state = state
    this.country = country
  }
}
