import React from 'react'
import {
  SmallServiceButton,
  ServiceStatusButtonWrapper,
} from './styledComponents'
import { useTranslation } from 'react-i18next'
import { SERVICES } from '$constants'
import { cloneElement } from 'react'

const IS_BOOKED_AND_CAN_BE_BOOKED = 1
const CANNOT_BE_BOOKED = 2
const IS_BOOKED_AND_CANNOT_BE_BOOKED = 3
const CAN_BE_BOOKED = 4

const ServiceStatusButtons = (props) => {
  const { t } = useTranslation()

  const serviceStatuses = {}

  // Initialize service status based on the variant props
  Object.keys(props?.variant || {}).forEach((key) => {
    const { key: serviceKey, value } = props.variant[key]
    SERVICES.forEach((service) => {
      if (service.attributeKeys && service.attributeKeys.includes(serviceKey)) {
        const getStatus = service.getStatus || ((val) => Number(val))
        serviceStatuses[service.servicename] = getStatus(value, serviceKey)
      }
      if (service.subservices) {
        service.subservices.forEach((subservice) => {
          if (
            subservice.attributeKeys &&
            subservice.attributeKeys.includes(serviceKey)
          ) {
            const getStatus = subservice.getStatus || ((val) => Number(val))
            serviceStatuses[subservice.servicename] = getStatus(
              value,
              serviceKey,
            )
          }
        })
      }
    })
  })

  // Render icons based on service statuses
  const renderIcon = (service) => {
    const { icon, servicename } = service

    const status = serviceStatuses[servicename]
    const title = t(servicename)

    // Clone the icon and override its style
    const styledIcon = cloneElement(icon, { size: 18, color: '#495057' })

    return (
      <div
        className={
          status === IS_BOOKED_AND_CAN_BE_BOOKED ||
          status === CAN_BE_BOOKED ||
          status === undefined
            ? 'clickableservice'
            : ''
        }
        title={title}
      >
        {styledIcon}
        {(status === IS_BOOKED_AND_CAN_BE_BOOKED ||
          status === CANNOT_BE_BOOKED ||
          status === IS_BOOKED_AND_CANNOT_BE_BOOKED) && (
          <svg
            style={{
              margin:
                status === IS_BOOKED_AND_CAN_BE_BOOKED
                  ? '0 0 -12px -7px'
                  : '0px 0 -4px -7px',
            }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill={
                status === IS_BOOKED_AND_CAN_BE_BOOKED
                  ? '#7CC334'
                  : status === CANNOT_BE_BOOKED
                    ? '#CD0204'
                    : '#7CC334'
              }
            />
          </svg>
        )}
      </div>
    )
  }

  const renderServices = () => {
    return SERVICES.flatMap((service) => {
      if (service.subservices) {
        return service.subservices.map((subservice) => (
          <SmallServiceButton key={subservice.id}>
            {renderIcon(subservice)}
          </SmallServiceButton>
        ))
      }
      return (
        <SmallServiceButton key={service.id}>
          {renderIcon(service)}
        </SmallServiceButton>
      )
    })
  }

  return (
    <ServiceStatusButtonWrapper>{renderServices()}</ServiceStatusButtonWrapper>
  )
}

export default ServiceStatusButtons
