import { useMemo } from 'react'
import Color from 'color'

// Exported function to get the color based on background brightness
export const useColorByHeaderBackground = (theme) => {
  return useMemo(() => {
    if (!theme.Layout) {
      console.warn('theme.Layout is undefined')
      return theme.colorPrimaryText
    }

    let backgroundColor

    if (theme.Layout.headerBgGradient) {
      const gradientColors = theme.Layout.headerBgGradient
        .replace(/linear-gradient\([^,]+,/, '') // remove everything before the colors (incl. 'deg')
        .match(/rgba?\([^()]*\)|#(?:[0-9a-fA-F]{3}){1,2}\b|[a-zA-Z]+/g) // only get valid colors

      if (gradientColors && gradientColors.length >= 2) {
        const color1 = Color(gradientColors[0])
        const color2 = Color(gradientColors[1])

        // Mix the two colors 50/50
        backgroundColor = color1.mix(color2, 0.5)
      } else {
        console.warn(
          `Invalid gradient format: '${theme.Layout.headerBgGradient}'`,
        )
        backgroundColor = Color('#ffffff') // Default fallback color
      }
    } else {
      // Fallback to a bright background if no gradient is provided
      backgroundColor = Color(theme.Layout?.headerBg || '#ffffff')
    }

    return backgroundColor.isLight() ? theme.colorPrimaryText : '#fff'
  }, [theme])
}
