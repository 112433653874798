import { fetchPartsAsBlob } from '$api/client'
import { DownOutlined } from '@ant-design/icons'
import { UilFileExport } from '@iconscout/react-unicons'
import { Dropdown, Button, message } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import useFetchDownloadQuery from './useFetchDownloadQuery'
import Tooltip from '$components/Tooltip'

const CSV = 'csv'
const JSON = 'json'

const FILE_FORMATS = {
  [CSV]: {
    fileExtension: '.csv',
    name: 'CSV',
    mimeType: 'text/csv',
  },
  [JSON]: {
    fileExtension: '.json',
    name: 'JSON',
    mimeType: 'application/json',
  },
}

const DEFAULT_FORMAT = CSV

const DownloadPartsButton = ({ queryFilters, ...rest }) => {
  const [messageApi, contextHolder] = message.useMessage()
  const { t } = useTranslation()

  const downloadFile = useFetchDownloadQuery()

  const handleDownload = (format) => {
    downloadFile({
      queryKey: [
        'parts',
        { ...queryFilters, pageSize: 100 },
        FILE_FORMATS[format].mimeType,
      ],
      queryFn: fetchPartsAsBlob,
      downloadFileName:
        `${dayjs().format()}-parts` + FILE_FORMATS[format].fileExtension,
      onError: () => messageApi.error(t('download_error')),
    })
  }

  return (
    <>
      {contextHolder}
      <Tooltip placement="top" title={t('parts_table.download_as')}>
        <Dropdown
          placement="bottom"
          arrow
          onClick={() => handleDownload(DEFAULT_FORMAT)}
          menu={{
            items: Object.entries(FILE_FORMATS).map(([format, { name }]) => ({
              label: name,
              key: format,
            })),
            onClick: ({ key: format }) => handleDownload(format),
          }}
          icon={<DownOutlined />}
          {...rest}
        >
          <Button type="text" size="small">
            <UilFileExport
              style={{ height: '16px', margin: '0px 0px -3px -0px' }}
            />
            {t('parts_table.export_parts')}
          </Button>
        </Dropdown>
      </Tooltip>
    </>
  )
}

export default DownloadPartsButton
