import { Col, Divider, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import OffersCreatedProgress from './OffersCreatedProgress'
import { StyledSteps } from './styledComponents'

const ExpandedHeader = ({ offersCreatedPercentage }) => {
  const { t } = useTranslation()

  return (
    <>
      <Row style={{ margin: '20px 0 -20px -20px' }}>
        <Col span={8}>
          <OffersCreatedProgress percentage={offersCreatedPercentage} />
        </Col>
        <Col span={16}>
          <StyledSteps
            progressDot
            current={offersCreatedPercentage < 100 ? 0 : 1}
            items={[
              {
                title: t('received_requests.steps.first.title'),
                description: t('received_requests.steps.first.subtitle'),
              },
              {
                title: t('received_requests.steps.second.title'),
                description: t('received_requests.steps.second.subtitle'),
              },
            ]}
          />
        </Col>
      </Row>
      <Divider />
    </>
  )
}

export default ExpandedHeader
