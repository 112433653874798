import styled from 'styled-components'
import { StyledTabs } from '$globalStyledComponents/Tabs'

export const StyledGroupTabs = styled(StyledTabs)`
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 7px;
  }
  .ant-divider-horizontal {
    margin: 10px 0 21px 0;
  }
  .ant-tabs-tabpane {
    background: ${(props) => props.theme.colorBgLightFour};
  }
`
