import { Col, Row, Avatar, Image, Popover, Space, Button, Flex } from 'antd'
import {
  UserOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useUser } from '$context/user'
import UploadExcelButton from './UploadExcelButton'
import deafaultxls from '$assets/img/defaultXLS.png'
import templatexlsx from '$assets/template-table.xlsx'
import CreatePartButton from './CreatePartButton'

const Topwelcomecard = ({
  partsCount = 0,
  groupsCount = 0,
  currentGroupPartsCount = 0,
  isGroup = false,
  groups,
  activeGroup,
}) => {
  const { t } = useTranslation()
  const user = useUser()

  const info = (
    <div>
      <p
        style={{
          maxWidth: '200px',
        }}
      >
        {t('parts_master_data.upload.instructions')}
      </p>
      <Image width={200} src={deafaultxls} />
    </div>
  )
  const downinfo = (
    <div>
      <a href={templatexlsx} download>
        <Button type="dashed">
          {t('parts_master_data.upload.example_download')}
        </Button>
      </a>
    </div>
  )

  return (
    <>
      <Row className="myevocard">
        <Col span={8}>
          <Row>
            <Col flex="100px">
              <Avatar size={64} icon={<UserOutlined />} />
            </Col>
            <Col flex="auto">
              {t('parts_master_data.welcome_text')}
              <h2>{user.name}</h2>
            </Col>
          </Row>
        </Col>
        <Col
          span={8}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          <Col style={{ width: '50%' }}>
            {t('parts_master_data.groups')}
            <h2>{groupsCount}</h2>
          </Col>
          <Col style={{ width: '50%' }}>
            {t('parts_master_data.total')}
            <h2>{partsCount}</h2>
          </Col>
          <Col style={{ width: '50%' }}>
            {isGroup ? (
              <>
                {t('parts_master_data.in_current_group')}
                <h2>{currentGroupPartsCount}</h2>
              </>
            ) : (
              ''
            )}
          </Col>
        </Col>
        <Col span={8} style={{ textAlign: 'right', margin: 'auto' }}>
          <Flex justify="flex-end" id="importPartsContainer">
            <div style={{ width: '149px', display: 'inline-block' }}>
              <UploadExcelButton groups={groups} activeGroup={activeGroup} />
              <div id="xlsxinfo">
                <Space>
                  {t('parts_master_data.upload.excel_file')}&nbsp;
                  <div style={{ cursor: 'help' }}>
                    <Popover
                      placement="bottomRight"
                      content={info}
                      title={t('parts_master_data.upload.data_format')}
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </div>
                  <Popover
                    placement="bottomRight"
                    content={downinfo}
                    title={t('Download')}
                  >
                    <a href={templatexlsx} download>
                      <DownloadOutlined />
                    </a>
                  </Popover>
                </Space>
              </div>
            </div>
            <CreatePartButton groups={groups} activeGroup={activeGroup} />
          </Flex>
        </Col>
      </Row>
    </>
  )
}

export default Topwelcomecard
