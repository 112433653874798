import { useEffect, useState } from 'react'
import { SlideInOut } from './styledComponents'

const SlideContentWrapper = ({
  isFirstOpen,
  currentStep,
  firstSlide,
  secondSlide,
}) => {
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    setAnimate(false)
    const timeoutId = setTimeout(() => {
      setAnimate(currentStep === 1)
    }, 0)
    // Cleanup
    return () => clearTimeout(timeoutId)
  }, [currentStep])

  return (
    <SlideInOut
      animate={animate}
      isFirstOpen={isFirstOpen}
      currentStep={currentStep}
    >
      <div style={{ width: '100%' }}>{firstSlide}</div>
      <div style={{ width: '100%' }}>{secondSlide}</div>
    </SlideInOut>
  )
}

export default SlideContentWrapper
