// eslint-disable-next-line no-restricted-imports
import { Tooltip } from 'antd'

/**
 * This is a wrapper for Ant Design's Tooltip component to avoid
 * warnings about using the deprecated findDOMNode function.
 * See VBS-1948 and https://ant.design/components/tooltip#why-does-the-warning-finddomnode-is-deprecated-some-times-appear-in-strict-mode
 * for more details.
 */
const TooltipWrapper = ({ children, ...props }) => {
  return (
    <Tooltip {...props}>
      <div>{children}</div>
    </Tooltip>
  )
}

export default TooltipWrapper
