import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const StyledLink = styled(Link)`
  position: relative;
  padding: 3px 16px 3px 7px;
  border: 1px solid ${(props) => props.theme.colorPrimary} !important;
  border-radius: 6px;

  &:after {
    position: absolute;
    content: '\\2197';
    top: 0;
    right: 0;
    height: 9px;
    width: 9px;
    background: ${(props) => props.theme.colorPrimary};
    color: #fff;
    font-size: 5px;
    line-height: 8px;
    text-align: center;
    border-radius: 0px 4px 0px 0px;
  }
`
