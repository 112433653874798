import { useEffect, useState } from 'react'
import { Image } from 'antd'
import { fallback } from '$utils'
import { StyledTag } from '$globalStyledComponents/Tags'
import { useTranslation } from 'react-i18next'

const Partimage = ({ partPng, isBrandedOrTextured }) => {
  const { t } = useTranslation()
  const [Img, setImg] = useState(fallback)

  useEffect(() => {
    if (partPng && !Img) {
      setImg(URL.createObjectURL(partPng))
    } else {
      // targets a bug that shows fallback even if the src {Img} is set
      document
        .getElementById('imgdetailview')
        .setAttribute('fallback', fallback)
    }
  }, [partPng, Img])

  return (
    <>
      {isBrandedOrTextured?.branded ? (
        <StyledTag bordered={false}>{t('marketplace.branded')}</StyledTag>
      ) : isBrandedOrTextured?.textured ? (
        <StyledTag bordered={false}>{t('marketplace.textured')}</StyledTag>
      ) : null}
      <Image
        id="imgdetailview"
        src={Img}
        //fallback={fallback}
        width="100%"
        preview={false}
        // style={{ cursor: "pointer" }}
      />
    </>
  )
}

export default Partimage
