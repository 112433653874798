import {
  OFFER_ACCEPTED,
  OrderPartsListStatusTag,
  REQUEST_RECEIVED,
} from '$components/OrderPartsListStatus'
import ExpirationDate from '$components/OrderPartsListTable/ExpirationDate'
import {
  calculateOrderPartPrice,
  summarizeDeliveryTimes,
} from '$components/OrderPartsListTable/utils'
import { currencyRenderer, dateWithTimeRenderer } from '$utils/i18n'
import { ValidationWrapper } from '$globalStyledComponents/Text'
import TruncateText from '$components/TruncateText'
import FilterDropdown from '$components/FilterDropdown'
import TextHighlighter from '$components/TextHighlighter'
import { SearchOutlined } from '@ant-design/icons'

const createColumns = (
  t,
  materialsById,
  formValues,
  determineStatus,
  filters,
  theme,
) => {
  const columns = [
    {
      title: t('received_requests.request_no'),
      dataIndex: 'order_number',
      sorter: true,
      filterDropdown: (props) => (
        <FilterDropdown
          {...props}
          operators={['contains']}
          parseValue={(value) => value}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? theme.colorPrimary : undefined }}
        />
      ),
      render: (ordernr) => {
        return (
          <div style={{ minWidth: '100px' }}>
            <b>
              <TextHighlighter
                text={ordernr ? ordernr.toString() : ''}
                filters={
                  filters.order_number ? [{ value: filters.order_number }] : []
                }
              />
            </b>
          </div>
        )
      },
    },
    {
      title: t('received_requests.client_company'),
      dataIndex: 'client_company',
      sorter: true,
      filterDropdown: (props) => (
        <FilterDropdown
          {...props}
          operators={['contains']}
          parseValue={(value) => value}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{ color: filtered ? theme.colorPrimary : undefined }}
        />
      ),
      render: (_value, record) => (
        <TextHighlighter
          text={record.client_company.slice(1)}
          filters={
            filters.client_company ? [{ value: filters.client_company }] : []
          }
        />
      ),
    },
    {
      title: t('received_requests.request_date'),
      dataIndex: 'time_stamp',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (_value, record) =>
        dateWithTimeRenderer(t)(record.list_of_orderparts[0].time_stamp),
    },
    {
      title: t('received_requests.date_of_expiration'),
      dataIndex: 'date_of_expiration', // Unique, unused key for sorting
      sorter: true,
      render: (_value, record) => {
        if (!record.provider_accepted_offer) {
          return ''
        }

        return <ExpirationDate date_of_expiration={record.date_of_expiration} />
      },
    },
    {
      title: t('received_requests.parts_amount'),
      render: (record) => {
        // sum all part quantities in the amount field(s) of list_of_orderparts
        return (
          determineStatus(record) === OFFER_ACCEPTED
            ? record.list_of_orderparts.filter(
                (orderPart) => !orderPart.rejected_by_client,
              )
            : record.list_of_orderparts
        ).reduce((accumulator, { amount }) => accumulator + amount, 0)
      },
    },
    {
      title: t('received_requests.materials'),
      render: (record) => {
        // merges all material names of all list_of_orderparts into one comma-separated string
        const namesString = Array.from(
          new Set(
            (determineStatus(record) === OFFER_ACCEPTED
              ? record.list_of_orderparts.filter(
                  (orderPart) => !orderPart.rejected_by_client,
                )
              : record.list_of_orderparts
            ).map((part) => materialsById[part.material]?.name),
          ),
        )
          .filter((name) => name)
          .join(', ')

        return <TruncateText text={namesString} />
      },
      ellipsis: true,
    },
    {
      title: t('received_requests.delivery_time'),
      render: (record) => {
        let delivery_times = []

        switch (determineStatus(record)) {
          case REQUEST_RECEIVED:
            delivery_times = record.list_of_orderparts
              .map((orderPart) =>
                orderPart.provider_offers
                  .map((storedOffer) => {
                    const liveOffer =
                      formValues?.[record.id]?.[orderPart.id]?.provider_offers[
                        storedOffer.offer_id
                      ]
                    const offer = liveOffer || storedOffer
                    return [
                      offer.base_production_time,
                      ...(offer.production_time_rules
                        ? Object.values(offer.production_time_rules).map(
                            (rule) => rule.max_days,
                          )
                        : []),
                    ]
                  })
                  .flat(),
              )
              .flat()
            break
          case OFFER_ACCEPTED:
            delivery_times = record.list_of_orderparts
              .filter((orderPart) => !orderPart.rejected_by_client)
              .map((orderPart) =>
                orderPart.provider_offers.map((offer) => offer.delivery_days),
              )
              .flat()
            break
          default:
            delivery_times = record.list_of_orderparts
              .filter((orderPart) => orderPart.provider_offers.length > 0)
              .map((orderPart) =>
                orderPart.provider_offers
                  .map((offer) => [
                    offer.base_production_time,
                    ...(offer.production_time_rules
                      ? offer.production_time_rules.map((rule) => rule.max_days)
                      : []),
                  ])
                  .flat(),
              )
              .flat()
            break
        }

        if (delivery_times.length > 0) {
          return t(
            'received_requests.days',
            summarizeDeliveryTimes(delivery_times),
          )
        } else {
          return t('received_requests.not_yet_defined')
        }
      },
    },
    {
      title: t('received_requests.price_total'),
      render: (record) => {
        let prices = []
        const status = determineStatus(record)

        let orderParts = record.list_of_orderparts
        if (status === OFFER_ACCEPTED) {
          orderParts = orderParts.filter(
            (orderPart) => !orderPart.rejected_by_client,
          )
        }

        prices = orderParts
          .map((orderPart) => {
            const liveOfferValues = Object.values(
              formValues?.[record.id]?.[orderPart.id]?.provider_offers || {},
            )

            if (liveOfferValues?.length) {
              return calculateOrderPartPrice(
                liveOfferValues,
                record.client_accepted_offer,
              )
            } else if (orderPart.provider_offers?.length) {
              return calculateOrderPartPrice(
                orderPart.provider_offers,
                record.client_accepted_offer,
              )
            }

            return undefined
          })
          .filter((price) => !!price)

        if (prices.length > 0) {
          const price = prices.reduce((sum, a) => sum + a, 0)
          return (
            <ValidationWrapper $valid={price > 0}>
              {currencyRenderer(t)(price)}
            </ValidationWrapper>
          )
        } else {
          return t('received_requests.not_yet_defined')
        }
      },
    },
    {
      title: t('received_requests.status'),
      render: (record) => (
        <TruncateText
          text={<OrderPartsListStatusTag orderPartsList={record} />}
        />
      ),
      ellipsis: true,
    },
  ]

  return columns
}

export default createColumns
