import { Col, Row, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { getRoleColorByName } from './utils'
import userasset from '$assets/img/userprofile.png'
import { useUser, useCompany } from '$context/user'

const Usercard = () => {
  const user = useUser()
  const company = useCompany()

  return (
    <>
      <div style={{ backgroundColor: '#D0DFE3', borderRadius: '8px 8px 0 0' }}>
        <Row>
          <Col span={18}>
            <div style={{ padding: '24px' }}>
              <h3 className="text-primary">Welcome, {user.name} !</h3>
              <p>
                Staff member of <i>{company.name}</i>
              </p>
            </div>
          </Col>
          <Col span={6}>
            <img
              src={userasset}
              alt=""
              style={{
                width: '160px',
                margin: '12px 0px -6px -73px',
                opacity: '0.8',
              }}
            />
          </Col>
        </Row>
      </div>
      <div style={{ padding: '0 24px 24px' }}>
        <Row justify="space-between">
          <Col span={12}>
            <div style={{ marginTop: '-34px' }}>
              <Avatar
                icon={<UserOutlined />}
                style={{
                  margin: '3px 3px 5px',
                  border: '5px solid white',
                  width: '60px',
                  height: '60px',
                  fontSize: '27px',
                  lineHeight: '47px',
                }}
              />
            </div>
            <h4 className="font-size-15 text-truncate">{user.name}</h4>
            {getRoleColorByName(`${user.role}`)}
          </Col>

          <Col span={12}>
            <div style={{ marginTop: '18px' }}>
              <Row justify="space-between"></Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Usercard
