import { fetchAllOrderPartsLists } from '$api/evoAPIs'
import { useUser } from '$context/user'
import { UserOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { Avatar, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

const Topwelcomecard = () => {
  const { t } = useTranslation()
  const user = useUser()

  const { data: { total: totalOrders } = {} } = useQuery(
    ['allorderedpartslists'],
    fetchAllOrderPartsLists,
    {
      placeholderData: [],
    },
  )

  return (
    <>
      <Row className="myevocard">
        <Col span={6}>
          <Row style={{ isplay: 'flex', flexWrap: 'nowrap' }}>
            <Col flex="80px">
              <Avatar size={64} icon={<UserOutlined />} />
            </Col>
            <Col flex="auto">
              {t('received_requests.welcome_text')}
              <h2>{user.name}</h2>
            </Col>
          </Row>
        </Col>
        <Col
          span={12}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          <Col style={{ width: '50%' }}>
            {t('received_requests.total_orders')}
            <h2>{totalOrders}</h2>
          </Col>
        </Col>
        <Col span={6} style={{ textAlign: 'right', margin: 'auto' }}></Col>
      </Row>
    </>
  )
}

export default Topwelcomecard
