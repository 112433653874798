import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useCompany } from '$context/user'

const OrderSummaryHeader = () => {
  const { t } = useTranslation()
  const company = useCompany()

  return (
    <div>
      <Row style={{ margin: '23px 0px 13px' }}>
        <Col span={12}>
          <div
            style={{
              borderRight: '1px solid #dfe1e4',
              marginRight: '25px',
              paddingRight: '10px',
            }}
          >
            <h2 style={{ opacity: '0.6' }}>
              {t('bookedservices.date_of_request')}
            </h2>
            <p>{new Date().toLocaleString() + ''}</p>
          </div>
        </Col>
        <Col span={12}>
          <div
            style={{
              borderRight: '1px solid #dfe1e4',
              marginRight: '25px',
              paddingRight: '10px',
            }}
          >
            <h2 style={{ opacity: '0.6' }}>{t('Customer')}</h2>
            <p>{company.name}</p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default OrderSummaryHeader
