import styled from 'styled-components'
import { StyledTable as DefaultStyledTable } from '$pages/serviceuser/Marketplace/Bookservice/Defaultwizard/styledComponents'
import { CloseCircleOutlined } from '@ant-design/icons'

export const StyledTable = styled(DefaultStyledTable)`
  tr.meta_row > td {
    background-color: #f6f6f6;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const StyledCloseIcon = styled(CloseCircleOutlined)`
  position: absolute;
  right: 1em;
  top: 1.25em;
`
