import { theme } from 'antd'
import { ThemeProvider } from 'styled-components'

const StyledComponentsThemeProvider = ({ children, ...rest }) => {
  const { token } = theme.useToken()

  return (
    <ThemeProvider theme={token} {...rest}>
      {children}
    </ThemeProvider>
  )
}

export default StyledComponentsThemeProvider
