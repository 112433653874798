import { TableNumberButton } from '$globalStyledComponents/Table'
import { Progress } from 'antd'
import { convertToPercentage, progressColor } from '$utils'
import { dateWithTimeRenderer } from '$utils/i18n'
import TruncateText from '$components/TruncateText'

const createColumns = (t) => {
  const columns = [
    {
      title: t('bookedservices.service_name'),
      dataIndex: 'service_name',
      sorter: true,
      render: (title) => <TruncateText text={title} />,
      ellipsis: true,
    },
    {
      title: t('Part No'),
      dataIndex: 'part.id_part_client',
      sorter: true,
      width: '160px',
      render: (_, bookedService) => (
        <TableNumberButton>
          {bookedService.part.id_part_client}
        </TableNumberButton>
      ),
    },
    {
      title: t('Part Name'),
      dataIndex: 'part.name',
      sorter: true,
      render: (_, bookedService) => (
        <TruncateText text={bookedService.part.name} />
      ),
      ellipsis: true,
    },
    {
      title: t('bookedservices.group'),
      dataIndex: 'partlist_name',
    },
    {
      title: t('bookedservices.date_of_request'),
      dataIndex: 'time_stamp',
      sorter: true,
      defaultSortOrder: 'descend',
      width: '6% !important',
      render: (time_stamp) => dateWithTimeRenderer(t)(time_stamp),
    },
    {
      title: t('Progress'),
      progress: '',
      width: '22%',
      render: (record) => (
        <Progress
          percent={convertToPercentage(record.status_info)}
          size="small"
          strokeColor={progressColor(convertToPercentage(record.status_info))}
        />
      ),
    },
  ]

  return columns
}

export default createColumns
