import useMaterialMapping from '$utils/useMaterialMapping'

const INTERNAL_PROPERTIES = [
  // {
  //   set top level properties explicitly:
  //   name: 'some_name'  // used as attribute key in DB and Form.Item names
  //   label: 'Some Name' // display label
  //
  //   set one of following two bools to add to respective attribute array instead:
  //   float_attribute: true
  //   attributes: true
  //
  //   settings:
  //   required: true
  //   units: {
  //     <unit>: <scale factor relative to default unit> e.g.:
  //     mm: 1, // unit with value 1 will be default
  //     cm: 10, // scaled by 10 relative to mm
  //     m: 1000,
  //   }
  // }
  {
    name: 'id_part_client',
    label: 'Part No',
    required: true,
    units: {},
  },
  {
    name: 'name',
    label: 'Part Name',
    required: true,
  },
  {
    name: 'Width',
    float_attribute: true,
    required: true,
    units: {
      mm: 1,
      cm: 10,
      m: 1000,
    },
  },
  {
    name: 'Depth',
    float_attribute: true,
    required: true,
    units: {
      mm: 1,
      cm: 10,
      m: 1000,
    },
  },
  {
    name: 'Height',
    float_attribute: true,
    required: true,
    units: {
      mm: 1,
      cm: 10,
      m: 1000,
    },
  },
  {
    name: 'Weight',
    float_attribute: true,
    units: {
      mg: 0.001,
      g: 1,
      kg: 1000,
    },
  },
  {
    name: 'Material',
    attribute: true,
    useOptions: () => {
      const { data, isLoading, ...rest } = useMaterialMapping()
      if (isLoading) {
        return { data, isLoading, ...rest }
      }
      return { data: Object.values(data), isLoading, ...rest }
    },
  },
  {
    name: 'Price',
    float_attribute: true,
    units: {
      ct: 0.01,
      EUR: 1,
    },
  },
  {
    name: 'Price AM',
    float_attribute: true,
    units: {
      ct: 0.01,
      EUR: 1,
    },
  },
]

export default INTERNAL_PROPERTIES
