import { Modal } from 'antd'
import { useTranslation } from 'react-i18next'

const LegalTextModal = (props) => {
  const { t } = useTranslation()
  const markup = { __html: props.legalText.text }
  return (
    <>
      <Modal
        title={t('bookedservices.legal_text')}
        centered
        open={props.open}
        onOk={() => props.setOpen(false)}
        onCancel={() => props.setOpen(false)}
        width={1000}
      >
        <div
          dangerouslySetInnerHTML={markup}
          style={{
            height: '70vh',
            overflow: 'auto',
          }}
        />
      </Modal>
    </>
  )
}
export default LegalTextModal
