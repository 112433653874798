import { useState } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UilTrashAlt } from '@iconscout/react-unicons'
import { Button, notification, Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'

import { evoClient } from '$api/client'
import PartsTable from '$components/PartsTable'
import ExpandedRow from './ExpandedRow'

const GroupPartsTable = ({ groupId, columns, setGroupPartsLength }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [selectedParts, setSelectedParts] = useState({})

  const deletePartsFromGroupMutation = useMutation(
    (partIds) => {
      return evoClient.delete(`/parts-lists/${groupId}/parts`, {
        headers: {
          'Content-Type': 'application/json',
        },
        data: partIds,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['groupparts'])
        queryClient.invalidateQueries(['parts'], { groupId })

        setSelectedParts({})

        notification.success({
          description: t('parts_master_data.delete_part_from_group_success'),
        })
      },
      onError: () => {
        notification.error({
          description: t('parts_master_data.delete_part_from_group_error'),
        })
      },
    },
  )

  const groupPartsTableFooter = (selectedParts) => (
    <Popconfirm
      title={t('parts_master_data.delete_part_from_group_confirm', {
        count: Object.keys(selectedParts).length,
      })}
      placement="topLeft"
      okText={t('button.confirm')}
      cancelText={t('button.cancel')}
      onConfirm={() => {
        deletePartsFromGroupMutation.mutate(Object.keys(selectedParts))
      }}
    >
      <Button type="primary" disabled={Object.keys(selectedParts).length === 0}>
        {t('parts_master_data.delete_parts_from_group', {
          count: Object.keys(selectedParts).length,
        })}
        <UilTrashAlt
          size="18"
          style={{ margin: '0px 0px -4px 12px', cursor: 'pointer' }}
        />
      </Button>
    </Popconfirm>
  )

  return (
    <PartsTable
      columns={columns}
      groupId={groupId}
      expandable={{
        expandedRowRender: (record) => <ExpandedRow record={record} />,
        expandRowByClick: true,
      }}
      selectable={{
        show: true,
        selectedParts,
        setSelectedParts,
      }}
      filterable={true}
      onLoad={(_, totalParts) => setGroupPartsLength(totalParts)}
      footer={groupPartsTableFooter}
    />
  )
}

export default GroupPartsTable
