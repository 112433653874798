import { UilCheck } from '@iconscout/react-unicons'
import { Button } from 'antd'
import ServiceStatusButtons from '../../../PartsTable/ServiceStatusButtons'
import { PartNoBg } from './styledComponents'
import TruncateText from '$components/TruncateText'

const createColumns = (t) => {
  const columns = [
    {
      title: t('Part No'),
      dataIndex: 'id_part_client',
      render: (title) => <PartNoBg>{title}</PartNoBg>,
      width: '160px',
    },
    {
      title: t('Part Name'),
      dataIndex: 'name',
      render: (text) => <TruncateText text={text} />,
      ellipsis: true,
    },
    {
      title: t('service.generate_3d_model.part_has_3d_model'),
      render: (record) => {
        if (record.has3dmodel === true) {
          return (
            <Button
              style={{ margin: '-6px 0' }}
              type="secondary"
              shape="circle"
              icon={
                <UilCheck
                  style={{
                    height: '19px',
                    color: '#7CC334',
                    margin: '-3px 0px',
                  }}
                />
              }
            />
          )
        }
      },
    },
    {
      title: t('Services'),
      width: '265px',
      render: (record) => (
        <ServiceStatusButtons variant={record.status_attributes} />
      ),
    },
  ]

  return columns
}

export default createColumns
