import { StyledLayout } from '../../../globalStyledComponents/Layout'
import Footer from '../../../components/Footer'
import Topwelcomecard from './Topwelcomecard'
import Materialmapper from './MaterialMapper'
import { Badge } from 'antd'

const { Content } = StyledLayout

const Mymaterials = () => {
  return (
    <StyledLayout className="layout">
      <Content>
        <h1 style={{ margin: '50px 0' }}>My Materials</h1>
        <Badge.Ribbon text="demo" color="#CCC" style={{ margin: '10px 0' }}>
          <Topwelcomecard />
        </Badge.Ribbon>
        <Badge.Ribbon text="demo" color="#CCC" style={{ margin: '10px 0' }}>
          <div className="contentcard" style={{ marginTop: '15px' }}>
            <Materialmapper />
          </div>
        </Badge.Ribbon>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default Mymaterials
