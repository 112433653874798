import { useTranslation } from 'react-i18next'
import Orderedservicestable from '$pages/serviceprovider/Orderedservices/Orderedservicestable'
import ServicesPage from '$components/Services/ServicesTabs'

const Orderedservices = () => {
  const { t } = useTranslation()

  return (
    <ServicesPage
      TableComponent={Orderedservicestable}
      title={t('ordered_services.title')}
    />
  )
}

export default Orderedservices
