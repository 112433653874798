import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { CenteredSpinner } from '$components/Spinner'
import ThemeProvider from './context/ThemeProvider'
import AuthContextProvider from './context/AuthContextProvider'
import { RefetchTimerProvider } from './context/RefetchTimerProvider'
import UserIdleHandler from '$components/UserIdleHandler'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './i18n'
import QueryClientProvider from '$context/QueryClientProvider'
import LoadUserCentricsScript from '$context/LoadUserCentricsScript'
import Layout from '$components/Layout'
import GuidedTour from '$components/GuidedTour'

if (process.env.NODE_ENV !== 'production') {
  document.body.classList.add(process.env.NODE_ENV || 'development')
}

const root = ReactDOM.createRoot(document.getElementById('root'))
const fallbackloader = (
  <CenteredSpinner message={'please wait, authenticating'} />
)

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <QueryClientProvider>
        <ThemeProvider>
          <RefetchTimerProvider>
            <StrictMode>
              <Suspense fallback={fallbackloader}>
                <UserIdleHandler>
                  <Layout>
                    <LoadUserCentricsScript>
                      <GuidedTour>
                        <Routes />
                      </GuidedTour>
                    </LoadUserCentricsScript>
                  </Layout>
                </UserIdleHandler>
              </Suspense>
              <ReactQueryDevtools initialIsOpen={false} />
            </StrictMode>
          </RefetchTimerProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </AuthContextProvider>
  </BrowserRouter>,
)
