import { dateRenderer } from '$utils/i18n'
import { useTranslation } from 'react-i18next'

import { ValidationWrapper } from '$globalStyledComponents/Text'

const ExpirationDate = ({ date_of_expiration }) => {
  const { t } = useTranslation()

  return (
    <ValidationWrapper $valid={new Date(date_of_expiration) >= new Date()}>
      {dateRenderer(t)(date_of_expiration)}
    </ValidationWrapper>
  )
}

export default ExpirationDate
