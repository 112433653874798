import React, { createContext, useContext, useState } from 'react'
import { startTimer, stopTimer } from '$utils'

const RefetchTimerContext = createContext()

export const RefetchTimerProvider = ({ children }) => {
  const [isRunning, setIsRunning] = useState(false)

  const startRefetch = (interval, callback, delay) => {
    setTimeout(() => {
      startTimer(interval, callback)
      setIsRunning(true)
    }, delay)
  }

  const stopRefetch = () => {
    stopTimer()
    setIsRunning(false)
  }

  return (
    <RefetchTimerContext.Provider
      value={{ isRunning, startRefetch, stopRefetch }}
    >
      {children}
    </RefetchTimerContext.Provider>
  )
}

export const useRefetchTimer = () => useContext(RefetchTimerContext)
