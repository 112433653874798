import {
  OFFER_ACCEPTED,
  OFFER_REJECTED,
  OFFER_MADE,
  REQUEST_RECEIVED,
  REQUEST_REJECTED,
  useOrderPartsListStatus,
} from '$components/OrderPartsListStatus'
import CreateOffersView from './CreateOffersView'
import ViewOffers from './ViewOffers'

const ExpandedRow = ({
  orderPartsList,
  orderPartsFormValues,
  setOrderPartsFormValues,
  deleteRule,
  deleteProviderOffer,
}) => {
  const { status } = useOrderPartsListStatus(orderPartsList)

  switch (status) {
    case REQUEST_RECEIVED:
      return (
        <CreateOffersView
          orderPartsList={orderPartsList}
          setOrderPartsFormValues={setOrderPartsFormValues}
          orderPartsFormValues={orderPartsFormValues}
          deleteRule={deleteRule}
          deleteProviderOffer={deleteProviderOffer}
        />
      )
    case REQUEST_REJECTED:
    case OFFER_MADE:
    case OFFER_ACCEPTED:
    case OFFER_REJECTED:
      return <ViewOffers orderPartsList={orderPartsList} />
    default:
      return
  }
}

export default ExpandedRow
