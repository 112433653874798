import Editgroup from './Editgroup'
import GroupPartsTable from './PartsTable'

const Tabcontent = ({
  groupName,
  setGroupName,
  groupDescription,
  setGroupDescription,
  filterDescription,
  setGroupFilterDescription,
  setIsChanged,
  setGroupPartsLength,
  groupId,
  columns,
}) => {
  const isNewGroup = !groupId

  return (
    <>
      <Editgroup
        groupId={groupId}
        setGroupName={setGroupName}
        groupName={groupName}
        setGroupDescription={setGroupDescription}
        groupDescription={groupDescription}
        groupFilterDescription={filterDescription}
        setGroupFilterDescription={setGroupFilterDescription}
        setIsChanged={setIsChanged}
      />
      {!isNewGroup && (
        <GroupPartsTable
          groupId={groupId}
          columns={columns}
          setGroupPartsLength={setGroupPartsLength}
        />
      )}
    </>
  )
}
export default Tabcontent
