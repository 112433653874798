import { getPartThumbnailAsServiceProvider } from '$api/evoAPIs'
import OrderedServicesDetails from '$components/Services/OrderedServicesDetails'
import { UilCalendarAlt } from '@iconscout/react-unicons'
import { useQuery } from '@tanstack/react-query'
import { Col, Divider, Input, Row, Switch } from 'antd'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import Partimage from '$components/Services/PartImage/Partimage'
import UploadFiles from './UploadFiles'
const { TextArea } = Input

const Order = ({
  record,
  setIsFileUploaded,
  setOrderOfferText,
  orderOfferText,
  setAcceptOrder,
  acceptOrder,
  orderRejectText,
  setOrderRejectText,
  current,
}) => {
  const { t } = useTranslation()

  dayjs.extend(advancedFormat)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()

  const isBrandedOrTextured =
    record.service_name === 'Apply Branding'
      ? { branded: true }
      : record.service_name === 'Apply Texturing'
        ? { textured: true }
        : null

  const partId = record?.part?.id

  const { data: partPng } = useQuery({
    queryKey: ['partThumbnail', partId],
    queryFn: getPartThumbnailAsServiceProvider,
    enabled: !!partId,
    select: (data) => new Blob([data], { type: 'image/png' }),
  })

  const getStepContent = () => {
    switch (current) {
      case 0:
        return stepOne(record)
      case 1:
        return <OrderedServicesDetails record={record} />
      case 2:
        return record.service_name === 'Order Part'
          ? stepThreeOrderPart()
          : stepThree(record)
      case 3:
        return record.service_name === 'Order Part'
          ? stepLastOrderPart()
          : stepLast(record)
      default:
        return (
          <Row style={{ width: '100%' }}>
            <Col
              span={12}
              className="formcolumn"
              style={{ padding: '0 30px 0 0' }}
            >
              <Partimage partPng={partPng} />
            </Col>
          </Row>
        )
    }
  }

  // Im Moment wird nur die Ansicht für die Bestelldaten in eine eigene Komponente ausgelagert,
  // da sie komplexer ist. Kann später auch für die anderen Steps relevant werden.

  const stepOne = (record) => {
    return (
      <Row style={{ width: '100%' }}>
        <Col span={12} className="formcolumn" style={{ padding: '0 30px 0 0' }}>
          <Partimage partPng={partPng} />
        </Col>
        <Col span={12} className="formcolumn" style={{ padding: '0 30px 0 0' }}>
          <h3>{t('info')}</h3>
          <p>{t('bookedservices.accept_and_mark')}</p>
          <Divider />
          <h3>{t('bookedservices.order_placed_at')}: </h3>
          <Row>
            <Col>
              <UilCalendarAlt
                style={{ margin: '0 18px -11px 0', width: '19px' }}
              />
            </Col>
            <Col>
              <h2 style={{ margin: '0 0', fontSize: '17px' }}>
                {t('localized_datetime', {
                  val: new Date(record.time_stamp),
                  formatParams: {
                    val: {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    },
                  },
                })}
              </h2>
              <div>
                {t('localized_datetime', {
                  val: new Date(record.time_stamp),
                  formatParams: {
                    val: {
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                    },
                  },
                })}
                &nbsp;<i>({dayjs(record.time_stamp).format('z')})</i>
              </div>
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
    )
  }

  const stepThree = (record) => {
    return (
      <Row style={{ width: '100%' }}>
        <Col span={12} className="formcolumn" style={{ padding: '0 30px 0 0' }}>
          <UploadFiles record={record} setIsFileUploaded={setIsFileUploaded} />
        </Col>
        <Col span={12} className="formcolumn" style={{ padding: '0 30px 0 0' }}>
          <h3>{t('info')}</h3>
          <p>{t('bookedservices.upload_finished_files')}</p>
          <Divider />
        </Col>
      </Row>
    )
  }

  const onOrderOfferTextChange = (e) => {
    setOrderOfferText(e.target.value)
  }

  const onOrderRejectTextChange = (e) => {
    setOrderRejectText(e.target.value)
  }

  const onChangeAcceptOrderSwitch = () => {
    setAcceptOrder(!acceptOrder)
  }

  const stepThreeOrderPart = () => {
    return (
      <>
        <Row style={{ width: '100%', display: 'inline' }}>
          <h3>{t('info')}</h3>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>{t('bookedservices.place_offer')}</p>
            <span>
              <span style={{ marginRight: '15px' }}>
                {t('bookedservices.accept_order')}
              </span>
              <Switch defaultChecked onChange={onChangeAcceptOrderSwitch} />
            </span>
          </div>
        </Row>
        <Divider />
        {acceptOrder ? (
          <Row>
            <TextArea
              value={orderOfferText}
              rows={8}
              onChange={onOrderOfferTextChange}
            />
          </Row>
        ) : (
          <>
            <p>{t('bookedservices.order_rejected')}</p>
            <Row>
              <TextArea
                value={orderRejectText}
                rows={8}
                onChange={onOrderRejectTextChange}
              />
            </Row>
          </>
        )}
      </>
    )
  }

  const stepLastOrderPart = () => {
    return (
      <>
        <Row style={{ width: '100%' }}>
          <p>{t('bookedservices.order_is_finished')}</p>
        </Row>
        <Divider />
        <Row>
          <p style={{ whiteSpace: 'pre-line' }}>
            {orderOfferText
              ? orderOfferText
              : t('bookedservices.order_rejected')}
          </p>
        </Row>
      </>
    )
  }

  const stepLast = () => {
    return (
      <Row style={{ width: '100%' }}>
        <Col span={12} className="formcolumn" style={{ padding: '0 30px 0 0' }}>
          <Partimage
            partPng={partPng}
            isBrandedOrTextured={isBrandedOrTextured}
          />
        </Col>
        <Col span={12} className="formcolumn" style={{ padding: '0 30px 0 0' }}>
          <p>{t('bookedservices.order_is_finished')}</p>
        </Col>
      </Row>
    )
  }

  return getStepContent()
}

export default Order
