import { BarChartOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Divider, message, Row, Tag } from 'antd'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { evoClient } from '$api/client'
import { fetchFileAsServiceUser } from '$api/evoAPIs'
import { parts } from '$api/client'
import DownloadImage from './DownloadImage'
import Partimage from '$components/Services/PartImage/Partimage'
import Progresssteps from './Progresssteps'
import { ExpandableTableCard, LabelText } from './styledComponents'
import { StyledGlobal } from '$globalStyledComponents/Global'
import { convertStatusInfoToStep } from '$utils'
import { useTranslation } from 'react-i18next'
import useFetchDownloadQuery from '$components/FileDownload/useFetchDownloadQuery'

const Partdetails = ({ record }) => {
  const [current, setCurrent] = useState(0)
  const [partPng, setPartPng] = useState('')
  const { t } = useTranslation()
  const [messageApi, contextHolder] = message.useMessage()

  const downloadFile = useFetchDownloadQuery()

  const steps = [
    {
      title: t('bookedservices.status.placed'),
      description: t('bookedservices.order_placed_by_customer'),
    },
    {
      title: t('bookedservices.status.in_progress'),
      description: t('bookedservices.order_is_being_processed'),
    },
    {
      title: t('bookedservices.status.delivery'),
      description: t('bookedservices.task_completed'),
    },
  ]
  useEffect(() => {
    dayjs.extend(advancedFormat)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.guess()

    // set current state: in orderedservice view state 2 is not finished, state 3 is.
    // if (!record?.part_status === false) {
    if (convertStatusInfoToStep(record?.status_info) === 2) {
      setCurrent(convertStatusInfoToStep(record.status_info) - 1)
    } else if (convertStatusInfoToStep(record?.status_info) === 3) {
      setCurrent(2)
    } else {
      setCurrent(convertStatusInfoToStep(record?.status_info))
    }
    // }
    // this is only when its a feasibility check
    if (record?.status && record?.status === 100) {
      setCurrent(2)
    }

    // get the part image from client
    evoClient
      .get(`${parts}${record.part.db_id_client}/png/`, {
        responseType: 'blob',
      })
      .then(async (resp) => {
        const asyncBlob = await resp.data.arrayBuffer()
        const blob = new Blob([asyncBlob], { type: 'image/png' })
        setPartPng(blob)
      })
      .catch((error) => {
        // handle error
        console.error(error)
      })
  }, [record.part, record?.status, record?.status_info])

  const downloadFinalFile = () => {
    const { part, provider_file_location: fileName } = record
    if (!part.db_id_client || !fileName) {
      messageApi.error(t('download_error'))
      return
    }

    downloadFile({
      queryKey: ['parts', part.db_id_client, 'file', fileName],
      queryFn: fetchFileAsServiceUser,
      downloadFileName: fileName,
      onError: () => messageApi.error(t('download_error')),
    })
  }

  const renderFCResult = () => {
    const fCValue = record.feasible_printers.length > 0
    const fill = fCValue ? '#34C38F' : '#CD0204'
    return (
      <div style={{ display: 'flex' }}>
        <StyledGlobal className="traffic-light">
          <svg viewBox="0 0 12 12">
            <circle cx="60%" cy="60%" r="4" fill={fill} />
          </svg>
        </StyledGlobal>
        <span style={{ marginTop: '1px' }}>
          {fCValue ? 'feasible' : 'not feasible'}
        </span>
      </div>
    )
  }

  const isBrandedOrTextured =
    record.service_name === 'Apply Branding'
      ? { branded: true }
      : record.service_name === 'Apply Texturing'
        ? { textured: true }
        : null

  const getStepContent = () => {
    switch (current) {
      case 0:
        return (
          <Partimage
            partPng={partPng}
            isBrandedOrTextured={isBrandedOrTextured}
          />
        )
      case 1:
        return (
          <Partimage
            partPng={partPng}
            isBrandedOrTextured={isBrandedOrTextured}
          />
        )
      case 2:
        return <DownloadImage />

      default:
        return (
          <Partimage
            partPng={partPng}
            isBrandedOrTextured={isBrandedOrTextured}
          />
        )
    }
  }
  const getStepContent2 = () => {
    switch (current) {
      case 0:
        return (
          <p style={{ width: 187 }}>
            {t('bookedservices.service_ordered_waiting')}
          </p>
        )
      case 1:
        return (
          <>
            <Row>
              <Col span={10}>
                <LabelText style={{ marginBottom: '2px' }}>: </LabelText>
              </Col>
              <Col>
                <p>
                  {dayjs(record.time_stamp).format('DD.MM.YYYY, k:mm:ss, z')}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <LabelText style={{ marginBottom: '2px' }}>
                  {t('bookedservices.time_passed')}:
                </LabelText>
              </Col>
              <Col>
                <p>{dayjs(record.time_stamp).fromNow()}</p>
              </Col>
            </Row>
            {record?.status_info === 'in_delivery' && (
              <Row>
                <Col span={10}>
                  <LabelText style={{ marginBottom: '2px' }}>
                    {t('received_requests.status')}:
                  </LabelText>
                </Col>
                <Col>
                  <Tag>{t('bookedservices.delivering')}</Tag>
                </Col>
              </Row>
            )}
          </>
        )
      case 2:
        return (
          <>
            <Row>
              <Col span={10}>
                <LabelText style={{ marginBottom: '2px' }}>
                  {t('bookedservices.date_of_request')}:
                </LabelText>
              </Col>
              <Col>
                <p>
                  {dayjs(record.time_stamp).format('DD.MM.YYYY, k:mm:ss, z')}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={10}>
                <LabelText style={{ marginBottom: '2px' }}>
                  {t('marketplace.result')}:
                </LabelText>
              </Col>
              <Col>
                {record.feasible_printers ? (
                  renderFCResult()
                ) : (
                  <Button
                    disabled={record?.status_info === 'finished' ? false : true}
                    type="primary"
                    size="small"
                    onClick={downloadFinalFile}
                  >
                    {t('Download')}
                  </Button>
                )}
              </Col>
            </Row>
          </>
        )

      default:
        return (
          <p>
            {t('bookedservices.status.unknown')} {current}.
          </p>
        )
    }
  }

  return (
    <>
      {contextHolder}
      <ExpandableTableCard>
        <Row className="row">
          <Col span={9} style={{ padding: '0 30px 0 0' }}>
            {getStepContent()}
          </Col>
          <Col span={10} style={{ padding: '0 30px 0 0' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ margin: '-10px 10px 0 -8px' }}>
                <Avatar
                  icon={<BarChartOutlined />}
                  style={{
                    margin: '3px 3px 5px',
                    border: '5px solid white',
                    width: '52px',
                    height: '52px',
                    fontSize: '22px',
                    lineHeight: '43px',
                  }}
                />
              </div>
              <div>
                <LabelText style={{ marginBottom: '2px' }}>
                  {t('service.processed_by')}:
                </LabelText>
                <p>Daimler Buses GmbH</p>
                {/* {record.company.substring(1)} */}
              </div>
            </div>
            <Divider />
            {getStepContent2()}
            <Divider />
          </Col>
          <Col span={5}>
            <Progresssteps current={current} steps={steps} />
          </Col>
        </Row>
      </ExpandableTableCard>
    </>
  )
}

export default Partdetails
