import { Layout } from 'antd'
import styled from 'styled-components'

export const StyledLayout = styled(Layout)`
    max-width: 1380px;
    margin: 0 auto;
    padding: 0 30px;

    .header-with-inlines {
      margin: 50px 0;

      > * {
        display: inline;
      }

      * + a.jump {
        margin-left: 50px;
      }

      a.jump + a.jump {
        margin-left: 20px;
      }
    } 

    .contentcard{
      background-color: white;
      box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
      padding: 20px;
      margin-bottom: 15px;
    }

    .partsmasterdatatablecard {
      background-color: white;
      box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
      padding: 20px;
      margin: 15px 0;
    }

    .servicestablecard {
      background-color: white;
      box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
      padding: 20px;
    }
}
`
export const StyledDemoLayout = styled.div`
  .bubbles {
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  .bubble {
    position: absolute;
    left: var(--bubble-left-offset);
    bottom: -60px;
    display: block;
    width: var(--bubble-radius);
    height: var(--bubble-radius);
    border-radius: 50%;
    animation: float-up var(--bubble-float-duration) var(--bubble-float-delay)
      ease-in infinite;
  }
  .bubble::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #3c7d8c80;
    border-radius: inherit;
    animation: var(--bubble-sway-type) var(--bubble-sway-duration)
      var(--bubble-sway-delay) ease-in-out alternate infinite;
  }
  .bubble:nth-child(0) {
    --bubble-left-offset: 95vw;
    --bubble-radius: 3vw;
    --bubble-float-duration: 31s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 5s;
    --bubble-sway-delay: 7s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(1) {
    --bubble-left-offset: 13vw;
    --bubble-radius: 2.3vw;
    --bubble-float-duration: 14s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 2s;
    --bubble-sway-delay: 2s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(2) {
    --bubble-left-offset: 96vw;
    --bubble-radius: 1.6vw;
    --bubble-float-duration: 31s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 10s;
    --bubble-sway-delay: 2s;
    --bubble-sway-type: sway-right-to-left;
  }
  .bubble:nth-child(3) {
    --bubble-left-offset: 86vw;
    --bubble-radius: 2.2vw;
    --bubble-float-duration: 29s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 9s;
    --bubble-sway-delay: 4s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(4) {
    --bubble-left-offset: 97vw;
    --bubble-radius: 3vw;
    --bubble-float-duration: 18s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 12s;
    --bubble-sway-delay: 8s;
    --bubble-sway-type: sway-right-to-left;
  }
  .bubble:nth-child(5) {
    --bubble-left-offset: 11vw;
    --bubble-radius: 0.6vw;
    --bubble-float-duration: 28s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 1s;
    --bubble-sway-delay: 4s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(6) {
    --bubble-left-offset: 15vw;
    --bubble-radius: 1.6vw;
    --bubble-float-duration: 20s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 1s;
    --bubble-sway-delay: 4s;
    --bubble-sway-type: sway-right-to-left;
  }
  .bubble:nth-child(7) {
    --bubble-left-offset: 97vw;
    --bubble-radius: 2.6vw;
    --bubble-float-duration: 26s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 3s;
    --bubble-sway-delay: 8s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(8) {
    --bubble-left-offset: 79vw;
    --bubble-radius: 2.2vw;
    --bubble-float-duration: 21s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 0s;
    --bubble-sway-delay: 1s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(9) {
    --bubble-left-offset: 57vw;
    --bubble-radius: 1.2vw;
    --bubble-float-duration: 13s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 6s;
    --bubble-sway-delay: 2s;
    --bubble-sway-type: sway-right-to-left;
  }
  .bubble:nth-child(10) {
    --bubble-left-offset: 42vw;
    --bubble-radius: 0.6vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 3s;
    --bubble-sway-delay: 7s;
    --bubble-sway-type: sway-right-to-left;
  }
  .bubble:nth-child(11) {
    --bubble-left-offset: 42vw;
    --bubble-radius: 1.6vw;
    --bubble-float-duration: 19s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 9s;
    --bubble-sway-delay: 7s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(12) {
    --bubble-left-offset: 99vw;
    --bubble-radius: 0.6vw;
    --bubble-float-duration: 16s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 13s;
    --bubble-sway-delay: 4s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(13) {
    --bubble-left-offset: 93vw;
    --bubble-radius: 0.6vw;
    --bubble-float-duration: 30s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 9s;
    --bubble-sway-delay: 7s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(14) {
    --bubble-left-offset: 78vw;
    --bubble-radius: 1.2vw;
    --bubble-float-duration: 23s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 9s;
    --bubble-sway-delay: 4s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(15) {
    --bubble-left-offset: 11vw;
    --bubble-radius: 2vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 7s;
    --bubble-float-delay: 7s;
    --bubble-sway-delay: 2s;
    --bubble-sway-type: sway-right-to-left;
  }
  .bubble:nth-child(16) {
    --bubble-left-offset: 7vw;
    --bubble-radius: 1.2vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 2s;
    --bubble-float-delay: 5s;
    --bubble-sway-delay: 1s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(17) {
    --bubble-left-offset: 33vw;
    --bubble-radius: 2vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 3s;
    --bubble-float-delay: 4s;
    --bubble-sway-delay: 2s;
    --bubble-sway-type: sway-right-to-left;
  }
  .bubble:nth-child(18) {
    --bubble-left-offset: 22vw;
    --bubble-radius: 1vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 4s;
    --bubble-float-delay: 3s;
    --bubble-sway-delay: 3s;
    --bubble-sway-type: sway-left-to-right;
  }
  .bubble:nth-child(19) {
    --bubble-left-offset: 77vw;
    --bubble-radius: 2.8vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 5s;
    --bubble-float-delay: 3s;
    --bubble-sway-delay: 4s;
    --bubble-sway-type: sway-right-to-left;
  }
  .bubble:nth-child(20) {
    --bubble-left-offset: 88vw;
    --bubble-radius: 0.6vw;
    --bubble-float-duration: 15s;
    --bubble-sway-duration: 6s;
    --bubble-float-delay: 1s;
    --bubble-sway-delay: 5s;
    --bubble-sway-type: sway-left-to-right;
  }

  @keyframes float-up {
    to {
      transform: translateY(-200vh);
    }
  }
  @keyframes sway-left-to-right {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
  @keyframes sway-right-to-left {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
`
