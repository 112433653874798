import { Row, Col } from 'antd'
import PartDetailForm from '$components/PartDetailForm'
import Part3dFileUpload from '$components/FileUpload/Part3dFileUpload'

const ExpandedRow = ({ record }) => (
  <Row gutter={[20, 0]}>
    <Col span={6}>
      <Part3dFileUpload record={record} />
    </Col>
    <Col span={18}>
      <PartDetailForm record={record} showEditDates={true} />
    </Col>
  </Row>
)

export default ExpandedRow
