import React, { useRef, useState } from 'react'
import Tooltip from '$components/Tooltip'
import { StyledTruncate } from '$components/TruncateText/TruncateStyled'

const TruncateText = ({ text }) => {
  const textRef = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const handleOverflow = () => {
    if (textRef.current) {
      setShowTooltip(textRef.current.scrollWidth > textRef.current.offsetWidth)
    }
  }

  return (
    <Tooltip title={showTooltip ? text : ''}>
      <StyledTruncate
        ref={textRef}
        onMouseEnter={handleOverflow}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {text}
      </StyledTruncate>
    </Tooltip>
  )
}

export default TruncateText
