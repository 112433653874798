import { useIgnoreCMP } from '$utils'

const LegalDE = () => {
  useIgnoreCMP()

  return (
    <div style={{ margin: '0 200px' }}>
      <h2 style={{ textAlign: 'center' }}>Rechtliche Hinweise</h2>

      <p>&nbsp;</p>

      <p>
        <strong>Urheberrecht</strong>
        <br />
        <br />
        Copyright Daimler Buses GmbH. Alle Rechte vorbehalten. Alle Texte,
        Bilder, Graphiken, Ton-, Video- und Animationsdateien sowie ihre
        Arrangements unterliegen dem Urheberrecht und anderen Gesetzen zum
        Schutz geistigen Eigentums. Sie d&uuml;rfen weder f&uuml;r Handelszwecke
        oder zur Weitergabe kopiert, noch ver&auml;ndert und auf anderen
        Web-Sites verwendet werden. Einige unserer Internet-Seiten enthalten
        auch Material, das dem Urheberrecht derjenigen unterliegt, die dieses
        zur Verf&uuml;gung gestellt haben.
      </p>

      <p>
        <strong>Produkte und Preise</strong>
        <br />
        <br />
        Nach Redaktionsschluss einzelner Seiten k&ouml;nnen sich &Auml;nderungen
        bei Produkten und Leistungen ergeben haben. Konstruktions- oder
        Form&auml;nderungen, Abweichungen im Farbton sowie &Auml;nderungen des
        Liefer- oder Leistungsumfangs seitens des Herstellers bleiben
        w&auml;hrend der Lieferzeit vorbehalten, sofern die &Auml;nderungen oder
        Abweichungen unter Ber&uuml;cksichtigung unserer Interessen f&uuml;r den
        Kunden zumutbar sind. Die Abbildungen k&ouml;nnen auch Zubeh&ouml;r,
        Sonderausstattungen oder sonstige Umf&auml;nge enthalten, die nicht zum
        serienm&auml;&szlig;igen Liefer- oder Leistungsumfang geh&ouml;ren.
        Farbabweichungen sind technisch bedingt. Einzelne Seiten k&ouml;nnen
        auch Typen und Leistungen enthalten, die in einzelnen L&auml;ndern nicht
        angeboten werden. Aussagen &uuml;ber gesetzliche, rechtliche und
        steuerliche Vorschriften und Auswirkungen haben nur f&uuml;r die
        Bundesrepublik Deutschland G&uuml;ltigkeit. Vorbehaltlich
        anderslautender Regelung in den Verkaufs- oder Lieferbedingungen gelten
        die am Tage der Lieferung g&uuml;ltigen Preise. F&uuml;r unsere
        Vertragspartner verstehen sich die Preise als unverbindliche
        Preisempfehlung. Fragen Sie daher zum letzten Stand bitte eine
        Niederlassung oder einen Vertragspartner.
      </p>

      <p>
        <strong>Handelsmarken</strong>
        <br />
        <br />
        Wo nicht anders angegeben, unterliegen alle auf den Internet-Seiten von
        Daimler Buses genannten Marken dem Schutz des geistigen Eigentums der
        Daimler Truck AG, dies gilt insbesondere f&uuml;r die Modellnamen sowie
        alle Logos und Embleme des Unternehmens.
      </p>

      <p>
        <strong>Lizenzrechte</strong>
        <br />
        <br />
        Wir m&ouml;chten Ihnen ein innovatives und informatives
        Internet-Programm anbieten. Wir hoffen deshalb, dass Sie sich &uuml;ber
        unsere kreative Gestaltung genauso freuen wie wir. Wir bitten Sie aber
        dennoch um Verst&auml;ndnis daf&uuml;r, dass wir unser geistiges
        Eigentum, einschlie&szlig;lich Patente, Marken, Kennzeichnungs- und
        Urheberrechte, sch&uuml;tzen m&uuml;ssen und diese Internet-Seiten
        keinerlei Lizenzrechte an diesem geistigen Eigentum gew&auml;hren
        k&ouml;nnen.
      </p>

      <p>
        <strong>Haftung</strong>
        <br />
        <br />
        Die Informationen und Angaben auf diesen Seiten stellen keine
        Zusicherung oder Garantie dar, sei sie ausdr&uuml;cklich oder
        stillschweigend. Sie stellen insbesondere keine stillschweigende Zusage
        oder Garantie betreffend die Beschaffenheit, die Handelsf&auml;higkeit,
        die Eignung f&uuml;r bestimmte Zwecke oder den Nichtversto&szlig; gegen
        Gesetze und Patente dar.
        <br />
        <br />
        Auf unseren Internet-Seiten finden Sie auch Links zu anderen Seiten im
        Internet. Wir m&ouml;chten Sie darauf hinweisen, dass wir keinen
        Einfluss auf die Gestaltung und den Inhalt der Seiten haben, auf die
        verlinkt wird. Wir k&ouml;nnen daher auch keine Gew&auml;hr f&uuml;r die
        Aktualit&auml;t, Korrektheit, Vollst&auml;ndigkeit oder Qualit&auml;t
        der dort bereitgestellten Informationen &uuml;bernehmen. Vor diesem
        Hintergrund distanzieren wir uns hiermit von allen Inhalten dieser
        Seiten. Diese Erkl&auml;rung gilt f&uuml;r alle auf unseren
        Internet-Seiten enthaltenen Links zu externen Seiten und deren Inhalte.
      </p>

      <p>&nbsp;</p>

      <p>
        <strong>Informationen zur Online-Streitbeilegung</strong>
        <br />
        <br />
        Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von
        Streitigkeiten (sog. &bdquo;OS-Plattform&ldquo;) geschaffen. Die
        OS-Plattform dient als Anlaufstelle zur au&szlig;ergerichtlichen
        Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen,
        die aus Online-Kaufvertr&auml;gen erwachsen. Sie k&ouml;nnen die
        OS-Plattform unter dem folgenden Link erreichen:
        <br />
        <br />
        <a
          href="https://ec.europa.eu/consumers/odr"
          target="_blank"
          rel="noreferrer"
        >
          https://ec.europa.eu/consumers/odr
        </a>
      </p>

      <p>
        <strong>
          Hinweis gem&auml;&szlig; &sect; 36 Verbraucherstreitbeilegungsgesetz
          (VSBG)
        </strong>
        <br />
        <br />
        Wir werden nicht an einem Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle im Sinne des deutschen
        Verbraucherstreitbeilegungsgesetzes (VSBG) teilnehmen und sind hierzu
        auch nicht verpflichtet.
      </p>

      <p>
        <strong>Rangfolge</strong>
        <br />
        <br />
        Nutzungsbedingungen f&uuml;r ein Digitales Angebot haben Vorrang vor
        diesen rechtlichen Hinweisen.
      </p>

      <p>&nbsp;</p>
    </div>
  )
}

export default LegalDE
