import { retrieveTokens } from '$api/tokenManagement'

const tokenInterceptor = (config) => {
  const { accesstoken } = retrieveTokens()
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${accesstoken}`,
    },
  }
}

export default tokenInterceptor
