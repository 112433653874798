import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { REDIRECT_PARAM } from '$constants'
import HeaderRoute from '$components/HeaderRoute'
import { CenteredSpinner } from '$components/Spinner'
import { useToken } from '$context/user'

const ProtectedRoute = ({ current, setCurrent, intendedPath }) => {
  const { loginInProgress, token } = useToken()
  const { t } = useTranslation()

  if (loginInProgress) {
    return <CenteredSpinner message={t('please wait, authenticating')} />
  }

  return token ? (
    <HeaderRoute currentRoute={current} setCurrentRoute={setCurrent} />
  ) : (
    <Navigate
      to={`/login${
        intendedPath && intendedPath !== '/'
          ? `?${REDIRECT_PARAM}=${intendedPath}`
          : ''
      }`}
      replace
    />
  )
}

export default ProtectedRoute
