const IDTOKEN = 'idtoken'
const ACCESSTOKEN = 'accesstoken'
const REFRESHTOKEN = 'refreshToken'

export const retrieveTokens = () => ({
  idtoken: localStorage.getItem(IDTOKEN),
  accesstoken: localStorage.getItem(ACCESSTOKEN),
  refreshToken: localStorage.getItem(REFRESHTOKEN),
})

export const storeTokens = (idtoken, accesstoken, refreshToken) => {
  localStorage.setItem(IDTOKEN, idtoken)
  localStorage.setItem(ACCESSTOKEN, accesstoken)
  localStorage.setItem(REFRESHTOKEN, refreshToken)
}

export const clearTokens = () => {
  localStorage.removeItem(ACCESSTOKEN)
  localStorage.removeItem(IDTOKEN)
  localStorage.removeItem(REFRESHTOKEN)
}
