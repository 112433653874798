import { Modal } from 'antd'
import styled from 'styled-components'

export const StyledModal = styled(Modal)`

    .anticon {
        color: #00566A;
    }
    .ant-btn-primary {
        background-color: #00566A;
    }
}
`

export const StyledDemoModal = styled(Modal)`
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-header {
    background-color: ${(props) => props.theme.colorPrimary};
    padding: 2px;
    margin-bottom: 16px !important;
  }
  .ant-modal-body {
    padding: 0 16px;
  }
  .ant-modal-footer {
    padding: 16px;
  }
  .ant-modal-title {
    color: white;
    font-weight: 500 !important;
  }
  .ant-modal-close-x {
    color: white;
  }
  .ant-modal-close {
    margin-top: -6px;
  }
`
