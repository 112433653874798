import { evoConsolidation } from '$api'
import { consolidationPart } from '$api/evoAPIs'
import { SERVICES } from '$constants'
import { DownloadOutlined, QuestionOutlined } from '@ant-design/icons'
import { UilCalendarAlt } from '@iconscout/react-unicons'
import { Button, Col, Divider, List, Row, Tag, Empty } from 'antd'
import Tooltip from '$components/Tooltip'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { StyledRoundButton } from '../../styledComponents'
import { StyledCard } from '$globalStyledComponents/Card'

const OrderedServicesList = ({
  data,
  loading,
  pageSize,
  setPageNr,
  totalParts,
}) => {
  const { t } = useTranslation()

  React.useEffect(() => {
    dayjs.extend(advancedFormat)
    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.guess()
  }, [])

  return (
    <List
      grid={{ gutter: 12, column: 3 }}
      dataSource={data}
      loading={loading}
      pagination={{
        total: totalParts,
        onChange: setPageNr,
        pageSize: pageSize,
        align: 'start',
      }}
      locale={{
        emptyText: (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t('marketplace.no_services_booked')}
            style={{ margin: '0' }}
          />
        ),
      }}
      renderItem={(item) => {
        let icon = (
          SERVICES.find((s) => s.id === item.service_id) ||
          SERVICES.flatMap((s) => s.subservices || []).find(
            (sub) => sub.id === item.service_id,
          )
        )?.icon

        icon
          ? (icon = React.cloneElement(icon, {
              size: 23,
              style: { marginTop: '5px' },
            }))
          : (icon = React.cloneElement(<QuestionOutlined />, {
              size: 23,
              style: { marginTop: '5px', color: '#F1B44C' },
            }))

        const getFinalFile = (item) => {
          // get the part final file from client
          evoConsolidation
            .get(
              `${consolidationPart}${item.part.db_id_client}/file?file_name=${item.provider_file_location}`,
              {
                responseType: 'blob',
              },
            )
            .then(async (resp) => {
              const asyncBlob = await resp.data.arrayBuffer()
              const blob = new Blob([asyncBlob], {
                type: 'application/octet-stream',
              })
              const url = URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = item.provider_file_location
              a.style.display = 'none'
              document.body.appendChild(a)
              a.click()
              URL.revokeObjectURL(url)
              document.body.removeChild(a)
            })
            .catch((error) => {
              // handle error
              console.error(error)
            })
          return item.provider_file_location
        }

        return (
          <React.Fragment>
            <Col style={{ marginBottom: '12px' }}>
              <StyledCard>
                <Row>
                  <Col flex="95px">
                    <StyledRoundButton
                      style={{ width: '50px', height: '50px' }}
                      size="large"
                      type="primary"
                      shape="circle"
                      icon={icon}
                    />
                  </Col>

                  <Col flex="auto">
                    <h4>
                      <Link to={`/myservices`}>{t(item.service_name)}</Link>
                    </h4>
                    <p>by Daimler Buses</p>
                  </Col>
                </Row>
                <Divider />
                <Row style={{ marginBottom: '-10px' }}>
                  <Col span={6}>
                    <Tag
                      color={item?.status_info === 'finished' ? 'green' : ''}
                    >
                      {item?.status_info === 'finished' ? (
                        <Tooltip title={t('marketplace.tooltip.finished')}>
                          {t('marketplace.finished')}
                        </Tooltip>
                      ) : (
                        <Tooltip title={t('marketplace.tooltip.in_progress')}>
                          {t('marketplace.in_progress')}
                        </Tooltip>
                      )}
                    </Tag>
                  </Col>
                  <Col span={11}>
                    <UilCalendarAlt
                      style={{ margin: '-3px 5px -7px 0px', width: '13px' }}
                    />
                    {dayjs(item.time_stamp).format('DD.MM.YYYY')}
                  </Col>
                  <Col span={6}>
                    {item.service_name === 'Feasibility Check' ||
                    item.service_name === 'Order Part' ? (
                      <></>
                    ) : item?.status_info === 'finished' ? (
                      <Button
                        type="dashed"
                        size="small"
                        shape="round"
                        icon={<DownloadOutlined />}
                        onClick={() => getFinalFile(item)}
                      >
                        {t('marketplace.result')}
                      </Button>
                    ) : (
                      <Button
                        type="dashed"
                        size="small"
                        shape="round"
                        icon={<DownloadOutlined />}
                        disabled
                      >
                        {t('marketplace.result')}
                      </Button>
                    )}
                  </Col>
                </Row>
              </StyledCard>
            </Col>
          </React.Fragment>
        )
      }}
    />
  )
}

OrderedServicesList.propTypes = {
  projects: PropTypes.array,
}

export default OrderedServicesList
