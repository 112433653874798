import { useTranslation } from 'react-i18next'
import { StyledBadge } from './styledComponents'
import { theme } from 'antd'

const { useToken } = theme

const Myservicestabsitem = (itemprop) => {
  const { t } = useTranslation()
  const { token } = useToken()

  return (
    <div
      style={{
        borderLeft: '3px',
        margin: '18px 0 0',
        textAlign: 'center',
      }}
    >
      <StyledBadge
        count={itemprop.itemprop.performed}
        offset={[3, -1]}
        style={{ fontSize: '10px', backgroundColor: token.colorPrimary }}
        title={t('my_services.unread_notifications_for', {
          count: itemprop.itemprop.performed,
          service: itemprop.itemprop.label,
        })}
      >
        <div style={{ fontSize: '14px' }}>{itemprop.itemprop.icon}</div>
      </StyledBadge>
      <h4 style={{ paddingTop: '2px', border: 'none' }}>
        {t(itemprop.itemprop.i18nextLabel)}
      </h4>
    </div>
  )
}

export default Myservicestabsitem
