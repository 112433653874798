import { UilUpload } from '@iconscout/react-unicons'
import { Button } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { checkFileSize } from '$utils'
import { evoConsolidation } from '$api'
import {
  postApplyBranding,
  postApplyTexturing,
  postGenerate3DModel,
} from '$api/evoAPIs'
import { StyledUpload } from './styledComponents'

const UploadFiles = (props) => {
  const { t } = useTranslation()
  const [fileList, setFileList] = useState([])
  const [buttonHeight, setButtonHeight] = useState('250px')
  const [progress, setProgress] = useState(0)

  const beforeUpload = (file) => checkFileSize(file.size, '2 GB', t)

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options

    const fmData = new FormData()
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        const percent = Math.floor((event.loaded / event.total) * 100)
        setProgress(percent)
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000)
        }
        onProgress({ percent: (event.loaded / event.total) * 100 })
      },
    }
    fmData.append('file', file)

    try {
      if (props.record.service_name === 'Apply Branding') {
        await evoConsolidation.put(
          postApplyBranding + props.record.id,
          fmData,
          config,
        )
      } else if (props.record.service_name === 'Apply Texturing') {
        await evoConsolidation.put(
          postApplyTexturing + props.record.id,
          fmData,
          config,
        )
      } else if (props.record.service_name === 'Generate 3D Model') {
        await evoConsolidation.put(
          postGenerate3DModel + props.record.id + '/file',
          fmData,
          config,
        )
      }

      onSuccess(props.setIsFileUploaded(true))
    } catch (err) {
      console.error('Error: ', err)
      onError({ err })
    }
  }

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  useEffect(() => {
    if (fileList.length > 0) {
      setButtonHeight('58px')
    } else {
      setButtonHeight('250px')
    }
  }, [fileList])

  return (
    <>
      <StyledUpload
        listType="picture"
        defaultFileList={fileList}
        customRequest={uploadImage}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        progress={progress}
      >
        <Button
          block
          style={{ height: buttonHeight }}
          icon={
            <UilUpload
              style={{ height: '15px', margin: '0px 9px -3px -1px' }}
            />
          }
        >
          Upload
        </Button>
      </StyledUpload>
    </>
  )
}
export default UploadFiles
