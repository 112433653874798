import { Button, Tag } from 'antd'
import { RedoOutlined, SearchOutlined } from '@ant-design/icons'

import FilterDropdown from '$components/FilterDropdown'
import TextHighlighter from '$components/TextHighlighter'
import Tooltip from '$components/Tooltip'
import TruncateText from '$components/TruncateText'
import { currencyRenderer, dateWithTimeRenderer } from '$utils/i18n'

import ExpirationDate from './ExpirationDate'
import { calculateOrderPartPrice, summarizeDeliveryTimes } from './utils'
import { StyledLink } from './ExpandedRow/styledComponents'

export const getColumns = ({
  t,
  filters,
  theme,
  materialsById,
  handleAcceptOffer,
  openOrderService,
  orderPartFormData,
  orderPartListsData,
  orderPartListStatus,
}) => [
  {
    title: (
      <div style={{ whiteSpace: 'normal', overflowWrap: 'anywhere' }}>
        {t('my_orders.order_nr')}
      </div>
    ),
    dataIndex: 'order_number',
    sorter: true,
    filterDropdown: (props) => (
      <FilterDropdown
        {...props}
        operators={['contains']}
        parseValue={(value) => value}
      />
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{ color: filtered ? theme.colorPrimary : undefined }}
      />
    ),
    render: (ordernr) => {
      return (
        <div style={{ minWidth: '100px' }}>
          <b>
            <TextHighlighter
              text={ordernr ? ordernr.toString() : ''}
              filters={
                filters.order_number ? [{ value: filters.order_number }] : []
              }
            />
          </b>
        </div>
      )
    },
  },
  {
    title: (
      <div style={{ whiteSpace: 'normal', overflowWrap: 'anywhere' }}>
        {t('my_orders.order_date')}
      </div>
    ),
    dataIndex: 'time_stamp',
    sorter: true,
    defaultSortOrder: 'descend',
    render: (_value, record) =>
      dateWithTimeRenderer(t)(record.list_of_orderparts[0].time_stamp),
  },
  {
    title: t('my_orders.date_of_expiration'),
    dataIndex: 'date_of_expiration', // Unique, unused key for sorting
    sorter: true,
    render: (_value, record) => {
      if (!record.provider_accepted_offer) {
        return ''
      }

      return <ExpirationDate date_of_expiration={record.date_of_expiration} />
    },
  },
  {
    title: t('my_orders.parts_amount'),
    dataIndex: 'list_of_orderparts',
    sorter: true,
    render: (_value, record) => {
      const partsAmount = record.list_of_orderparts.filter(
        (orderPart) => !orderPart.rejected_by_client,
      ).length
      return partsAmount
    },
  },
  {
    title: t('my_orders.materials'),
    dataIndex: 'materials', // Unique, unused key for sorting
    sorter: true,
    render: (_value, record) => {
      const materialNames = record.list_of_orderparts
        .filter((orderPart) => !orderPart.rejected_by_client)
        .map((obj) => materialsById[obj.material]?.name || '')
        .join(', ')

      const isTruncated = materialNames.length > 30
      const displayText = isTruncated
        ? `${materialNames.substring(0, 30)}...`
        : materialNames

      return (
        <Tooltip title={isTruncated ? materialNames : ''}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <TruncateText text={displayText} />
          </div>
        </Tooltip>
      )
    },
    ellipsis: true,
  },
  {
    title: t('my_orders.delivery_time'),
    dataIndex: 'delivery_time', // Unique, unused key for sorting
    sorter: true,
    render: (_value, record) => {
      if (!record.provider_accepted_offer) {
        return ''
      }

      let offers_delivery_days
      if (orderPartFormData[record.id]) {
        offers_delivery_days = Object.values(orderPartFormData[record.id])
          .filter((orderPart) => !orderPart.rejected_by_client)
          .map((orderPart) =>
            Object.values(orderPart.provider_offers).map(
              (offer) => offer.delivery_days,
            ),
          )
          .flat()
      } else {
        offers_delivery_days = record.list_of_orderparts
          .filter((orderPart) => !orderPart.rejected_by_client)
          .map((orderPart) =>
            orderPart.provider_offers.map((offer) => offer.delivery_days),
          )
          .flat()
      }
      return t(
        'received_requests.days',
        summarizeDeliveryTimes(offers_delivery_days),
      )
    },
  },
  {
    title: t('my_orders.price_total'),
    dataIndex: 'price_total', // Unique, unused key for sorting
    render: (_value, record) => {
      if (!record.provider_accepted_offer) {
        return ''
      }

      let batch_prices
      if (orderPartFormData[record.id]) {
        batch_prices = Object.values(orderPartFormData[record.id])
          .filter((orderPart) => !orderPart.rejected_by_client)
          .map((orderPart) =>
            calculateOrderPartPrice(Object.values(orderPart.provider_offers)),
          )
          .flat()
      } else {
        batch_prices = record.list_of_orderparts
          .filter((orderPart) => !orderPart.rejected_by_client)
          .map((orderPart) =>
            calculateOrderPartPrice(orderPart.provider_offers),
          )
          .flat()
      }
      return currencyRenderer(t)(batch_prices.reduce((sum, a) => sum + a, 0))
    },
  },
  {
    title: 'Status',
    render: (record) => {
      if (record.client_accepted_offer) {
        return (
          <Tooltip title={t('service.order_part.tooltip.offer_accepted')}>
            <Tag color="success">
              <TruncateText
                text={t('service.order_part.status.offer_accepted')}
              />
            </Tag>
          </Tooltip>
        )
      } else if (record.provider_accepted_offer) {
        const orderPartList = orderPartListsData.find(
          (orderPartList) => orderPartList.id === record.id,
        )
        const allReviewed = orderPartList.list_of_orderparts
          .filter((orderPart) => !orderPart.rejected_by_client)
          .every((orderPart) =>
            orderPartListStatus[record.id]?.includes(orderPart.id),
          )

        const isExpired = orderPartList.list_of_orderparts
          .map(({ status_info }) => status_info)
          .includes('expired')

        return (
          <Tooltip
            title={
              isExpired
                ? t('service.order_part.tooltip.offer_expired')
                : !allReviewed
                  ? t('service.order_part.tooltip.please_review')
                  : t('service.order_part.tooltip.accept_and_order')
            }
          >
            <Button
              type="primary"
              size="small"
              disabled={!allReviewed || isExpired}
              onClick={() => handleAcceptOffer(record.id)}
            >
              <TruncateText text={t('my_orders.accept_and_order')} />
            </Button>
          </Tooltip>
        )
      } else {
        return (
          <Tooltip title={t('service.order_part.tooltip.offer_pending')}>
            <Tag color="warning">
              <TruncateText
                text={t('service.order_part.status.offer_pending')}
              />
            </Tag>
          </Tooltip>
        )
      }
    },
    ellipsis: true,
  },
  {
    title: '',
    render: (record) => {
      return (
        <StyledLink>
          <Tooltip title={t('my_orders.repeat_order')}>
            <RedoOutlined
              onClick={() => openOrderService(record)}
              style={{ fontSize: '16px' }}
            />
          </Tooltip>
        </StyledLink>
      )
    },
  },
]
