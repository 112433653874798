import { PlusOutlined } from '@ant-design/icons'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Modal, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { checkFileSize, fallback } from '$utils'
import {
  evoClient,
  getPartPng,
  getPartStl,
  postStl,
  putPart,
  getPartStlHead,
} from '$api/client'
import Partimage from './Partimage'
import {
  StyledStlViewer,
  StyledUpload,
  ImageContainerFallback,
  ExpandableTableCard,
  ImageContainer,
} from './styledComponents'

const Part3dFileUpload = ({ record }) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'part_3d_file_upload' })

  const queryClient = useQueryClient()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState([])
  const [pngImgUrl, setPngImgUrl] = useState(null)
  const [pngFallbackUrl, setPngFallbackUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingPartsPng, setLoadingPartsPng] = useState({
    isLoading: true,
    loadingText: t('generating_preview_png'),
  })

  const beforeUpload = (file) => checkFileSize(file.size, '2 GB', t)

  const updatePartMutation = useMutation(
    (body) => {
      return evoClient.put(putPart + record.id, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['allgroups'])
        queryClient.invalidateQueries(['allpartspaginatedmarketplace'])
      },
    },
  )

  const postSTLMutation = useMutation(postStl, {
    onSuccess: async () => {
      // Set loading until png appear
      setLoadingPartsPng({
        isLoading: true,
        loadingText: t('generating_preview_png'),
      })

      // Workaround for potentially incomplete PNG generation
      let result = null
      let count = 0
      while (count < 15) {
        await new Promise((resolve) => setTimeout(resolve, 1300))
        try {
          result = await getPartPng(record.id)
        } catch (err) {
          console.error(`Error in getPartPng:`, err)
        }
        if (result) {
          const blob = new Blob([result], { type: 'image/png' })
          const blobUrl = URL.createObjectURL(blob)
          setPngImgUrl(blobUrl)
          setLoadingPartsPng({
            ...loadingPartsPng,
            isLoading: false,
          })
          updatePartMutation.mutate(JSON.stringify(record))
          break
        }
        console.info(`No preview PNG generated, retry ${count + 1}/15`)
        count++
      }

      // no png because stl > 100mb
      setPngFallbackUrl(fallback)
      setLoadingPartsPng({
        ...loadingPartsPng,
        isLoading: false,
      })
    },
    onError: () => {
      setLoadingPartsPng({
        ...loadingPartsPng,
        isLoading: false,
      })
      setFileList([])
    },
  })

  const customUpload = ({ file, onProgress }) =>
    postSTLMutation.mutate({
      partId: record.id,
      file,
      onProgress,
    })

  const deleteStl = () => {
    setPngImgUrl(null)
    setPngFallbackUrl(null)
    updatePartMutation.mutate(JSON.stringify(record))
    setPreviewOpen(false)
  }
  const handleCancel = () => setPreviewOpen(false)
  const handleLoading = () => setLoading(false)
  const handlePreview = async () => {
    const stlblob = await getPartStl(record.id)
    const blob = new Blob([stlblob], { type: 'image/png' })
    const blobUrl = URL.createObjectURL(blob)
    setPreviewImage(blobUrl)
    setPreviewOpen(true)
    setPreviewTitle(record.name)
  }
  const handleChange = ({ fileList: newFileList }) => {
    newFileList.thumbUrl = pngImgUrl
    setFileList(newFileList)
  }

  useEffect(() => {
    async function fetchData() {
      setLoadingPartsPng({
        isLoading: true,
        loadingText: t('check_if_preview_png'),
      })
      try {
        const partPNG = await getPartPng(record.id)
        const blob = new Blob([partPNG], { type: 'image/png' })
        const blobUrl = URL.createObjectURL(blob)
        setPngImgUrl(blobUrl)
        setLoadingPartsPng((prevState) => ({
          ...prevState,
          isLoading: false,
        }))
      } catch (error) {
        // Wenn es kein PNG gibt, prüfe ob das STL hochgeladen wurde
        try {
          await getPartStlHead(record.id)
          setLoadingPartsPng((prevState) => ({
            ...prevState,
            isLoading: false,
          }))
          setPngFallbackUrl(fallback)
        } catch (stlError) {
          setLoadingPartsPng((prevState) => ({
            ...prevState,
            isLoading: false,
          }))
          console.info(stlError.response.status, 'Part has no stl file')
        }

        // Check for 404 status and log errors
        if (error.response && error.response.status === 404) {
          console.info('Part PNG not found (404) from ' + record.id)
        } else {
          console.error('An error occurred:', error)
        }
      }
    }
    fetchData()
  }, [record.id, t])

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('upload_stl')}
      </div>
    </div>
  )

  return (
    <>
      {loadingPartsPng.isLoading ? (
        <ExpandableTableCard>
          <ImageContainerFallback>
            <div className="spinner">
              <Spin />
            </div>
            <div className="overlay2">{loadingPartsPng.loadingText}</div>
          </ImageContainerFallback>
        </ExpandableTableCard>
      ) : pngImgUrl || pngFallbackUrl ? (
        pngImgUrl ? (
          <div>
            <ImageContainer>
              <Partimage
                part_url={pngImgUrl}
                deleteStl={deleteStl}
                handlePreview={handlePreview}
                setFileList={setFileList}
              />
            </ImageContainer>
          </div>
        ) : (
          <ExpandableTableCard>
            <ImageContainerFallback>
              <Partimage
                part_url={pngFallbackUrl}
                deleteStl={deleteStl}
                handlePreview={handlePreview}
                setFileList={setFileList}
              />
              <div className="overlay2">
                <Trans
                  i18nKey="part_image_does_not_exist"
                  components={{ 1: <br /> }}
                />
              </div>
            </ImageContainerFallback>
          </ExpandableTableCard>
        )
      ) : (
        <ExpandableTableCard>
          <StyledUpload
            accept=".stl"
            customRequest={customUpload}
            onChange={handleChange}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            beforeUpload={beforeUpload}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </StyledUpload>
        </ExpandableTableCard>
      )}

      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        width={800}
        centered
        transitionName=""
        destroyOnClose={true}
      >
        <Spin spinning={loading}>
          <StyledStlViewer
            onFinishLoading={handleLoading}
            orbitControls
            style={{
              width: '100%',
              height: '600px',
            }}
            url={previewImage}
            modelProps={{
              scale: 1.3,
              positionX: 70,
              positionY: 70,
            }}
          />
        </Spin>
      </Modal>
    </>
  )
}
export default Part3dFileUpload
