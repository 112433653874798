import styled from 'styled-components'
import { Card } from 'antd'

export const StyledCardContent = styled(Card)`
    margin-top: 15px;
    border: none;
    background-color: white;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
}
`
