import { Spin } from 'antd'
import React from 'react'

const Spinner = () => {
  return (
    <div>
      <Spin />
    </div>
  )
}

export const CenteredSpinner = ({ message }) => {
  return (
    <div
      style={{
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        verticalAlign: 'middle',
        padding: '25% 0',
      }}
    >
      <Spin />
      {message}
    </div>
  )
}

export default Spinner
