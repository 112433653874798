import React from 'react'
import { Image } from 'antd'
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import { ExpandableTableCard, ImageContainer } from './styledComponents'
import { fallback } from '$utils'
import { useTranslation } from 'react-i18next'

const Partimage = (props) => {
  //const src = part_url;//URL.createObjectURL(part_url);
  const { t } = useTranslation()

  const replaceStl = (e) => {
    e.stopPropagation()
    props.deleteStl()
    props.setFileList([])
  }
  return (
    <ExpandableTableCard>
      <ImageContainer>
        <Image
          src={props.part_url}
          fallback={fallback}
          width="100%"
          preview={false}
          //visible={false}
          style={{ cursor: 'pointer' }}
        />
        <div className="overlay">
          <div onClick={replaceStl}>
            <DeleteOutlined /> {t('button.delete')}
          </div>

          <div onClick={props.handlePreview}>
            <EyeOutlined /> {t('Preview')}
          </div>
        </div>
      </ImageContainer>
    </ExpandableTableCard>
  )
}

export default Partimage
