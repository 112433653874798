import { Card } from 'antd'
import styled from 'styled-components'

export const StyledCard = styled(Card)`
    margin: 0 15px 15px 0;
    border: none;
    background-color: white;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);

    .absolutecenter {
        display: flex;
        justify-content: center;
        height: 100%;
        flex-wrap: wrap;
        align-content: center;
    }
}
`
export const StyledContingentCard = styled(Card)`
    margin: 0 15px 15px 0;
    border: none;
    background-color: white;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
    width: 100%;
    .apex-charts {
        margin-top: -60px;
        margin-left: 17px;
    }
    .apexcharts-text {
        font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif!important; 
    }
    @media screen and (max-width: 1313px) {
        .apex-charts {
            margin-top: 0px;
            margin-left: 0px;
        }
    }
}
`
export const StyledOrderstotalCard = styled(Card)`
    margin: 0 15px 15px 0;
    border: none;
    background-color: white;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
    width: 100%;
    .apex-charts {
        margin-top: -10px;
        //width: 400px;
    }
    .apexcharts-text {
        font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif!important; 
    }

}
`

export const StyledOrdersandCustomerstotalCard = styled(Card)`
    margin: 0 15px 15px 0;
    border: none;
    background-color: white;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
    width: 100%;
    .apex-charts {
        margin-top: -50px;
        
    }
    .apexcharts-text {
        font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif!important; 
    }
}
`

export const StyledExecutedServices = styled(Card)`
    margin: 0 15px 15px 0;
    border: none;
    background-color: white;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);

    .apex-charts {
        margin-top: -60px;
        margin-left: 17px;
    }
    .apexcharts-text {
        font-family: Segoe UI,Frutiger,Frutiger Linotype,Dejavu Sans,Helvetica Neue,Arial,sans-serif!important; 
    }
    @media screen and (max-width: 1313px) {
        .apex-charts {
            margin-top: 0px;
            margin-left: 0px;
        }
    }
}
`
export const StyledUserCard = styled(Card)`
    margin: 0 15px 15px 0;
    border: none;
    background-color: white;
    box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
    .ant-card-body{
        padding: initial!important;
    }
}`
