import styled from 'styled-components'
import { Card } from 'antd'

import { StyledTable as GlobalStyledTable } from '$globalStyledComponents/Table'

export const StyledCardContent = styled(Card)`
  margin-top: 15px;
  border: none;
  background-color: white;
  box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
`

export const StyledTable = styled(GlobalStyledTable)`
  tr {
    cursor: default;
  }
  .row-disabled td,
  .expanded-row-disabled td {
    opacity: 0.6;
  }

  .ant-table-tbody {
    ${(props) =>
      props.expandable
        ? `
        > tr.ant-table-row {
          cursor: pointer;
        }
      `
        : `> tr.ant-table-row {
          cursor: default;
        }`}
  }

  .row-disabled td {
    text-decoration: line-through;
  }
`
