import { fetchAllOrderPartsLists } from '$api/evoAPIs'
import Footer from '$components/Footer'
import { useOrderPartsListStatus } from '$components/OrderPartsListStatus'
import { StyledLayout } from '$globalStyledComponents/Layout'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandedRow from './ExpandedRow'
import Topwelcomecard from './Topwelcomecard'
import createColumns from './createColumns'
import { StyledCardContent, StyledTable } from './styledComponents'
import Form from '$components/Form'
import useMaterialMapping from '$utils/useMaterialMapping'
import { useTheme } from 'styled-components'

const { Content } = StyledLayout

const ReceivedRequests = () => {
  const { t } = useTranslation()

  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [formValues, setFormValues] = useState({})
  const theme = useTheme()

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  })
  const [sorting, setSorting] = useState({
    field: 'time_stamp',
    order: -1,
  })
  const [filters, setFilters] = useState({
    status: null,
    order_number: null,
    client_company: null,
  })

  const { determineStatus } = useOrderPartsListStatus()

  const queryFilters = {
    pageNr: pagination.current,
    pageSize: pagination.pageSize,
    sorting,
    filters,
  }

  const {
    data: { items: orderPartLists } = {},
    isLoading: orderPartsListsAreLoading,
  } = useQuery(
    ['allorderedpartslists', queryFilters],
    fetchAllOrderPartsLists,
    {
      keepPreviousData: true,
      onSuccess: ({ page, total }) => {
        setPagination((prev) => ({
          ...prev,
          current: page,
          total,
        }))
      },
    },
  )

  const { data: materialsById, isLoading: materialsAreLoading } =
    useMaterialMapping()

  const handleFormChange = (formName, { forms }) => {
    if (formName) {
      setFormValues({
        ...formValues,
        [formName]: forms[formName].getFieldValue(),
      })
    }
  }

  const deleteProviderOffer = ({ orderPartsListId, orderPartId, offerId }) => {
    setFormValues((prevValues) => {
      const providerOffers =
        prevValues?.[orderPartsListId]?.[orderPartId]?.provider_offers

      if (providerOffers && offerId in providerOffers) {
        const { [offerId]: _offerToDelete, ...otherOffers } = providerOffers
        prevValues[orderPartsListId][orderPartId].provider_offers = otherOffers
      }

      return prevValues
    })
  }

  const deleteRule = ({
    orderPartsListId,
    orderPartId,
    offerId,
    typeOfRules,
    ruleId,
  }) => {
    setFormValues((prevValues) => {
      const ruleTypeKey = {
        price: 'price_rules',
        delivery: 'production_time_rules',
      }[typeOfRules]

      const rules =
        prevValues?.[orderPartsListId]?.[orderPartId]?.provider_offers?.[
          offerId
        ]?.[ruleTypeKey]

      if (rules && ruleId in rules) {
        const { [ruleId]: _ruleToDelete, ...otherRules } = rules

        prevValues[orderPartsListId][orderPartId].provider_offers[offerId][
          ruleTypeKey
        ] = otherRules
      }

      return prevValues
    })
  }

  const onTableRowExpand = (expanded, record) => {
    const keys = []
    if (expanded) {
      keys.push(record.id)
    }
    setExpandedRowKeys(keys)
  }

  // Handle table changes for pagination, sorting, and filtering
  const handleTableChange = (pagination, tableFilters, sorting) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    })

    setSorting({
      field: sorting.field,
      order: sorting.order,
    })

    setFilters((prevFilters) => {
      let orderNumberValue = null
      let clientCompanyValue = null
      if (tableFilters.order_number && tableFilters.order_number.length > 0) {
        const { searchTerm } = tableFilters.order_number[0]
        orderNumberValue = searchTerm
      }
      if (
        tableFilters.client_company &&
        tableFilters.client_company.length > 0
      ) {
        const { searchTerm } = tableFilters.client_company[0]
        clientCompanyValue = searchTerm
      }
      return {
        ...prevFilters,
        order_number: orderNumberValue,
        client_company: clientCompanyValue,
      }
    })
  }

  return (
    <StyledLayout className="layout">
      <Content>
        <h1 style={{ margin: '46px 0' }}>{t('received_requests.title')}</h1>
        <Topwelcomecard />
        <StyledCardContent>
          <Form.Provider onFormChange={handleFormChange}>
            {materialsAreLoading ? (
              t('Loading...')
            ) : (
              <StyledTable
                columns={createColumns(
                  t,
                  materialsById,
                  formValues,
                  determineStatus,
                  filters,
                  theme,
                )}
                loading={orderPartsListsAreLoading && materialsAreLoading}
                dataSource={orderPartLists}
                rowKey={(data) => data.id}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  showSizeChanger: true,
                }}
                onChange={handleTableChange}
                expandable={{
                  showExpandColumn: true,
                  expandRowByClick: true,
                  expandedRowRender: (record) => (
                    <ExpandedRow
                      orderPartsList={record}
                      setOrderPartsFormValues={(orderPartFormValues) =>
                        setFormValues({
                          ...formValues,
                          [record.id]: orderPartFormValues,
                        })
                      }
                      orderPartsFormValues={formValues[record.id]}
                      deleteRule={deleteRule}
                      deleteProviderOffer={deleteProviderOffer}
                    />
                  ),
                  expandedRowKeys: expandedRowKeys,
                  onExpand: onTableRowExpand,
                }}
              />
            )}
          </Form.Provider>
        </StyledCardContent>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default ReceivedRequests
