export const PERCENT = 'percent'
export const CENT = 'cent'

export const euroToCent = (value) => Math.ceil(value * 100)
export const centToEuro = (value) => value / 100

/*
 * basePrice: in cent
 * discount: object as returned by /order_parts_list endpoint
 */
const applyDiscount = (basePrice, discount) => {
  if (!discount) {
    return basePrice
  }

  const { discount_amount, discount_unit } = discount

  if (!(discount_amount && discount_unit)) {
    return basePrice
  }

  if (discount_unit === PERCENT) {
    // always round up to next cent
    return Math.ceil((basePrice * (100 - discount_amount)) / 100)
  } else if (discount_unit === CENT) {
    return basePrice - discount_amount
  }
}

/*
 * basePrice: in cent
 * surcharge: object as returned by /order_parts_list endpoint
 */
const applySurcharge = (basePrice, surcharge) => {
  if (!surcharge) {
    return basePrice
  }

  const { surcharge_amount, surcharge_unit } = surcharge

  if (!(surcharge_amount && surcharge_unit)) {
    return basePrice
  }

  if (surcharge_unit === PERCENT) {
    return Math.ceil((basePrice * (100 + surcharge_amount)) / 100)
  } else if (surcharge_unit === CENT) {
    return basePrice + surcharge_amount
  }
}

export const calculateBatchPrice = (
  {
    amount,
    base_price,
    base_production_time,
    delivery_days,
    price_rules = {},
    production_time_rules = {},
  },
  calculateWithClientValues = true,
) => {
  // if user has not selected delivery time yet
  // or we don't want to base calculation on that choice yet
  // use default value instead
  if (!delivery_days || !calculateWithClientValues) {
    delivery_days = base_production_time
  }

  const discount = Object.values(price_rules)
    .filter((rule) => !!rule)
    // break down to applicable rules
    .filter((rule) => rule.min_parts <= amount)
    // and pick the one with highest min_parts
    .reduce(
      (prev, current) =>
        prev && prev.min_parts > current.min_parts ? prev : current,
      undefined,
    )

  // find applicable rule for selected delivery time
  const surcharge = Object.values(production_time_rules).find(
    (rule) => rule.max_days === delivery_days,
  )

  return amount * applySurcharge(applyDiscount(base_price, discount), surcharge)
}

export const calculateOrderPartPrice = (
  provider_offers,
  calculateWithClientValues = true,
) => {
  return provider_offers
    .map((offer) => calculateBatchPrice(offer, calculateWithClientValues))
    .reduce((sum, a) => sum + a, 0)
}

export const summarizeDeliveryTimes = (times) => {
  // Note: this function returns an object with { count: N }, where N = max-min+1,
  //   for easy use with i18next's interpolation. I.e., N >= 1 if there is an
  //   entry. If there is no time at all, we return N = 0.

  times = times.filter((time) => time)
  if (times.length > 0) {
    const min = Math.min(...times)
    const max = Math.max(...times)
    if (min && max) {
      if (min === max) {
        return {
          count: 1,
          from: min,
          to: max,
        }
      } else {
        return {
          count: max - min + 1,
          from: min,
          to: max,
        }
      }
    }
  }
  return {
    count: 0,
  }
}
