import { fetchLegalTexts } from '$api/evoAPIs'
import { Alert, Divider } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { StyledTable } from '../styledComponents'
import CheckBoxForm from './LegalTextConfirm'
import createColumnsSummary from './createColumnsSummary'
import OrderSummaryHeader from './OrderSummaryHeader'

function StepThreeSummary(props) {
  const [legalTextConfirmed, setLegalTextConfirmed] = useState(false)
  const { t, i18n } = useTranslation()
  const datasource =
    props.bookedServiceStatus &&
    props.bookedServiceStatus[1].servicename ===
      'service.generate_3d_model.name'
      ? [
          ...props.bookableParts.bookableParts,
          ...props.bookableParts.notBookableParts,
        ]
      : props.bookableParts.bookableParts

  const { data: legalText } = useQuery({
    queryKey: ['legalTexts', i18n.language],
    queryFn: fetchLegalTexts,
  })
  return (
    <>
      <h1 style={{ margin: '20px 0px 0px' }}>{t('Order summary')}</h1>
      <OrderSummaryHeader />
      <h4 style={{ paddingTop: '15px' }}>{t('service.order_for')}</h4>
      <StyledTable
        size="middle"
        columns={createColumnsSummary(t)}
        dataSource={datasource}
        rowKey={(part) => part.id}
      />
      <div>
        {/* <h2>Price Summary</h2>
                <Row style={{ marginTop: "18px" }}>
                    <Col flex="auto">Sub Total</Col>
                    <Col flex="90px">{addPrices()}</Col>
                </Row>
                <Row style={{ marginTop: "18px" }}>
                    <Col flex="auto">Shipping</Col>
                    <Col flex="90px">0</Col>
                </Row>
                <Row style={{ marginTop: "18px" }}>
                    <Col flex="auto">Total</Col>
                    <Col flex="90px"><h2>{addPrices()}</h2></Col>
                </Row> */}
      </div>

      <Divider />
      {props.bookedServiceStatus &&
      props.bookedServiceStatus[1].servicename ===
        'service.generate_3d_model.name' &&
      props.currentFormValues.information_quality.includes('delivery') ? (
        <Alert
          message={t('service.generate_3d_model.info_message2')}
          type="info"
          showIcon
          style={{
            backgroundColor: '#d0dfe359',
            border: '1px solid #468b9112',
            marginBottom: '15px',
          }}
        />
      ) : null}
      <CheckBoxForm
        setLegalTextConfirmed={setLegalTextConfirmed}
        legalTextConfirmed={legalTextConfirmed}
        setButtonActive={props.setButtonActive}
        legalText={legalText}
      />
    </>
  )
}

export default StepThreeSummary
