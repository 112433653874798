import React from 'react'
import { Row, Col } from 'antd'
import PartDetailForm from '$components/PartDetailForm'
import Part3dFileUpload from '$components/FileUpload/Part3dFileUpload'
import OrderedServiceResult from './OrderedServiceResult'

const ExpandedRow = ({ record }) => (
  <>
    <Row gutter={[20, 0]}>
      <Col span={6}>
        <Part3dFileUpload record={record} />
      </Col>
      <Col span={18}>
        <PartDetailForm record={record} />
      </Col>
    </Row>
    <OrderedServiceResult record={record} />
  </>
)

export default ExpandedRow
