import { useQuery } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import {
  fetchAllApplyBrandingParts,
  fetchAllApplyTexturingParts,
  fetchAllGenerate3DModelParts,
  fetchAllFCServices,
  fetchAllOrderParts,
  fetchAllOrderPartsLists,
} from '$api/evoAPIs'
import { App } from 'antd'

function useServicesData() {
  const [abData, setAbData] = useState(null)
  const [atData, setAtData] = useState(null)
  const [g3Data, setG3Data] = useState(null)
  const [fcData, setFcData] = useState(null)
  const [opData, setOpData] = useState(null)
  const [oplData, setOplData] = useState(null)
  const [accumulated, setAccumulated] = useState([])
  const [loading, setLoading] = useState(true)
  const { notification } = App.useApp()

  const openErrorNotification = (err) => {
    notification.error({
      message: 'Network Error',
      description: `${err}: There is a server problem`,
      onClick: () => {},
    })
  }

  useQuery({
    queryKey: ['allappliedbranding'],
    queryFn: fetchAllApplyBrandingParts,
    onSuccess: (data) => {
      setAbData(data)
    },
    onError: (err) => {
      setLoading(false)
      if (err.response?.status === 403) {
        window.location.reload()
      } else {
        openErrorNotification(toString(err.response?.status))
      }
    },
  })

  useQuery({
    queryKey: ['allappliedtexturing'],
    queryFn: fetchAllApplyTexturingParts,
    onSuccess: (data) => {
      setAtData(data)
    },
    onError: (err) => {
      if (err.response?.status === 403) {
        window.location.reload()
      } else {
        openErrorNotification(toString(err.response?.status))
      }
    },
  })

  useQuery({
    queryKey: ['allgenerate3DModel'],
    queryFn: fetchAllGenerate3DModelParts,
    onSuccess: (data) => {
      setG3Data(data)
    },
    onError: (err) => {
      setLoading(false)
      if (err.response?.status === 403) {
        window.location.reload()
      } else {
        openErrorNotification(toString(err.response?.status))
      }
    },
  })

  useQuery(['feasibilitycheck'], fetchAllFCServices, {
    onSuccess: (data) => {
      let processedData = []
      for (let i of data) {
        processedData.push({
          ...i,
          service_name: 'Feasibility Check',
        })
      }
      setFcData(processedData)
    },
    onError: (err) => {
      setLoading(false)
      if (err.response?.status === 403) {
        window.location.reload()
      } else {
        openErrorNotification(toString(err.response?.status))
      }
    },
  })

  useQuery({
    queryKey: ['allOrderParts'],
    queryFn: fetchAllOrderParts,
    onSuccess: (data) => {
      setOpData(data)
    },
    onError: (err) => {
      setLoading(false)
      if (err.response?.status === 403) {
        window.location.reload()
      } else {
        openErrorNotification(toString(err.response?.status))
      }
    },
  })

  // Funktion zum Zusammenführen der Order Part und Order Part List
  function mergeObjects(obj1, obj2) {
    let result = []

    obj1.forEach((sourceObj) => {
      sourceObj.list_of_orderparts.forEach((idObj) => {
        let matchingObj = obj2.find((obj) => obj.id === idObj.id)

        if (matchingObj) {
          let mergedObject = { ...sourceObj, ...matchingObj }
          delete mergedObject.list_of_orderparts
          result.push(mergedObject)
        }
      })
    })

    return result
  }

  useQuery({
    queryKey: ['allOrderPartsLists'],
    queryFn: fetchAllOrderPartsLists,
    onSuccess: ({ items }) => {
      setOplData(items)
    },
    onError: (err) => {
      setLoading(false)
      if (err.response?.status === 403) {
        window.location.reload()
      } else {
        openErrorNotification(toString(err.response?.status))
      }
    },
  })

  useEffect(() => {
    if (abData && fcData && atData && g3Data && opData && oplData) {
      setLoading(false)
      let mergedOpData = mergeObjects(oplData, opData)
      setAccumulated([
        ...abData,
        ...atData,
        ...g3Data,
        ...fcData,
        ...mergedOpData,
      ])
    }
  }, [abData, fcData, atData, g3Data, opData, oplData])

  return { servicesData: accumulated, loading }
}

export default useServicesData
