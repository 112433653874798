import check_successful from '$assets/img/check_successful.png'
import { Alert, Collapse } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckCircle, TimesCircle } from '$components/Icons'
import {
  StyledCollapse,
  StyledTable,
} from '../../Defaultwizard/styledComponents'
import createColumnsFeasible from './createColumnsFeasible'
import createColumnsNotFeasible from './createColumnsNotFeasible'
import { machbarkeitsPruefung } from '../../Defaultwizard/StepOneSelection/machbarkeitsPruefung'

const { Panel } = Collapse

const StepOneSelection = (props) => {
  const { servicesData, selectedParts, setBookableParts, setConfigState } =
    props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [defaultActiveKey, setDefaultActiveKey] = useState(['1'])

  const onChange = (key) => {
    setDefaultActiveKey(key)
  }

  useEffect(() => {
    setLoading(true)

    const getMachbarkeit = async () => {
      if (servicesData) {
        const res = await machbarkeitsPruefung(
          selectedParts,
          servicesData.find((x) => x.name === 'Feasibility Check')
            .essential_attributes,
          'Feasibility Check',
          t,
        )
        setBookableParts(res)
        setLoading(false)
      }
    }

    getMachbarkeit()
  }, [servicesData, selectedParts, setBookableParts, t])

  useEffect(() => {
    if (props.bookableParts && props.bookableParts.bookableParts.length === 0) {
      setDefaultActiveKey(['2'])
    }

    const hasNotFeasibleParts =
      props.bookableParts?.notBookableParts?.length > 0
    setConfigState(!hasNotFeasibleParts)
  }, [props.bookableParts, setConfigState])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={check_successful}
          alt="check successful"
          width="200px"
          height="200px"
        />
        <div>
          <h2>{t('service.check_successful')}</h2>
          <p>
            {t('service.part_editable', {
              count: props.bookableParts?.bookableParts?.length,
            })}
          </p>
        </div>
      </div>
      {props.bookableParts?.notBookableParts?.length !== 0 &&
        props.bookableParts && (
          <Alert
            message={t('service.removing_alert_message', {
              count1: props.bookableParts?.notBookableParts?.length,
              count2:
                props.bookableParts?.notBookableParts?.length +
                props.bookableParts?.bookableParts?.length,
            })}
            type="error"
          />
        )}
      <StyledCollapse
        accordion
        activeKey={defaultActiveKey}
        onChange={onChange}
        style={{ margin: '22px 0' }}
      >
        <Panel
          header={
            <>
              <CheckCircle />
              {t('service.processable')}
            </>
          }
          key="1"
        >
          <StyledTable
            loading={loading}
            size="middle"
            columns={createColumnsFeasible(t)}
            dataSource={props.bookableParts?.bookableParts} //feasibleParts()
            rowKey={(part) => part.id}
          />
        </Panel>
        <Panel
          header={
            <>
              <TimesCircle />
              {t('service.processable_after_adjustments')}
            </>
          }
          key="2"
        >
          <StyledTable
            loading={loading}
            size="middle"
            columns={createColumnsNotFeasible(t)}
            dataSource={props.bookableParts?.notBookableParts} //notFeasibleParts()
            rowKey={(part) => part.id}
          />
        </Panel>
      </StyledCollapse>
    </>
  )
}

export default StepOneSelection
