import { Card } from 'antd'
import styled from 'styled-components'

export const LabelText = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin: 0 0 10px 0;
  color: #495057;
`

export const EnviromentName = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 0px;
  color: #495057;
`

export const ExpandableTableCard = styled(Card)`
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: -10px;
  cursor: default;
  background: none;
  border: none;
  max-width: 806px;

  .detailsprice {
    width: 82px;
  }
  .ant-steps-vertical {
    margin-top: -6px;
  }
  .ant-steps-item-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;
    color: #495057;
  }
`
export const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.6s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  :hover .overlay {
    opacity: 1;
  }

  .overlay div {
    color: white;
    text-decoration: none;
    margin: 10px;
    font-size: 1.2em;
    padding: 10px 20px;
  }
  .overlay div:first-child {
    position: absolute;
    bottom: 0;
    :hover {
      color: #495057;
    }
  }

  .overlay div:last-child {
    position: static;
    margin-top: 20px;
  }
`
