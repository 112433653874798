import { notification } from 'antd'
import { useEffect, useRef, useState } from 'react'
import ExpandedRow from './ExpandedRow'
import createColumns from './createColumns'
import { StyledTable, TableHeader } from './styledComponents'
import { useTranslation } from 'react-i18next'

notification.config({
  placement: 'top',
  duration: 5,
})

const DreiDModelConfigTable = (props) => {
  const { t } = useTranslation()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [status, setStatus] = useState([false, false])
  const statusRef = useRef(status)
  const bookablePartsRef = useRef(false)

  useEffect(() => {
    // Update the statusRef when "status" changes
    statusRef.current = status
  }, [status])

  useEffect(() => {
    // Update the statusRef when "props.bookableParts" changes
    bookablePartsRef.current = props.bookableParts
  }, [props.bookableParts])

  useEffect(() => {
    if (statusRef.current[1]) {
      if (status[1]) {
        const newDataSource = [...data]
        for (let i in newDataSource) {
          if (status[1] === newDataSource[i].id)
            newDataSource[i].value = status[0]
        }
        setData(newDataSource)
        // check if all .value are true, then enable "next" button
        if (newDataSource.every((obj) => obj.value === true)) {
          props.setConfigState(true)
        }
      }
      statusRef.current = [false, false]
    }
  }, [status, props, data])

  useEffect(() => {
    if (props.bookableParts) {
      if (bookablePartsRef.current !== false) {
        for (let i in props.bookableParts.bookableParts) {
          props.bookableParts.bookableParts[i].value = status[0]
          props.bookableParts.bookableParts[i].has3dModel = true
        }
        for (let i in props.bookableParts.notBookableParts) {
          props.bookableParts.notBookableParts[i].value = status[0]
          props.bookableParts.notBookableParts[i].has3dModel = false
        }

        setData([
          ...props.bookableParts.bookableParts,
          ...props.bookableParts.notBookableParts,
        ])
        setExpandedRowKeys([
          props.bookableParts.bookableParts?.[0]?.id ??
            props.bookableParts.notBookableParts?.[0]?.id,
        ])

        setLoading(false)
        //propsHasChanged.current = false;
        bookablePartsRef.current = false
      }
    }
  }, [props.bookableParts, status])

  const onTableRowExpand = (expanded, record) => {
    const keys = []
    if (expanded) {
      keys.push(record.id)
    }
    setExpandedRowKeys(keys)
  }

  return (
    <div>
      <TableHeader></TableHeader>
      <StyledTable
        loading={loading}
        columns={createColumns(t, expandedRowKeys, onTableRowExpand)}
        expandable={{
          expandRowByClick: true,
          expandIconAsCell: false,
          expandIconColumnIndex: -1,
          showExpandColumn: true,
          expandedRowRender: (record) => (
            <ExpandedRow
              record={record}
              setStatus={setStatus}
              setCurrentFormValues={props.setCurrentFormValues}
            />
          ),
          rowExpandable: () => true,
        }}
        dataSource={data}
        expandedRowKeys={expandedRowKeys}
        onExpand={onTableRowExpand}
        rowKey={(part) => part.id}
      />
    </div>
  )
}

export default DreiDModelConfigTable
