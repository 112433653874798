import { DatePicker } from 'antd'
import { useTranslation } from 'react-i18next'

const DateRangePicker = (props) => {
  const { RangePicker } = DatePicker

  const { i18n } = useTranslation()

  return (
    <RangePicker
      format={
        {
          de: 'DD.MM.YYYY',
          en: 'MM/DD/YYYY',
        }?.[i18n.language] || 'MM/DD/YYYY'
      }
      {...props}
    />
  )
}

export default DateRangePicker
