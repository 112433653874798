import { Input, Modal } from 'antd'
import styled from 'styled-components'
import { StyledTabs } from '$globalStyledComponents/Tabs'

const { Search } = Input

export const StyledSearch = styled(Search)`
  .ant-input {
    border: 1px solid rgba(73, 80, 87, 0.2);
  }
  .ant-input-lg {
    padding: 9px 11px;
    font-size: 13px;
  }
`
export const StyledModal = styled(Modal)`
  width: 420px !important;
  .ant-modal-title {
    font-size: 18px;
    margin-left: 5px;
    font-weight: 500 !important;
  }
`
export const StyledGroupTabs = styled(StyledTabs)`
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 7px;
  }
  .ant-divider-horizontal {
    margin: 10px 0 21px 0;
  }
  .ant-tabs-tabpane {
    background: ${(props) => props.theme.colorBgLightFour};
  }
  .ant-tabs-tab-remove {
    display: none;
  }
`
