import {
  UilAnalytics,
  UilBrushAlt,
  UilCube,
  UilFileCheck,
  UilNotes,
  UilStore,
  UilTruck,
  UilEstate,
} from '@iconscout/react-unicons'

export const SERVICES = [
  {
    id: '63dcfb10f81b69c6d3a7c431',
    icon: <UilFileCheck size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.feasibility_check.name',
    serviceInfo: 'service.feasibility_check.service_description',
    attributeKeys: ['Feasibility Check', 'fc_Order Part'],
    getStatus: (value, serviceKey) =>
      serviceKey === 'fc_Order Part' ? 1 : Number(value),
  },
  {
    id: 'main',
    icon: <UilEstate size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.prepare_for_digital_warehousing',
    serviceInfo: 'service.prepare_for_digital_warehousing_description',
    subservices: [
      {
        id: '6405eed3cebc2701ca6c4967',
        icon: <UilCube size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
        servicename: 'service.generate_3d_model.name',
        serviceInfo: 'service.generate_3d_model.service_description',
        attributeKeys: ['Generate 3D Model'],
      },
      {
        id: '63dcfb01f81b69c6d3a7c42e',
        icon: (
          <UilBrushAlt size="21" color="#F1B44C" style={{ margin: '-5px' }} />
        ),
        servicename: 'service.apply_texturing.name',
        serviceInfo: 'service.apply_texturing.service_description',
        attributeKeys: ['Apply Texturing'],
      },
      {
        id: '63dcfaf1f81b69c6d3a7c42d',
        icon: <UilStore size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
        servicename: 'service.apply_branding.name',
        serviceInfo: 'service.apply_branding.service_description',
        attributeKeys: ['Apply Branding'],
      },
    ],
  },
  {
    id: '63dcfac9f81b69c6d3a7c42b',
    icon: <UilNotes size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.request_am_offer.name',
    serviceInfo: 'service.request_am_offer.service_description',
    attributeKeys: ['Request AM Offer'],
  },
  {
    id: '63dcfb10f81b69c6d3a7c42f',
    icon: <UilTruck size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.order_part.name',
    serviceInfo: 'service.order_part.service_description',
    attributeKeys: ['Order Part'],
  },
  {
    id: '63dcfb24f81b69c6d3a7c430',
    icon: <UilAnalytics size="21" color="#F1B44C" style={{ margin: '-5px' }} />,
    servicename: 'service.digital_part_report.name',
    serviceInfo: 'service.digital_part_report.service_description',
    attributeKeys: ['Digital Parts Report'],
    demo: true,
  },
]

export const SESSION_EXPIRED = 'session_expired'
export const AUTOMATIC_LOGOUT = 'automatic_logout'
export const LOGOUT_FLAGS = [SESSION_EXPIRED, AUTOMATIC_LOGOUT]

export const REDIRECT_PARAM = 'redirect'
