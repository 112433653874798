import styled from 'styled-components'

export const ServiceStatusButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SmallServiceButton = styled.div`
  margin: -7px;
  width: 33px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: help;

  .clickableservice:hover {
    background: #e7e7e7;
    border-color: ${(props) => props.theme.colorBgLightSix};
    margin: -7px;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
  .clickableservice {
    background: ${(props) => props.theme.colorBgLightSeven};
    border-color: ${(props) => props.theme.colorBgLightSix};
    margin: -7px;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
`
