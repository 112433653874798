import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useRefetchTimer } from '$context/RefetchTimerProvider'
import Spinner from '$components/Spinner'

const StatusPage = (props) => {
  const { t } = useTranslation()

  const { startRefetch } = useRefetchTimer()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const stayAtMarketplace = () => {
    if (localStorage.getItem('notupdatedfc')) {
      startRefetch(
        1000,
        () => {
          queryClient.refetchQueries(['feasibilitycheck'])
        },
        0,
      )
    }
    navigate('/marketplace')
    props.setCurrent('marketplace')
  }

  if (props.loading) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
          verticalAlign: 'middle',
          padding: '13% 0',
        }}
      >
        <Spinner />
        {t('service.please_wait')}
      </div>
    )
  } else {
    return (
      <Result
        status="success"
        title={t('service.success.title')}
        subTitle={t('service.success.description')}
        extra={[
          <Button
            key="myServices"
            type="primary"
            onClick={() => {
              navigate('/myservices')
              props.setCurrent('myservices')
            }}
          >
            {t('My Services')}
          </Button>,
          <Button key="bookOtherServices" onClick={stayAtMarketplace}>
            {t('service.success.book_another')}
          </Button>,
        ]}
      />
    )
  }
}

export default StatusPage
