import { isValidElement } from 'react'
import { Button, InputNumber, Select } from 'antd'
import { UilTrashAlt } from '@iconscout/react-unicons'
import { PlusOutlined } from '@ant-design/icons'
import { dateWithTimeRenderer } from '$utils/i18n'
import Form from '$components/Form'

import { CheckCircle, ExclamationCircle, TimesCircle } from '$components/Icons'
import Tooltip from '$components/Tooltip'

const createColumnsConfig = (
  t,
  orderPartId,
  formData,
  invalidProviderOffers,
  addProviderOffer,
  deleteProviderOffer,
  providerOffersStored,
) => {
  const isTruthy = (value) => {
    // Check if value is a JSX element
    if (isValidElement(value)) {
      return value
    } else {
      return value ? t('Yes') : t('No')
    }
  }
  const columns = [
    {
      title: (
        <Tooltip placement="topLeft" title={t('received_requests.amount')}>
          {t('received_requests.amount')}
        </Tooltip>
      ),
      key: 'amount',
      width: 70,
      render: (record) => {
        return (
          <>
            <Form.Item
              name={[orderPartId, 'provider_offers', record.offer_id, 'amount']}
              hidden={!record.editable}
            >
              <InputNumber min={1} size="small" style={{ width: '54px' }} />
            </Form.Item>
            {!record.editable ? record.amount : null}
          </>
        )
      },
    },
    {
      title: (
        <Tooltip placement="topLeft" title={t('received_requests.partial')}>
          {t('received_requests.partial')}
        </Tooltip>
      ),
      key: 'allow_partial_delivery',
      render: (record) => {
        return (
          <>
            <Form.Item
              name={[
                orderPartId,
                'provider_offers',
                record.offer_id,
                'allow_partial_delivery',
              ]}
              hidden={!record.editable}
            >
              <Select
                size="small"
                options={[
                  {
                    value: true,
                    label: t('yes'),
                  },
                  {
                    value: false,
                    label: t('no'),
                  },
                ]}
              />
            </Form.Item>
            {!record.editable ? isTruthy(record.allow_partial_delivery) : null}
          </>
        )
      },
      width: 65,
    },
    {
      title: (
        <Tooltip placement="topLeft" title={t('received_requests.other')}>
          {t('received_requests.other')}
        </Tooltip>
      ),
      key: 'allow_other_suppliers',
      render: (record) => {
        return (
          <>
            <Form.Item
              name={[
                orderPartId,
                'provider_offers',
                record.offer_id,
                'allow_other_suppliers',
              ]}
              hidden={!record.editable}
            >
              <Select
                size="small"
                options={[
                  {
                    value: true,
                    label: t('yes'),
                  },
                  {
                    value: false,
                    label: t('no'),
                  },
                ]}
              />
            </Form.Item>
            {!record.editable ? isTruthy(record.allow_other_suppliers) : null}
          </>
        )
      },
      width: 65,
    },
    {
      title: (
        <Tooltip placement="topLeft" title={t('received_requests.subcontract')}>
          {t('received_requests.subcontract')}
        </Tooltip>
      ),
      key: 'allow_subcontractors',
      render: (record) => {
        return (
          <>
            <Form.Item
              name={[
                orderPartId,
                'provider_offers',
                record.offer_id,
                'allow_subcontractors',
              ]}
              hidden={!record.editable}
            >
              <Select
                size="small"
                options={[
                  {
                    value: true,
                    label: t('yes'),
                  },
                  {
                    value: false,
                    label: t('no'),
                  },
                ]}
              />
            </Form.Item>
            {!record.editable ? isTruthy(record.allow_subcontractors) : null}
          </>
        )
      },
      width: 65,
    },
    ...(providerOffersStored
      ? []
      : [
          {
            title: (
              <Tooltip
                placement="topLeft"
                title={t('received_requests.desired_delivery_date')}
              >
                {t('received_requests.desired_delivery_date')}
              </Tooltip>
            ),
            dataIndex: 'desired_delivery_date',
            key: 'desired_delivery_date',
            render: (record) => dateWithTimeRenderer(t)(record),
          },
        ]),
    {
      title: t('received_requests.status'),
      render: (record) => {
        const providerOffersFormData = formData?.provider_offers?.[record.key]

        let allPriceRulesValid = true
        let allProductionTimeRulesValid = true

        if (
          providerOffersFormData?.price_rules &&
          providerOffersFormData.price_rules.length > 0
        ) {
          allPriceRulesValid = providerOffersFormData.price_rules.every(
            (rule) =>
              rule.min_parts && rule.discount_amount && rule.discount_unit,
          )
        }

        if (
          providerOffersFormData?.production_time_rules &&
          providerOffersFormData.production_time_rules.length > 0
        ) {
          allProductionTimeRulesValid =
            providerOffersFormData.production_time_rules.every(
              (rule) =>
                rule.max_days && rule.surcharge_amount && rule.surcharge_unit,
            )
        }

        return providerOffersFormData?.base_price &&
          providerOffersFormData?.base_production_time &&
          allPriceRulesValid &&
          allProductionTimeRulesValid ? (
          <CheckCircle />
        ) : (
          <Tooltip title={t('received_requests.specify_offer')}>
            {invalidProviderOffers.includes(record.key) ? (
              <TimesCircle />
            ) : (
              <ExclamationCircle />
            )}
          </Tooltip>
        )
      },
    },
    {
      title: (
        <Button size="small" onClick={addProviderOffer}>
          <PlusOutlined />
        </Button>
      ),
      render: (record) => (
        <Button
          size="small"
          type="secondary"
          onClick={(event) => {
            event.stopPropagation()
            deleteProviderOffer(record.offer_id)
          }}
        >
          <UilTrashAlt size="17" color="#495057" />
        </Button>
      ),
    },
  ]

  return columns
}

export default createColumnsConfig
