import { useState } from 'react'
import { Button, Divider, Flex, Modal } from 'antd'
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import {
  StyledBookCardTop,
  StyledBookCardBottom,
  StyledRibbon,
  StyledRoundButton,
  StyledServicecard,
  StyleBookButton,
} from './styledComponents'
import useServiceKPI from '$utils/useServiceKpis'

const ServiceButton = ({
  service,
  demoflag = false,
  buttonToggle,
  disabled,
  isRowSelected,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [modalContent, setModalContent] = useState({})
  const kpiData = useServiceKPI(service)
  const { t } = useTranslation()

  const stopProp = (e) => {
    e.stopPropagation() // Prevent event propagation to the outer div
  }

  const showModal = () => {
    setModalContent(service)
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      {demoflag && !disabled && (
        <StyledRibbon>
          <div className="ribbon ribbon-top-left">
            <span>demo</span>
          </div>
        </StyledRibbon>
      )}

      <StyledServicecard
        disabled={disabled}
        $isMetaservice={service.subservices?.length > 0}
      >
        <StyledBookCardTop>
          <StyledRoundButton
            disabled={disabled}
            size="large"
            type="primary"
            shape="circle"
            icon={service.icon}
            onClick={stopProp}
          />
          <div>
            <h3
              style={
                disabled
                  ? { margin: '0 0 6px 0', color: 'rgba(0, 0, 0, 0.25)' }
                  : { margin: '0 0 6px 0' }
              }
            >
              {t(service.servicename)}

              <InfoCircleOutlined className="info_icon" onClick={showModal} />
            </h3>
            <div style={disabled && { color: 'rgba(0, 0, 0, 0.25)' }}>
              {kpiData}
            </div>
          </div>
        </StyledBookCardTop>
        <Divider style={{ margin: '16px 0' }} />
        <StyledBookCardBottom>
          {service.subservices ? (
            <Flex justify="flex-end" gap="16px" style={{ width: '100%' }}>
              {service.subservices.map((subservice) => (
                <StyleBookButton
                  onClick={(e) => {
                    e.stopPropagation()
                    if (!disabled) {
                      buttonToggle(subservice)
                    }
                  }}
                  key={subservice.id}
                  type="dashed"
                  size="small"
                  disabled={disabled}
                  style={!isRowSelected ? { opacity: 0.5 } : {}}
                  block
                >
                  <span className="shortened-text">
                    {t(subservice.servicename)}
                  </span>
                  <ArrowRightOutlined />
                </StyleBookButton>
              ))}
            </Flex>
          ) : (
            <Button
              type="dashed"
              size="small"
              disabled={disabled}
              style={!isRowSelected ? { opacity: 0.5 } : {}}
              onClick={(e) => {
                e.stopPropagation()
                if (!disabled) {
                  buttonToggle(service)
                }
              }}
            >
              {t('Book service')} <ArrowRightOutlined />
            </Button>
          )}
        </StyledBookCardBottom>
      </StyledServicecard>

      <Modal
        title={t(modalContent.servicename)}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <p>{t(modalContent.serviceInfo)}</p>

        {modalContent.subservices && (
          <div>
            <ul style={{ listStyle: 'none', padding: 0 }}>
              {modalContent.subservices.map((subservice) => (
                <li key={subservice.id} style={{ marginBottom: '16px' }}>
                  <b>{t(subservice.servicename)}:</b>{' '}
                  {t(subservice.serviceInfo)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </Modal>
    </>
  )
}

export default ServiceButton
