import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { fetchAllGroups } from '$api/client'

import { StyledGroupTabs } from './styledComponents'

const ALL = 'ALL'

const PartsListsTabs = ({
  groupId,
  setGroupId = () => {},
  setGroup = () => {},
}) => {
  const { t } = useTranslation()

  const [innerGroupId, setInnerGroupId] = useState(groupId || ALL)

  const allPartsTab = {
    key: ALL,
    label: t('All Parts'),
  }
  const tabFromGroup = (group) => ({
    label: group.list_name,
    key: group.id,
  })

  const { data: groups = [] } = useQuery({
    queryKey: ['allgroups'],
    queryFn: fetchAllGroups,
  })
  const groupsById = useMemo(
    () => groups.reduce((obj, group) => ({ [group.id]: group, ...obj }), {}),
    [groups],
  )

  return (
    <StyledGroupTabs
      animated={true}
      hideAdd={true}
      type="card"
      items={[allPartsTab, ...groups.map(tabFromGroup)]}
      onChange={(key) => {
        setInnerGroupId(key)
        setGroupId(key === ALL ? undefined : key)
        setGroup(key === ALL ? undefined : groupsById[key])
      }}
      activeKey={innerGroupId}
    />
  )
}

export default PartsListsTabs
