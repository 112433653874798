import { Tag } from 'antd'

export const keycloakToHumanreadable = {
  serviceuser_superadmin: 'Superadmin',
  serviceuser_admin: 'Admin',
  serviceuser_user: 'User',
  // serviceprovider_superadmin: 'Administrator',
}

// reverse mapping
const humanReadableToKeycloak = Object.entries(keycloakToHumanreadable).reduce(
  (ret, entry) => {
    const [key, value] = entry
    ret[value] = key
    return ret
  },
  {},
)

export const convertRoleNames = (name) => {
  return keycloakToHumanreadable?.[name]
}

export const convertRoleNamesBack = (name) => {
  return humanReadableToKeycloak?.[name]
}

export const extractServiceUserRole = (roles) => {
  return (
    roles?.find((x) => x.name === 'serviceuser_superadmin')?.name ||
    roles?.find((x) => x.name === 'serviceuser_admin')?.name ||
    roles?.find((x) => x.name === 'serviceuser_user')?.name ||
    null
  )
}

export const getRoleColorByName = (roleName) => {
  const colors = {
    serviceuser_superadmin: 'volcano',
    serviceuser_admin: 'geekblue',
    serviceuser_user: 'green',
  }

  return (
    <Tag color={colors?.[roleName]}>
      {convertRoleNames(roleName)?.toUpperCase() || '-'}
    </Tag>
  )
}
