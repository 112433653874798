import { isDemoError } from '$utils/demo'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as OriginalQueryClientProvider,
} from '@tanstack/react-query'
import { StyledDemoModal } from '$globalStyledComponents/Modal'
import Layout from '$components/Layout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const QueryClientProvider = ({ children }) => {
  const { t } = useTranslation()

  const handleDemoError = (error) => {
    if (isDemoError(error)) {
      const message = error?.response?.data?.detail
      showDemoNotice(message || t('demo.note'))
    }
  }

  // create stable instance of queryClient
  const [queryClient] = useState(
    new QueryClient({
      mutationCache: new MutationCache({
        onError: (error) => {
          handleDemoError(error)
        },
      }),
      queryCache: new QueryCache({
        onError: (error) => {
          handleDemoError(error)
        },
      }),
    }),
  )

  const [demoMessage, setDemoMessage] = useState(false)
  const showDemoNotice = (text) => {
    setDemoMessage(text)
  }
  const dismissDemoNotice = () => {
    setDemoMessage(false)
  }

  return (
    <OriginalQueryClientProvider client={queryClient}>
      {children}
      <StyledDemoModal
        open={!!demoMessage}
        centered
        onCancel={dismissDemoNotice}
        onOk={dismissDemoNotice}
        cancelButtonProps={{ style: { display: 'none' } }}
        title={<Layout message={t('demo.title')} visible={true} />}
      >
        <p>{demoMessage}</p>
      </StyledDemoModal>
    </OriginalQueryClientProvider>
  )
}

export default QueryClientProvider
