import check_successful from '$assets/img/check_successful.png'
import { Alert } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import createColumns from './createColumns'
import { StyledTable } from './styledComponents'
import { machbarkeitsPruefung } from '../../Defaultwizard/StepOneSelection/machbarkeitsPruefung'

const StepOne3DModelOverview = (props) => {
  const { servicesData, selectedParts, setBookableParts, setHas3DModel } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [bookedData, setBookedData] = useState()

  useEffect(() => {
    setLoading(true)

    const getMachbarkeit = async () => {
      if (servicesData) {
        const res = await machbarkeitsPruefung(
          selectedParts,
          servicesData.find((x) => x.name === 'Generate 3D Model')
            .essential_attributes,
          'Generate 3D Model',
          t,
        )
        setBookableParts(res)
        setLoading(false)
      }
    }

    getMachbarkeit()
  }, [servicesData, selectedParts, setBookableParts, t])

  useEffect(() => {
    // add an attribute to table data "has3dmodel"
    if (
      Array.isArray(props.bookableParts?.bookableParts) &&
      props.bookableParts?.bookableParts.length > 0
    ) {
      const arr1 = props.bookableParts?.bookableParts
      const arr2 = props.selectedParts
      const result = arr2.map((obj) => ({
        ...obj,
        has3dmodel: arr1.some((item) => item.id === obj.id),
      }))
      setHas3DModel(true)
      setBookedData(result)
    } else if (
      Array.isArray(props.bookableParts?.notBookableParts) &&
      props.bookableParts?.notBookableParts.length > 0
    ) {
      const arr2 = props.selectedParts
      const result = arr2.map((obj) => ({
        ...obj,
        has3dmodel: false,
      }))
      setBookedData(result)
    }
  }, [props.bookableParts, props.selectedParts, setHas3DModel])

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: '40px',
        }}
      >
        <img
          src={check_successful}
          alt="check successful"
          width="200px"
          height="200px"
        />
        <div>
          <h2>{t('service.check_successful')}</h2>
          <p>
            {t('service.part_generate3dmodel_editable', {
              count: props.bookableParts?.bookableParts?.length,
            })}
          </p>
        </div>
      </div>
      <Alert
        message={t('service.generate_3d_model.info_message', {
          count1: props.bookableParts?.notBookableParts?.length,
          count2:
            props.bookableParts?.notBookableParts?.length +
            props.bookableParts?.bookableParts?.length,
        })}
        type="info"
        showIcon
        style={{ backgroundColor: '#d0dfe359', border: '1px solid #468b9112' }}
      />
      <p></p>
      <StyledTable
        loading={loading}
        size="middle"
        columns={createColumns(t)}
        dataSource={bookedData}
        rowKey={(part) => part.id}
      />
    </>
  )
}

export default StepOne3DModelOverview
