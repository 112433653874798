import { App, ConfigProvider } from 'antd'
import deDE from 'antd/es/locale/de_DE'
import enUS from 'antd/es/locale/en_US'
import { useTranslation } from 'react-i18next'

const AntdConfigProvider = ({
  children,
  defaultTheme,
  clientTheme,
  ...rest
}) => {
  const { i18n } = useTranslation()

  return (
    <ConfigProvider
      theme={{
        token: {
          ...defaultTheme.token,
          ...clientTheme?.token,
        },
        components: {
          ...defaultTheme.components,
          ...clientTheme?.components,
        },
      }}
      locale={
        {
          de: deDE,
          en: enUS,
        }?.[i18n.language] || enUS
      }
      {...rest}
    >
      <App>{children}</App>
    </ConfigProvider>
  )
}

export default AntdConfigProvider
