import styled from 'styled-components'
import { Steps, Table } from 'antd'
import Form from '$components/Form'

export const StyledPeriods = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledDiscount = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  :hover {
    background: #f5f5f5;
  }
`

export const StyledAddress = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  gap: 20px;
`

export const StyledLink = styled.span`
  cursor: pointer;
`

export const StyledSteps = styled(Steps)`
  [class^='ant-steps'] {
    width: 150px;
  }
`
export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }

  tr {
    background-color: white;
  }

  .ant-table-thead > tr > th:nth-child(2),
  .ant-table-tbody > tr > td:nth-child(2),
  .ant-table-thead > tr > th:nth-child(3),
  .ant-table-tbody > tr > td:nth-child(3),
  .ant-table-thead > tr > th:nth-child(4),
  .ant-table-tbody > tr > td:nth-child(4),
  .ant-table-thead > tr > th:nth-child(5),
  .ant-table-tbody > tr > td:nth-child(5) {
    max-width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .ant-table-thead > tr > th:last-child,
  .ant-table-tbody > tr > td:last-child {
    text-align: center;
  }
  .ant-table-tbody > tr {
    cursor: ${(props) => (props.pointerCursor ? 'pointer' : 'default')};
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item .ant-form-item-control-input {
    min-height: auto;
  }
  .selected-row {
    background-color: #f5f7f7;
  }

  .invalid {
    color: ${(props) => props.theme.colorError};
  }
`
export const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-list .ant-list-header {
    padding-block: 0px;
  }
  .ant-input-number-group .ant-input-number-group-addon {
    padding: 0 5px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-inline-end: 10px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 6px;
  }
  .ant-form-item .ant-form-item-label > label:after {
    content: ' ';
  }
  .no-required-asterisk
    .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }
`
