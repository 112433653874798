import { useState, useEffect } from 'react'
import { Space, Button, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { StyledTable } from './styledComponents'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: 'userlistnamecol',
  },
  {
    title: 'Type',
    key: 'type',
    render: (record) => <Tag>{record?.type}</Tag>,
  },
  {
    title: 'Action',
    key: 'action',
    render: () => {
      return (
        <Space size="middle">
          <button href="#">Edit</button>
        </Space>
      )
    },
  },
]

const tData = (data) =>
  Array.isArray(data) && data?.map((el, i) => ({ key: i, ...el }))

const Companylist = (props) => {
  const [data, setData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [setSelectedRow] = useState(undefined)
  const count = 3
  const queryClient = useQueryClient()

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedRow(record)
        setSelectedRowKeys([record.key])
        if (record.id) {
          props.setCompanyID(record.id)
        } else {
          queryClient.invalidateQueries(['companyuser'])
        }
      },
    }
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const addCompany = () => {
    props.setIsNewCompany(true)
    const newData = {
      key: count,
      name: '',
      type: '',
      id: '',
    }
    setData([...data, newData])
  }

  useEffect(() => {
    // checks if users company is main or sub
    if (props.userCompany[0] !== '') {
      if (props.userCompany[1] === true) {
        for (let i of props.userCompany[0].subGroups) {
          i.type = 'SUB'
        }
        setData([
          {
            key: '0',
            name: props.userCompany[0].name,
            type: 'MAIN',
          },
          ...props.userCompany[0].subGroups,
        ])
      } else {
        props.userCompany[0].type = 'SUB'
        setData([
          {
            key: '0',
            name: props.userCompany[0].name,
            type: 'SUB',
            id: props.userCompany[0].id,
          },
        ])
        // }
      }
    }
  }, [props.company, props.userCompany])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>Companies</h3>
        <Button
          type="primary"
          onClick={addCompany}
          disabled={
            props.isNewCompany ||
            !props.userCompany[1] ||
            (props.userCompany && !props.userCompany[0]?.access?.manage)
          }
        >
          <PlusOutlined />
        </Button>
      </div>
      <br />
      <StyledTable
        columns={columns}
        dataSource={tData(data)}
        rowSelection={rowSelection}
        onRow={onRow}
        rowKey={(record) => record.key}
      />
    </>
  )
}

export default Companylist
