import { fetchAllBookedServicesQueries } from '$api/evoAPIs'
import Orderstotal from '$components/Orderstotal'
import ServicesCompleted from '$components/ServicesCompleted'
import { useQuery } from '@tanstack/react-query'
import { Col, Row } from 'antd'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ExpandedRow from './ExpandedRow'
import createColumns from './createColumns'
import { StyledTable, TableHeader } from './styledComponents'

const PAGE_SIZE = 10

const Myservicestable = ({ currentTab }) => {
  const { t } = useTranslation()
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const tableWrapperRef = useRef(null)
  const [pageNr, setPageNr] = useState(1)
  const [total, setTotal] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [sorting, setSorting] = useState({
    key: 'time_stamp',
    order: -1,
  })

  const convertToMachineServiceName = (serviceName) => {
    if (serviceName === 'All Services') return null
    if (serviceName === 'Feasibility Check') return 'feasibility_check'
    if (serviceName === 'Generate 3D Model') return 'generate_3d_model'
    if (serviceName === 'Apply Branding') return 'apply_branding'
    if (serviceName === 'Apply Texturing') return 'apply_texturing'
    if (serviceName === 'Digital Parts Report') return 'digital_parts_report'
    else return null
  }
  const currentService = convertToMachineServiceName(currentTab)

  const { data: orders, isLoading: loading } = useQuery({
    queryKey: [
      'allBookedServices',
      {
        pageNr,
        pageSize,
        sorting,
        currentService,
      },
    ],
    queryFn: fetchAllBookedServicesQueries,
    placeholderData: { items: [] },
    onSuccess: ({ total }) => {
      setTotal(total)
    },
    retry: 0,
  })

  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.id])
    } else {
      setExpandedRowKeys(
        expandedRowKeys.filter((rowKey) => rowKey !== record.id),
      )
    }
  }

  const onChange = (_pagination, _tableFilters, sorter, { action }) => {
    if (action === 'sort') {
      setSorting({
        key:
          sorter.field ||
          sorter.column?.field ||
          sorter.column?.title ||
          undefined,
        order: sorter.order === 'ascend' ? 1 : -1,
      })
      setPageNr(1)
    }
  }

  return (
    <Row className="myservicescontent">
      <Col flex="440px">
        <div className="myserviceskpi">
          <Orderstotal tab={currentTab} />
          <ServicesCompleted
            tab={currentTab}
            onRowClick={(orderId) => {
              // expand if not yet expanded
              if (!expandedRowKeys.includes(orderId)) {
                setExpandedRowKeys([...expandedRowKeys, orderId])
              }
              // if necessary navigate to items page
              const orderIndex = orders.items.findIndex(
                (order) => order.id === orderId,
              )
              const currentPageMinIndex = currentPage * PAGE_SIZE - PAGE_SIZE
              const currentPageMaxIndex = PAGE_SIZE - 1
              if (
                orderIndex < currentPageMinIndex ||
                orderIndex > currentPageMaxIndex
              ) {
                setCurrentPage(Math.floor(orderIndex / PAGE_SIZE) + 1)
              }
              // always scroll item into view
              const scrollToTableItem = (key) => {
                const tableItem = tableWrapperRef.current.querySelector(
                  `tr[data-row-key="${key}"]`,
                )
                if (tableItem) {
                  tableItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  })
                }
                return false
              }
              // attempt a few times until table is rendered fully...
              let attempts = 0
              if (!scrollToTableItem() && attempts < 10) {
                ++attempts
                setTimeout(() => scrollToTableItem(orderId), 50)
              }
            }}
          />
        </div>
      </Col>
      <Col flex="880px">
        <div className="servicestablecard" ref={tableWrapperRef}>
          <TableHeader></TableHeader>
          <StyledTable
            loading={loading}
            columns={createColumns(t)}
            expandable={{
              showExpandColumn: false,
              expandedRowRender: (record) => <ExpandedRow record={record} />,
              rowExpandable: () => true,
              expandedRowKeys: expandedRowKeys,
              onExpand,
              expandRowByClick: true,
            }}
            rowKey={(record) => record.id}
            dataSource={orders?.items || []}
            pagination={{
              total: total,
              onChange: setPageNr,
              onShowSizeChange: (_, size) => {
                setPageSize(size)
              },
              showSizeChanger: true,
              pageSize: pageSize,
              align: 'start',
            }}
            onChange={onChange}
          />
        </div>
      </Col>
    </Row>
  )
}

export default Myservicestable
