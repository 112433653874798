import { Row } from 'antd'
import { useTranslation } from 'react-i18next'
import OfferAdditionalContent from './OfferAdditionalContent'
import OrderedPartsTable from './OrderedPartsTable'

const ExpandedRow = ({
  orderPartsList,
  orderPartFormData,
  resetOrderPartFormData,
  orderPartListStatus,
  editable,
  showProviderOffers,
  showRejectedProviderOffers,
  showOfferTerms,
  additionalOrderPartsTableColumns = [],
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <h4 style={{ padding: '15px 0' }}>{t('my_orders.ordered_parts')}</h4>
        <OrderedPartsTable
          orderPartsList={orderPartsList}
          orderPartFormData={orderPartFormData}
          resetOrderPartFormData={resetOrderPartFormData}
          orderPartListStatus={orderPartListStatus}
          editable={editable}
          showProviderOffers={showProviderOffers}
          showRejectedProviderOffers={showRejectedProviderOffers}
          showOfferTerms={showOfferTerms}
          additionalColumns={additionalOrderPartsTableColumns}
        />
        <div style={{ padding: '0 15px', width: '100%' }}>
          <OfferAdditionalContent
            orderPartsList={orderPartsList}
            editable={false}
          />
        </div>
      </Row>
    </>
  )
}

export default ExpandedRow
