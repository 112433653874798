import { Dropdown, Menu, Select } from 'antd'
import styled from 'styled-components'
import { useColorByHeaderBackground } from '$utils/useColorByHeaderBackground'

export const StyledHorizontalMenue = styled(Menu)`
  flex: auto;
  min-width: 0;
  justify-content: center;
  padding: 0 20px;
  height: 68px;
  border: none;
  background: unset;
  .ant-menu-overflow-item {
    display: flex;
    align-items: center;
  }
`
export const StyledDropdownMenue = styled(Dropdown)`
  .ant-dropdown {
    text-align: right;
    z-index: 0;
  }
  .ant-dropdown-menu {
    border-radius: 50px;
  }
  z-index: 0 !important;
  text-align: right;
`
export const StyledSelect = styled(Select)`
  .ant-select-selector {
    color: ${({ theme }) => useColorByHeaderBackground(theme)};
  }
`
