import { useTranslation } from 'react-i18next'
import Form from '$components/Form'

import createColumnsConfig from './createColumnsConfig'
import { StyledTable } from './styledComponents'

const ProviderOffersList = ({
  orderPartId,
  providerOffers,
  setProviderOffers,
  selectedKey,
  setSelectedKey,
  providerOffersStored,
  invalidProviderOffers,
  deleteProviderOffer: deleteProviderOfferFromCalculation,
}) => {
  const { t } = useTranslation()

  // useWatch does not support dynamic namePath so watch whole form
  const formData = Form.useWatch()
  // and then select relevant data for this component
  const providerOfferFormData = formData?.[orderPartId]

  const onRow = (record) => {
    return {
      onClick: () => {
        setSelectedKey(record.key)
      },
    }
  }

  const addProviderOffer = () => {
    const newUUID = crypto.randomUUID()
    const newData = {
      key: newUUID,
      offer_id: newUUID,
      amount: 1,
      editable: true,
    }
    setProviderOffers([...providerOffers, newData])
    setSelectedKey(newUUID)
  }

  const deleteProviderOffer = (keyToDelete) => {
    const newData = providerOffers.filter((item) => item.key !== keyToDelete)
    setProviderOffers(newData)

    deleteProviderOfferFromCalculation({ orderPartId, offerId: keyToDelete })

    if (keyToDelete === selectedKey) {
      setSelectedKey(null)
    }
  }

  return (
    <>
      <h4>
        {providerOffersStored
          ? t('received_requests.provider_offers_title')
          : t('received_requests.suborders_title')}
      </h4>
      <br />
      <StyledTable
        pointerCursor={true}
        columns={createColumnsConfig(
          t,
          orderPartId,
          providerOfferFormData,
          invalidProviderOffers,
          addProviderOffer,
          deleteProviderOffer,
          providerOffersStored,
        )}
        dataSource={providerOffers}
        size="small"
        rowClassName={(record, index) => {
          const rowClassNames = []
          if (index === selectedKey) {
            rowClassNames.push('selected-row')
          }
          if (invalidProviderOffers.includes(record.key)) {
            rowClassNames.push('invalid')
          }

          return rowClassNames.join(' ')
        }}
        onRow={onRow}
      />
    </>
  )
}

export default ProviderOffersList
