import styled from 'styled-components'
import { Button, Table, Tree, Row } from 'antd'

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colorBgLightOne};
  border-color: ${(props) => props.theme.colorBgLightSix};
`

export const GroupedDiv = styled.div`
  width: 65%;
  background: ${(props) => props.theme.colorPrimaryBg};
  padding: 15px 38px;
  margin-top: 55px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
`
export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    //margin-top: -6px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }
  tr {
    background-color: white;
    //cursor: pointer;
  }
  .ant-table-selection-column {
    display: none;
  }
  .ant-table-tbody {
    cursor: pointer;
  }
  .ant-table-row-selected {
    background-color: ${(props) => props.theme.colorPrimaryBgHover};
  }
  .ant-table-selection-col {
    width: unset;
  }
`
export const StyledTableDWHM = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    //margin-top: -6px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }
  tr {
    background-color: white;
    //cursor: pointer;
  }
  .ant-table-tbody {
    cursor: pointer;
  }
  .ant-table-row-selected {
    background-color: #e6f7ff;
  }
  .ant-table-selection-col {
    width: unset;
  }
`
export const StyledSubTableDWHM = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    //margin-top: -6px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }
  .ant-table {
    margin-inline: unset !important;
  }
  .ant-table-container::before {
    width: 0px;
  }

  tr {
    background-color: white;
    //cursor: pointer;
  }

  .ant-table-tbody {
    cursor: pointer;
  }
  .ant-table-row-selected {
    background-color: #e6f7ff;
  }
  .ant-table-selection-col {
    width: unset;
  }
`
export const StyledTree = styled(Tree)`
  .ant-tree-treenode {
    height: 55px;
    width: 100%;
    border-bottom: 6px solid ${(props) => props.theme.colorBgLightOne};
    //border-bottom: 1px solid #f0f0f0;
    align-items: center;
  }
  .ant-tree-node-content-wrapper {
    margin-top: 2px !important;
    padding: 2px 8px !important;
  }
  .ant-tree-switcher {
    margin: 13px 13px 0 13px;
  }
  .ant-tree-checkbox {
    margin-inline-end: 14px;
  }
`
export const StyledTreeNode = styled.div`
  display: flex;
  justify-content: space-between;
  width: 210px;
`
export const StyledTreeSubNode = styled.div`
  display: flex;
  justify-content: space-between;
  width: 185px;
`
export const StyledRow = styled(Row)`
  .ant-form-item-row {
    justify-content: space-around;
  }
`
export const StyledRow2 = styled(Row)`
  .currentclass {
    margin-left: -7px;
  }
`
