import { List } from 'antd'
import {
  UilLayersAlt,
  UilWrench,
  UilFire,
  UilSun,
  UilWater,
} from '@iconscout/react-unicons'

const MaterialInfo = (props) => {
  const materialpropertydata = [
    {
      title: 'Material Property 1',
      icon: <UilWrench />,
    },
    {
      title: 'Material Property 2',
      icon: <UilFire />,
    },
    {
      title: 'Material Property 3',
      icon: <UilSun />,
    },
    {
      title: 'Material Property 4',
      icon: <UilWater />,
    },
  ]

  return (
    <>
      {props.selectedDWHMaterialKeys.length !== 0 ? (
        <>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                background: 'rgb(173 181 189 / 33%)',
                borderRadius: '50%',
                height: '58px',
                width: '58px',
              }}
            >
              <UilLayersAlt
                style={{ height: '37px', width: '37px', margin: '17%' }}
              />
            </div>
            <div style={{ marginLeft: '25px' }}>
              <h3>{props.selectedDWHMaterialKeys}</h3>
              <p>A placeholder material description </p>
            </div>
          </div>
          <div>
            <h3>Material</h3>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua. At vero eos et accusam et justo duo
              dolores et ea rebum.{' '}
            </p>
          </div>
          <div>
            <h3>Material Details</h3>
            <List
              itemLayout="horizontal"
              dataSource={materialpropertydata}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={item.icon}
                    title={item.title}
                    description="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod."
                  />
                </List.Item>
              )}
            />
          </div>
        </>
      ) : (
        <>
          <h3>-</h3>
          <p>
            Please click on a DWH Material name to get some corresponding
            informations
          </p>
        </>
      )}
    </>
  )
}

export default MaterialInfo
