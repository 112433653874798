import { DatePicker as AntdDatePicker } from 'antd'
import { useTranslation } from 'react-i18next'

const DatePicker = (props) => {
  const { i18n } = useTranslation()

  return (
    <AntdDatePicker
      format={
        {
          de: 'DD.MM.YYYY',
          en: 'MM/DD/YYYY',
        }?.[i18n.language] || 'MM/DD/YYYY'
      }
      {...props}
    />
  )
}

export default DatePicker
