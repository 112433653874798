import { createContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Flex, Tag, Tour } from 'antd'
import { useTranslation } from 'react-i18next'

import { useUser } from '$context/user'
import SvgImage from './SVGImage'

export const TourContext = createContext()

const getTopnavItemSelector = (key) =>
  `[data-menu-id^="rc-menu-uuid-"][data-menu-id$="-${key}"]`

const GuidedTour = ({ children }) => {
  const { t } = useTranslation('tour')
  const navigate = useNavigate()
  const location = useLocation()
  const viewedTour = localStorage.getItem('hasViewedTour')
  const { isServiceUser } = useUser()

  const [currentStep, setCurrentStep] = useState(0)
  const [isOpen, setIsOpen] = useState()

  const happensOnPage = (url) => {
    if (location.pathname !== url) {
      navigate(url)
    }
  }

  const introStep = {
    title: <h2>{t('welcome_to_dwh')}</h2>,
    description: (
      <Flex justify="space-between">
        <div>
          <p>{t('small_tour_intro')}</p>
          <p>{t('current_location_marketplace')}</p>
          <p>{t('order_parts_services')}</p>
        </div>
        <SvgImage />
      </Flex>
    ),
    action: () => happensOnPage('/marketplace'),
    target: () => document.querySelector(getTopnavItemSelector('marketplace')),
  }

  const outroStep = {
    title: <h3>{t('end')}</h3>,
    description: (
      <div style={{ textAlign: 'center' }}>
        <SvgImage />
        <p>{t('enjoy_your_dwh')}</p>
      </div>
    ),
    action: () => happensOnPage('/myorders'),
  }

  const interactiveSteps = [
    {
      title: t('your_parts'),
      description: <p>{t('select_parts_to_book')}</p>,
      action: () => {
        happensOnPage('/marketplace')

        const checkbox = document.querySelector(
          '.contentcard .ant-table-row.ant-table-row-level-0 .ant-checkbox-input',
        )
        if (checkbox) {
          checkbox.click()
        } else {
          console.error('Checkbox not found')
        }
      },
      target: () => document.querySelector('.contentcard'),
    },
    {
      title: t('services'),
      description: <p>{t('book_services_with_selected_parts')}</p>,
      action: () => happensOnPage('/marketplace'),
      target: () => document.querySelector('#contextmenu'),
    },
    {
      title: t('parts_master_data'),
      description: <p>{t('manage_parts_page')}</p>,
      action: () => happensOnPage('/partsmasterdata'),
      target: () =>
        document.querySelector(getTopnavItemSelector('partsmasterdata')),
    },
    {
      title: t('import_parts'),
      description: <p>{t('import_parts_instructions')}</p>,
      action: () => happensOnPage('/partsmasterdata'),
      target: () => document.querySelector('#importPartsContainer'),
    },
    {
      title: t('part_detail_view'),
      description: <p>{t('part_details_editing')}</p>,
      action: () => {
        happensOnPage('/partsmasterdata')
        // query for first row of the active tab
        const rowElement = document.querySelector(
          '.partsmasterdatatablecard .ant-tabs-tabpane-active .ant-table-row.ant-table-row-level-0 .ant-btn-default',
        )
        if (rowElement) {
          rowElement.click()
        } else {
          console.error('Row element not found')
        }
      },
      target: () => document.querySelector('.partsmasterdatatablecard'),
    },
    {
      title: t('groups'),
      description: <p>{t('group_parts_option')}</p>,
      action: () => happensOnPage('/partsmasterdata'),
      target: () => document.querySelector('.ant-tabs-nav'),
    },
    {
      title: t('my_services'),
      description: <p>{t('view_ordered_digital_services')}</p>,
      action: () => happensOnPage('/myservices'),
      target: () => document.querySelector(getTopnavItemSelector('myservices')),
    },
    {
      title: t('my_services_detail_view'),
      description: <p>{t('service_completion_download')}</p>,
      action: () => {
        happensOnPage('/myservices')
        const rowElement = document.querySelector(
          '.servicestablecard .ant-table-row.ant-table-row-level-0 ',
        )
        if (rowElement) {
          rowElement.click()
        } else {
          console.error('Row element not found')
        }
      },
      target: () =>
        document.querySelector('.servicestablecard .ant-table-expanded-row'),
    },
    {
      title: t('my_orders'),
      description: <p>{t('find_manage_orders')}</p>,
      action: () => happensOnPage('/myorders'),
      target: () => document.querySelector(getTopnavItemSelector('myorders')),
    },
    {
      title: t('order_status'),
      description: (
        <div>
          <p>{t('order_status_description')}</p>
          <ul>
            <li>
              <Tag color="warning">{t('offer_pending')}</Tag>
              {t('offer_pending_description')}
            </li>
            <li>
              <Tag>{t('accept_offer_and_order')}</Tag>
              {t('accept_offer_and_order_description')}
            </li>
            <li>
              <Tag color="success">{t('accepted')}</Tag>
              {t('accepted_description')}
            </li>
          </ul>
        </div>
      ),
      action: () => happensOnPage('/myorders'),
      target: () => document.querySelector('.ant-table'),
    },
  ]

  const steps = [
    introStep,
    ...interactiveSteps.map(({ title, ...step }, ix) => ({
      ...step,
      title: (
        <Flex justify="space-between">
          <h3>{title}</h3>
          <div style={{ marginRight: '30px' }}>
            {ix + 1} / {interactiveSteps.length}
          </div>
        </Flex>
      ),
    })),
    outroStep,
  ]

  const firstTourTarget = introStep.target()

  const handleStepChange = (stepIndex) => {
    setCurrentStep(stepIndex)
    if (steps[stepIndex] && steps[stepIndex].action) {
      steps[stepIndex].action()
    }
  }

  const handleClose = () => {
    localStorage.setItem('hasViewedTour', 'true')
    setIsOpen(false)
    if (currentStep >= steps.length - 1) {
      setCurrentStep(0)
    }
  }

  const onSetTour = () => {
    localStorage.removeItem('hasViewedTour')
    setIsOpen(true)
  }

  return (
    <TourContext.Provider
      value={{
        onSetTour,
      }}
    >
      {!viewedTour && isServiceUser && firstTourTarget && (
        <Tour
          steps={steps}
          current={currentStep}
          onChange={handleStepChange}
          onClose={handleClose}
          open={isOpen}
          gap={{ offset: 15 }}
        />
      )}
      {children}
    </TourContext.Provider>
  )
}

export default GuidedTour
