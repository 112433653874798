import { useQueryClient } from '@tanstack/react-query'

const useFetchDownloadQuery = () => {
  const queryClient = useQueryClient()

  const downloadFile = async ({
    queryKey,
    queryFn,
    downloadFileName = '',
    onError,
  }) => {
    try {
      const blob = await queryClient.fetchQuery({
        queryKey,
        queryFn,
      })

      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.setAttribute('download', downloadFileName)
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      console.error(error)
      onError(error)
    }
  }

  return downloadFile
}

export default useFetchDownloadQuery
