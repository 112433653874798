import { useContext } from 'react'
import { AuthContext, AuthProvider } from 'react-oauth2-code-pkce'
import { keycloakUrl, realm, keycloakClientId } from '$api'
import { useLogout } from '$context/user'

const keycloakOrigin = new URL(keycloakUrl).origin

const authConfig = {
  clientId: keycloakClientId,
  authorizationEndpoint: `${keycloakOrigin}/realms/${realm}/protocol/openid-connect/auth`,
  logoutEndpoint: `${keycloakOrigin}/realms/${realm}/protocol/openid-connect/logout`,
  tokenEndpoint: `${keycloakOrigin}/realms/${realm}/protocol/openid-connect/token`,
  redirectUri: window.location.origin,
  scope: 'profile openid',
  decodeToken: true,
  autoLogin: false,
}

const AuthContextProvider = ({ children }) => {
  const { error } = useContext(AuthContext)
  const logout = useLogout()

  if (error) {
    return (
      <>
        <div style={{ color: 'red' }}>
          An error occurred during authentication: {error}
        </div>
        <button onClick={logout}>Log out</button>
      </>
    )
  }

  return <AuthProvider authConfig={authConfig}>{children}</AuthProvider>
}

export default AuthContextProvider
