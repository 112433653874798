import styled, { keyframes } from 'styled-components'

const createScaleAnimationIn = () => keyframes`
  0% {
    transform: translate(0%);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translate(-50%);
  }
`

const createScaleAnimationOut = () => keyframes`
  0% {
    transform: translate(-50%);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translate(0%);
  }
`

// Dynamically setting the animation based on props
export const SlideInOut = styled.div`
  overflow: hidden;
  display: flex;
  width: 200%;
  animation: ${({ animate, isFirstOpen }) =>
      !isFirstOpen
        ? animate
          ? createScaleAnimationIn()
          : createScaleAnimationOut()
        : ''}
    0.5s;
  animation-delay: 0s;
  animation-fill-mode: both;
`
