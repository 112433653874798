import { Row, Button, Alert } from 'antd'
import styled from 'styled-components'

export const SusiCloundBackgroundImg = styled.img`
  height: 100%;
  margin-left: -36px;
  max-width: 150%;
`

export const RightSideImageContainer = styled.div`
  width: 70%;
  height: 100vh;
  overflow: hidden;
`

export const LeftSideFormContainer = styled.div`
  width: 30%;
  position: relative;
`

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80%;
`

export const LinksContainerBottom = styled.div`
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  line-height: 30px;
  padding-bottom: 15px;
`

export const FormContainer = styled.div`
  position: relative;
  width: 80%;
  margin: 20px auto 0px;
`

export const HeaderLogo = styled.img`
  width: 100%;
  margin-bottom: 40px;
`

export const LogoWrapper = styled(Row)`
  margin: 35px 40px 0px 40px;
`

export const OrangeText = styled.p`
  color: ${(props) => props.theme.colorPrimary};
  margin-bottom: 3;
  font-style: normal;
  font-weight: 500;
  font-size: 16.16px;
  line-height: 0px;
`

export const GreyText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #74788d;
`

export const WideButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
`

export const LoginFooterContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 40px;
`

export const HeartImage = styled.img`
  height: 12px;
  width: 12px;
  margin: 6px 4px 0px 4px;
`

export const LoginAlert = styled(Alert)`
  margin-bottom: 20px;
`
