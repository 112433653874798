const createColumnsConfig = (t) => {
  const columns = [
    {
      title: t('my_printers.printer.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('my_printers.printer.location'),
      dataIndex: 'standort',
      key: 'standort',
    },
  ]

  return columns
}

export default createColumnsConfig
