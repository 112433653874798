import {
  setOrderPartsListDateOfExpiration,
  setOrderPartsListProviderNote,
} from '$api/evoAPIs'
import DatePicker from '$components/DatePicker'
import Form from '$components/Form'
import OrderAddressView from '$components/OrderAddressView'
import ExpirationDate from '$components/OrderPartsListTable/ExpirationDate'
import { useUser } from '$context/user'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button, Col, Input, message, Row, theme } from 'antd'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const { useToken } = theme
const { TextArea } = Input

const OfferAdditionalContent = ({ form, orderPartsList, editable }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [messageApi, contextHolder] = message.useMessage()
  const { token } = useToken()
  const { isServiceProvider } = useUser()

  const setDateOfExpiration = useMutation({
    mutationFn: setOrderPartsListDateOfExpiration,
  })

  const setProviderNoteMutation = useMutation({
    mutationFn: setOrderPartsListProviderNote,
  })

  const onOrderPartFormFinish = async ({
    provider_note,
    date_of_expiration,
  }) => {
    try {
      await setProviderNoteMutation.mutateAsync({
        order_parts_list_id: orderPartsList.id,
        provider_note: provider_note,
      })
      await setDateOfExpiration.mutateAsync({
        order_parts_list_id: orderPartsList.id,
        date_of_expiration: date_of_expiration,
      })
      messageApi.success('Saved successfully!')
      queryClient.invalidateQueries(['allorderedpartslists'])
    } catch (error) {
      console.error(error)
      messageApi.error('Could not save data!')
    }
  }

  return (
    <>
      {contextHolder}
      <Form
        form={form}
        onFinish={onOrderPartFormFinish}
        layout="vertical"
        requiredMark={(label, info) => (
          <h4>
            {label}
            {info.required && (
              <span style={{ color: token.colorError, marginLeft: '5px' }}>
                *
              </span>
            )}
          </h4>
        )}
      >
        <Row>
          <Col span={16}>
            <Form.Item
              label={t('received_requests.date_of_expiration')}
              name={'date_of_expiration'}
              initialValue={
                orderPartsList?.date_of_expiration
                  ? dayjs(orderPartsList.date_of_expiration)
                  : undefined
              }
              rules={[
                {
                  required: true,
                  message: t('received_requests.date_of_expiration_required'),
                },
              ]}
            >
              {editable ? (
                <DatePicker
                  style={{ width: '45%' }}
                  disabledDate={(d) => !d || d.isBefore(new Date())}
                  allowClear={false}
                />
              ) : (
                <ExpirationDate
                  date_of_expiration={orderPartsList.date_of_expiration}
                />
              )}
            </Form.Item>
            <OrderAddressView
              billingAddress={orderPartsList.billing_address}
              deliveryAddress={orderPartsList.delivery_address}
            />
          </Col>
          <Col span={8}>
            {orderPartsList?.client_note && (
              <>
                <h4>
                  {t('service.order_part.additional_notes')}
                  {isServiceProvider && ` ${t('by customer')}`}
                </h4>
                <p>{orderPartsList?.client_note}</p>
              </>
            )}

            {editable ? (
              <Form.Item
                label={t('received_requests.provider_note')}
                name={'provider_note'}
                initialValue={orderPartsList?.provider_note}
              >
                <TextArea rows={4} placeholder="" />
              </Form.Item>
            ) : (
              orderPartsList?.provider_note && (
                <>
                  <h4>
                    {t('received_requests.provider_note')}
                    {!isServiceProvider && ` ${t('by service provider')}`}
                  </h4>
                  <p>{orderPartsList?.provider_note}</p>
                </>
              )
            )}

            {editable && (
              <Form.Item>
                <Row justify={'end'}>
                  <Button type="primary" htmlType="submit">
                    {t('button.save')}
                  </Button>
                </Row>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default OfferAdditionalContent
